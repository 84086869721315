import { fromJS, Record } from 'immutable'

import { IUser } from 'models'

import { SET_USER, ON_READY, ON_AUTH_SUCCESS, SET_ERROR, UserTypes } from './actions'

export enum UserErrors {
  NetworkError = 'Network Error',
}

type IState = {
  isFetching: boolean
  onReady: boolean
  onAuthSuccess: boolean
  isLoggedIn?: boolean
  user?: IUser
  error?: string | boolean | number | UserErrors
}

const initialState: Record<IState> = fromJS({
  isFetching: false,
  onReady: false,
  onAuthSuccess: undefined,
  isLoggedIn: undefined,
  user: undefined,
  error: undefined,
})

export const reducer = (state = initialState, action: UserTypes) => {
  switch (action.type) {
    case SET_USER:
      return state.set('user', action.payload).set('error', undefined)
    case ON_READY:
      return state.set('onReady', true).set('isLoggedIn', action.payload)
    case ON_AUTH_SUCCESS:
      return state.set('onAuthSuccess', true)
    case SET_ERROR:
      return state.set('error', action.payload).set('isFetching', false)
    default:
      return state
  }
}

export default reducer