import React from 'react'

import { Flex } from '@ocs.lab/ui'
import { IButtonRating, RATE_BUTTONS_SETUP } from '../../options'

import Button from './Button'

type Props = {
  currentRating?: IButtonRating
  setRating: (value: IButtonRating) => void
}

const RateButtons: React.FC<Props> = ({ currentRating, setRating }) => {
  return (
    <Flex flexWrap="wrap">
      {RATE_BUTTONS_SETUP.map((buttonRating) => (
        <Button
          key={buttonRating.rating}
          buttonRating={buttonRating}
          isActive={currentRating?.rating === buttonRating.rating}
          setRating={setRating}
        />
      ))}
    </Flex>
  )
}

export default RateButtons
