import value from 'assets/images/value.svg'
import value_1 from 'assets/images/value_1.svg'
import value_2 from 'assets/images/value_2.svg'
import value_3 from 'assets/images/value_3.svg'

const VALUES = [
  {
    title: 'Заказчикам',
    body: 'Получать более качественный сервис от коллег и улучшать взаимодействие внутри компании',
    photo: value,
  },
  {
    title: 'Исполнителям',
    body: 'Позволяет посмотреть на свою работу со стороны, улучшать качество работы и расти профессионально',
    photo: value_1,
  },
  {
    title: 'Руководителям',
    body: 'Дает увидеть слабые места и оптимизировать процессы. Позволяет работать с мотивацией и зонами роста сотрудников',
    photo: value_2,
  },
  {
    title: 'Компании',
    body: 'Повышает прозрачность и эффективность внутренних процессов и в конечном итоге влияет на благополучие компании',
    photo: value_3,
  },
]

export default VALUES