import { fromJS, Record } from 'immutable'

import { IEmployeeFeedbackFilter, IEmployeeFeedbackItem } from 'models/employee/feedback'
import { FeedbackTypes, GET_EMPLOYEE_FEEDBACK, GET_EMPLOYEE_FEEDBACK_SUCCESS, GET_EMPLOYEE_FEEDBACK_FAIL, GET_FEEDBACK_COUNT_SUCCESS } from './actions'
import { Status } from 'models'

type IState = {
  isLoading: boolean
  status?: Status
  data?: IEmployeeFeedbackItem[]
  filters?: IEmployeeFeedbackFilter[]
  totalCount?: number
  provisionDateSortOrder?: 'Asc' | 'Desc'
  serviceTypeId: number | null
  feedbackCount?: number
  error?: string | boolean
}

const initialState: Record<IState> = fromJS({
  isLoading: true,
  status: undefined,
  data: undefined,
  filters: undefined,
  totalCount: undefined,
  provisionDateSortOrder: undefined,
  serviceTypeId: undefined,
  feedbackCount: undefined,
  error: false,
})

export const reducer = (state = initialState, action: FeedbackTypes) => {
  // const data = state.get('data')
  // const filters = state.get('filters')
  switch (action.type) {
    case GET_EMPLOYEE_FEEDBACK:
      return state.set('isLoading', true)
        .set('provisionDateSortOrder', action.payload.provisionDateSortOrder)
        .set('serviceTypeId', action.payload.serviceTypeId)
    case GET_EMPLOYEE_FEEDBACK_SUCCESS:
      return state
        .set('isLoading', false)
        .set('data', action.payload.data.data)
        .set('filters', action.payload.data.filters)
        .set('totalCount', action.payload.data.totalCount)
        .set('feedbackCount', action.payload.data.totalCount)
        .set('status', action.payload.status as Status)
    case GET_EMPLOYEE_FEEDBACK_FAIL:
      return state.set('isLoading', false).set('error', action.payload)
    case GET_FEEDBACK_COUNT_SUCCESS:
      return state.set('feedbackCount', action.payload)
    // case DELETE_VOTED_ITEM:

    //   const finalFilters = filters?.map((f) => {
    //     if (f.serviceTypeId === null || f.serviceTypeId === action.payload.serviceTypeId) {
    //       return {
    //         ...f,
    //         itemsCount: f.itemsCount - 1
    //       }
    //     }
    //     return f
    //   }).filter(f => f.itemsCount)

    //   const currentServiceTypeId = state.get('serviceTypeId')
    //   const isCurrentServiceTypeIdExists = finalFilters?.find(f => f.serviceTypeId === currentServiceTypeId)

    //   return state
    //     .set('data', data?.filter(item => item.service.serviceId !== action.payload.serviceId))
    //     .set('filters', finalFilters)
    //     .set('serviceTypeId', isCurrentServiceTypeIdExists ? currentServiceTypeId : null)
    // // .set('feedbackCount', action.payload)
    default:
      return state
  }
}

export default reducer