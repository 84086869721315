import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Box, FlexColumn, Text } from '@ocs.lab/ui'

export const WIDTH_MEMBER_CARD = 242
export const HEIGHT_MEMBER_CARD = 272

const useStyles = makeStyles(() => ({
  root: {
    width: WIDTH_MEMBER_CARD,
    height: HEIGHT_MEMBER_CARD,
    backgroundColor: '#fff',
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    '&:nth-child(2)': {
      marginTop: 40,
    },
    '&:nth-child(5)': {
      marginTop: 40,
    },
  },
}))

type Props = {
  name: string
  role: string
  photo: any
}

const TeamMember: React.FC<Props> = ({ name, role, photo }) => {
  const { root } = useStyles()
  return (
    <FlexColumn className={root}>
      <img src={photo} alt="no_photo" />
      <Box pt={35} pb={8}>
        <b>{name}</b>
      </Box>
      <Text variant="caption" color="textSecondary" align="center">
        {role}
      </Text>
    </FlexColumn>
  )
}

export default TeamMember
