import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'store'

import { Box, Dialog, Flex, Paper } from '@ocs.lab/ui'
import { SortType } from '@ocs.lab/ui/lib/components/icons'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'

import FeedbackActions from 'store/employee/feedback/actions'

import FeedbackItem from '../FeedbackItem'
import { useTheme } from '@material-ui/core/styles'
import Tab from '../Tab'
import VoteForm from 'screens/Vote/components/VoteForm'
import { IEmployeeFeedbackItem } from 'models/employee/feedback'
import { DialogProps } from '@ocs.lab/ui/lib/components/elements/Dialog'
import VoteActions from 'store/vote/actions'

const ALL_CAPTIONS = [{ title: 'Активность' }, { title: 'Название' }, { title: 'Дата', sortKey: 'Date' }, { title: '' }]

type Props = {
  onVoteSuccess: (message: string) => void
}

//TODO: повторение логики и UI reports надо сделать унивесрслаьные компоненты
const TableBodyLayout: React.VFC<Props> = ({ onVoteSuccess }) => {
  const { palette } = useTheme()
  const dispatch = useDispatch()
  const feedback = useSelector(({ feedback }) => feedback.get('data'))
  const filters = useSelector(({ feedback }) => feedback.get('filters'))
  const provisionDateSortOrder = useSelector(({ feedback }) => feedback.get('provisionDateSortOrder'))
  const serviceTypeId = useSelector(({ feedback }) => feedback.get('serviceTypeId'))
  const voted = useSelector(({ vote }) => vote.voted)

  const [activeVoteProcess, setActiveVoteProcess] = useState<IEmployeeFeedbackItem | null>(null)
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper')

  const IconComponent = () => (
    <SortType style={{ marginRight: 3, marginLeft: 3 }} type={provisionDateSortOrder!} isVisible />
  )

  useEffect(() => {
    if (voted) {
      const isLastItem = feedback?.length === 1 && feedback[0].serviceTypeId === serviceTypeId
      onVoteSuccess('Спасибо за обратную связь!')
      dispatch(FeedbackActions.getEmployeeFeedback(provisionDateSortOrder, isLastItem ? null : serviceTypeId))
      dispatch(VoteActions.resetVoted())
    }
  }, [voted]) // eslint-disable-line

  const handleClickOpen = (scrollType: DialogProps['scroll'], item: IEmployeeFeedbackItem) => {
    setActiveVoteProcess(item)
    setScroll(scrollType)
  }

  const handleVoteFormCallback = () => {
    setActiveVoteProcess(null)
  }

  const toggleSortOrderByDate = () =>
    dispatch(FeedbackActions.getEmployeeFeedback(provisionDateSortOrder === 'Asc' ? 'Desc' : 'Asc', serviceTypeId))

  return (
    <Box px={24} pb={24} borderRadius={16}>
      <Flex id="serviceTypesFilter" flexWrap="wrap" gridGap={8} pb={24}>
        {filters?.map((f) => (
          <Tab
            key={f.serviceTypeId}
            title={f.title}
            subtitle={f.itemsCount.toString()}
            active={f.isSelected}
            onClick={() => {
              if (!f.isSelected) {
                dispatch(FeedbackActions.getEmployeeFeedback(provisionDateSortOrder, f.serviceTypeId))
              }
            }}
          />
        ))}
      </Flex>

      <TableContainer component={Paper}>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {ALL_CAPTIONS.map(({ title, sortKey }) => (
                <TableCell key={title} style={{ fontSize: 12, color: palette.text.secondary }}>
                  <TableSortLabel
                    style={{ cursor: sortKey ? 'pointer' : 'text' }}
                    active={title === 'Дата'}
                    direction={provisionDateSortOrder?.toLocaleLowerCase() as 'asc' | 'desc'}
                    onClick={title === 'Дата' ? toggleSortOrderByDate : undefined}
                    IconComponent={IconComponent}
                    hideSortIcon
                  >
                    {title.toUpperCase()}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {feedback?.map((data) => (
              <FeedbackItem
                key={data.service.serviceId}
                data={data}
                onOpen={(scrollType) => {
                  handleClickOpen(scrollType, data)
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        isOpen={!!activeVoteProcess}
        onOverlayClick={() => setActiveVoteProcess(null)}
        onClose={() => setActiveVoteProcess(null)}
        scroll={scroll}
      >
        {activeVoteProcess && (
          <VoteForm
            voteId={activeVoteProcess.voteInfo.voteId}
            secret={activeVoteProcess.voteInfo.voteSecret}
            callback={handleVoteFormCallback}
            noElevation
            isLocal={true}
          />
        )}
      </Dialog>
    </Box>
  )
}

export default TableBodyLayout
