import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { Link, Tooltip, Text, Box } from '@ocs.lab/ui'
import { HOVER } from '@ocs.lab/ui/lib/theme/helpers'

import { IServiceDescription } from 'models/reports'

const useStyles = makeStyles(({ palette }) => ({
  root: {
    [HOVER]: {
      color: palette.text.secondary,
      '& img': {
        transitionDuration: '0.3s',
        transform: 'translateX(10px)',
      },
    },
  },
}))

type Props = {
  index: number
  description: IServiceDescription
}

const DescriptionItem: React.FC<Props> = ({ index, description }) => {
  const { root } = useStyles()
  const [isShowCopyTooltip, setIsShowCopyTooltip] = useState(false)

  const { type, title, value, extraValue } = description

  const handleClipboard = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    if (value)
      navigator.clipboard.writeText(value).then(
        function () {
          setIsShowCopyTooltip(true)
          setTimeout(() => {
            setIsShowCopyTooltip(false)
          }, 500)
        },
        function (err) {
          console.error('Async: Could not copy text: ', err)
        }
      )
  }

  return type === 'LinkValue' ? (
    <Text style={{ wordBreak: 'break-word' }}>
      <Link
        key={extraValue}
        className={root}
        color="textPrimary"
        underline="none"
        target="_blank"
        href={value}
        withArrow={true}
      >
        {extraValue}
      </Link>
    </Text>
  ) : (
    // TODO we can't put tooltip into tooltip, mui errors
    <Tooltip open={isShowCopyTooltip} title="Скопировано" arrow placement="right">
      <Box width="fit-content">
        <Tooltip
          key={title}
          disableHoverListener={!index}
          title={title ?? ''}
          arrow
          placement="top"
          onClick={handleClipboard}
        >
          <Text
            style={{ width: 'fit-content', wordBreak: 'break-word' }}
            // FIXME: danger logic, need rebuild with another check
            color={!index || title === 'Журнал' ? 'textPrimary' : 'textSecondary'}
          >
            {value}
          </Text>
        </Tooltip>
      </Box>
    </Tooltip>
  )
}

export default DescriptionItem
