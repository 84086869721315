import { AxiosResponse } from "axios"
import { call, put } from "redux-saga/effects"
import { IActionWithPayload } from "../actions"

export function fetchData(
  fetch: (...args: any[]) => Promise<AxiosResponse>,
  success: (...args: any[]) => IActionWithPayload<string, any>,
  fail: (...args: any[]) => IActionWithPayload<string, any>,
  formArguments = (action: IActionWithPayload<string, any>) => [action.payload]
) {
  return function* fetchDataFlow(action: IActionWithPayload<string, any>) {
    try {
      // const { data } = yield call(fetch, action.payload)
      const { data } = yield call(fetch, ...formArguments(action))
      yield put(success(data))
    } catch (err) {
      // console.error(err.response)
      yield put(fail(err))
    }
  }
}