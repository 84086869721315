import Api from '.'
import { IReward } from 'models'

class Reward {

  static getRewardInfo(id: number, key: string) {
    return Api.get<IReward>(`votesmanagersrewards/${id}/${key}`)
  }

}

export default Reward
