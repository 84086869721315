import { IEmployeeInfo } from 'models/employee/activities'
import { createAction, ActionsUnion } from '../../helpers/actions'

export const GET_REPORT_EMPLOYEE_INFO = 'GET_REPORT_EMPLOYEE_INFO'
// export const GET_EMPLOYEE_ACTIVITY_EMPLOYEE_INFO = 'GET_EMPLOYEE_ACTIVITY_EMPLOYEE_INFO'
export const GET_EMPLOYEE_INFO_SUCCESS = 'GET_EMPLOYEE_INFO_SUCCESS'
export const GET_EMPLOYEE_INFO_FAIL = 'GET_EMPLOYEE_INFO_FAIL'

export const RESET_EMPLOYEE_INFO = 'RESET_EMPLOYEE_INFO'

const EmployeeInfoActions = {
  getData: (id: string) => createAction(GET_REPORT_EMPLOYEE_INFO, id),
  getDataSuccess: (data: IEmployeeInfo) => createAction(GET_EMPLOYEE_INFO_SUCCESS, data),
  getDataFail: (error: string | boolean) => createAction(GET_EMPLOYEE_INFO_FAIL, error),
  resetData: () => createAction(RESET_EMPLOYEE_INFO),
}

export type EmployeeInfoTypes = ActionsUnion<typeof EmployeeInfoActions>

export default EmployeeInfoActions
