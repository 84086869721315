import Api from '.'

class Statistics {
  static getTotalVotedVotesCount(onlyWithPublicComments: boolean = false) {
    return Api.get<number>(`/votes/gettotalvotedvotescount?onlyWithPublicComments=${onlyWithPublicComments}`)
  }

  static getTotalVotedClientsCount() {
    return Api.get<number>('/votes/gettotalvotedclientscount')
  }
}

export default Statistics
