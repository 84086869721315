import React from 'react'

import { Text, FlexColumn, Flex, FlexFill } from '@ocs.lab/ui'

import Value from './components/Value'
import VALUES from './values'

const Values = () => {
  return (
    <FlexColumn id="for-what" bgcolor="#fff" py={120} px={104} maxWidth={1600} margin="auto">
      <Flex pb={60} justifyContent="center">
        <Text variant="h1">Для чего нужна обратная связь</Text>&nbsp;
        <Text variant="h1" color="primary">
          или в чем ценность?
        </Text>
      </Flex>
      <FlexFill justifyContent="space-between">
        {VALUES.map((value) => (
          <Value key={value.title} {...value} />
        ))}
      </FlexFill>
    </FlexColumn>
  )
}

export default Values
