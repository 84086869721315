import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import { Box } from '@ocs.lab/ui'

import Landing, { rootStyles } from 'screens/Landing'
import Champions from 'screens/Champions'
import Reports from 'screens/Reports'
import MyActivities from 'screens/MyActivities'
import Feedback from 'screens/Feedback'
import Vote from 'screens/Vote'
import PageNotFound from 'screens/404'

import UserActions from 'store/keycloak/actions'
import { UserErrors } from 'store/keycloak'

import ProtectedRoute from 'components/ProtectedRoute'
import UseGoogleChromeNotification from 'components/UseGoogleChromeNotification'
import UserSettings from 'services/UserSettings'
import Reward from 'screens/Reward'

/* //TODO: create all routes like CONSTANTS (ex. lav-list) */

type Props = {
  setError: typeof UserActions.setError
}
class App extends React.PureComponent<Props> {
  // TODO: take to utils (check if IE)
  isIE = /trident/i.test(window.navigator.userAgent)

  componentDidMount() {
    const savedUserSettings = localStorage.getItem(UserSettings.keyName)
    if (savedUserSettings) new UserSettings(JSON.parse(savedUserSettings))

    //NOTE: helth check for server + help to detect users without VPN
    //TODO: mabe need to move this func to utils or smth like that, idk
    fetch(process.env.REACT_APP_KEYCLOAK_OPENID_CONFIGURATION)
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .catch((err) => {
        return this.props.setError(UserErrors.NetworkError)
      })
  }

  render() {
    return (
      <Box>
        <UseGoogleChromeNotification isShow={this.isIE} />
        <Router>
          <Switch>
            <ProtectedRoute exact path="/" styles={rootStyles}>
              <Landing />
            </ProtectedRoute>
            <ProtectedRoute path="/lav-list">
              <Champions />
            </ProtectedRoute>
            <ProtectedRoute path="/reports" requiredRoles={['BusinessAnalyst', 'Manager']}>
              <Reports />
            </ProtectedRoute>
            <ProtectedRoute path="/activities">
              <MyActivities />
            </ProtectedRoute>
            <ProtectedRoute path="/feedback">
              <Feedback />
            </ProtectedRoute>
            <Route path="/vote">
              <Vote />
            </Route>
            <Route path="/reward">
              <Reward />
            </Route>
            <Route component={PageNotFound} />
          </Switch>
        </Router>
      </Box>
    )
  }
}

export default connect(null, { setError: UserActions.setError })(App)
