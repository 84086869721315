import { Palette } from "@material-ui/core/styles/createPalette";
import { Overrides } from "@material-ui/core/styles/overrides";

export const makeOverrides = ({ palette }: { palette?: Palette }): Overrides => ({
  MuiTable: {},
  MuiTableBody: {},
  MuiTableCell: {
    root: {
      borderBottom: 'none',
    },
  },
  MuiTableRow: {
    root: {
      verticalAlign: 'top',
      '&$selected': {
        backgroundColor: palette?.primary.light,
      },
    },
    selected: {},
  },
  MuiTableHead: {
    root: {
      backgroundColor: palette?.background.default,
    },
  },
  MuiTableFooter: {},
  MuiTableContainer: {},
  MuiTablePagination: {},
  MuiTableSortLabel: {},
})