import React from 'react'
import { useSelector } from 'store'

import { Accordion, Backdrop, Box, FlexColumn, Text, Typography } from '@ocs.lab/ui'

import backgroundImg from 'assets/images/fon-dog.svg'

import { useTheme } from '@material-ui/core/styles'

import { declensions } from 'utils'
import Departments from './components/Departments'
import { IReportDepartmentStatistic, IServicesStatisticRecord } from 'models/reports'

// const tabs: ('Сервисы' | 'Подразделения')[] = ['Сервисы', 'Подразделения']

const EndHeaderComponent = ({ item }: { item: IReportDepartmentStatistic | IServicesStatisticRecord }) => (
  <Box mr={8}>
    <Typography color="textSecondary">
      {item.totalVotes} {declensions(item.totalVotes || 0, ['отзыв', 'отзыва', 'отзывов'])}
    </Typography>
  </Box>
)

const DetailedStatistics = () => {
  const { palette } = useTheme()

  // const [tab, setTab] = useState(tabs[0])

  const isLoading = useSelector((state) => state.statistics.get('isLoading'))
  const statistics = useSelector((state) => state.statistics.get('data'))
  const servicesStatistic = statistics?.servicesStatistic
  // const departmentsStatistic = statistics?.departmentsStatistic

  return (
    <Box bgcolor={palette.primary.light}>
      <FlexColumn
        gridGap={60}
        style={{ backgroundImage: `url(${backgroundImg})`, backgroundRepeat: 'no-repeat' }}
        py={100}
      >
        <Text variant="h1" align="center">
          Сервисы, по которым можно дать обратную связь
        </Text>

        {/* <Box m="auto">
          <Tabs selected={tab} tabs={tabs} onChange={(_, value) => setTab(value)} />
        </Box> */}

        <Backdrop isLoading={isLoading}>
          <FlexColumn
            gridGap={8}
            maxWidth="1028px"
            minWidth="900px"
            minHeight={isLoading ? '400px' : 'auto'}
            bgcolor="#fff"
            margin="auto"
            padding={8}
            borderRadius={16}
          >
            {/* {tab === 'Подразделения' ? (
              <Accordion
                items={departmentsStatistic?.children ?? []}
                EndHeaderComponent={EndHeaderComponent}
                ContentComponent={({ item }) => <Departments items={item.activities} />}
                isContentExists={(item) => item.activities.length > 0}
                getId={(item) => item.title}
              />
            ) : (
              <Accordion
                items={servicesStatistic ?? []}
                EndHeaderComponent={EndHeaderComponent}
                ContentComponent={({ item }) => <Departments items={item.serviceTypesInfos} />}
                isContentExists={(item) => !!item.serviceTypesInfos && item.serviceTypesInfos.length > 0}
                getId={(item) => item.title}
              />
            )} */}
            <Accordion
              items={servicesStatistic ?? []}
              EndHeaderComponent={EndHeaderComponent}
              ContentComponent={({ item }) => <Departments items={item.serviceTypesInfos} />}
              isContentExists={(item) => !!item.serviceTypesInfos && item.serviceTypesInfos.length > 0}
              getId={(item) => item.title}
            />
          </FlexColumn>
        </Backdrop>
      </FlexColumn>
    </Box>
  )
}

export default DetailedStatistics
