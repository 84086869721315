import React from 'react'

import { Box, Text, FlexColumn } from '@ocs.lab/ui'

type Props = {
  title: string
  body: string
  photo: any
}

const Value: React.FC<Props> = ({ title, body, photo }) => {
  return (
    <FlexColumn width={344} alignItems="center">
      <img src={photo} alt="no_photo" />
      <Box pt={15} pb={14}>
        <b style={{ fontSize: 20 }}>{title}</b>
      </Box>
      <Text align="center">{body}</Text>
    </FlexColumn>
  )
}

export default Value
