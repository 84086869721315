import React from 'react'

import { Box, Flex, FlexColumn, Link, Text } from '@ocs.lab/ui'

import { styled, useTheme } from '@material-ui/core/styles'

import backgroundImg from 'assets/images/principles-fon.svg'

import Principle, { WIDTH_MEMBER_CARD, HEIGHT_MEMBER_CARD } from './components/Principle'
import Product from './components/Product'
import PRINCIPLES from './principles'
import PRODUCTS from './products'

const Grid = styled(Box)({
  display: 'grid',
  gridTemplateRows: `repeat(2, ${HEIGHT_MEMBER_CARD}px)`,
  gridTemplateColumns: `repeat(2 ,${WIDTH_MEMBER_CARD}px)`,
  justifyContent: 'center',
  gridGap: 20,
  paddingTop: 60,
})

const FeedbackPrinciples = () => {
  const { palette } = useTheme()
  return (
    <Box id="feedback-principles">
      <FlexColumn
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 110%',
        }}
        bgcolor={palette.primary.light}
        py={120}
        alignItems="center"
      >
        <Text variant="h1" color="primary">
          4 принципа
        </Text>
        <Text variant="h1">качественной обратной связи</Text>
        <Grid>
          {PRINCIPLES.map((principle) => (
            <Principle key={principle.title} {...principle} />
          ))}
        </Grid>
      </FlexColumn>
      <FlexColumn id="goods" bgcolor={palette.action.hover} py={120} alignItems="center">
        <Flex>
          <Text variant="h1">Специальная линейка товаров Feedback в</Text>&nbsp;&nbsp;
          <Link
            variant="h1"
            color="primary"
            underline="always"
            href={process.env.REACT_APP_OSIP_MARKET}
            target="_blank"
          >
            магазинчике Осипа
          </Link>
        </Flex>
        <Flex pt={60}>
          {PRODUCTS.map((product) => (
            <Product key={product.title} {...product} />
          ))}
        </Flex>
      </FlexColumn>
    </Box>
  )
}

export default FeedbackPrinciples
