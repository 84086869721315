import Keycloak, { KeycloakConfig, KeycloakInstance } from 'keycloak-js'

import store from 'store'
import Api from 'api'
import { IUser } from 'models'

import UserActions from 'store/keycloak/actions'
import FeedbackActions from 'store/employee/feedback/actions'

const REFRESH_TOKEN_TTL = 10 * 60 * 1000

const config: KeycloakConfig = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
}

//TODO: build implements from KeycloakInstance
//TODO: maybe we should store the instance of AuthService store to redux, thinking...
// class AuthService implements KeycloakInstance {
class AuthService {
  private static KeycloakInstance: KeycloakInstance = Keycloak(config)

  static currentUser: IUser

  constructor() {
    AuthService.KeycloakInstance.onAuthSuccess = () => store.dispatch(UserActions.onAuthSuccess())
    AuthService.KeycloakInstance.onReady = (authenticated) => {
      Api.setAuthToken(AuthService.getToken())
      store.dispatch(UserActions.onReady(authenticated))
    }
    //TODO:
    AuthService.KeycloakInstance.onAuthError = (errorData) => console.log("onAuthError", errorData)
    AuthService.KeycloakInstance.onAuthRefreshError = () => {
      console.log("onAuthRefreshError")
      AuthService.logout()
    }
    AuthService.KeycloakInstance.onAuthRefreshSuccess = () => {
      console.log("onAuthRefreshSuccess")
      Api.setAuthToken(AuthService.getToken())
    }

    AuthService.KeycloakInstance.init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      redirectUri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URI,
      pkceMethod: 'S256',
      enableLogging: true,
    }).then(async (authenticated) => {
      if (authenticated) {
        AuthService.currentUser = await AuthService.getUser()
        store.dispatch(UserActions.setUser(AuthService.currentUser))
        store.dispatch(FeedbackActions.getFeedbackCount())
      } else {
        AuthService.login()
      }
    }).catch(e => {
      console.error("ERROR AuthService constructor")
    })

    AuthService.updateTokenTimeout()

  }

  static updateTokenTimeout() {
    setTimeout(() => {
      AuthService.updateToken(() => {
        AuthService.updateTokenTimeout()
      })
    }, REFRESH_TOKEN_TTL)
  }

  static async getUser() {
    const { data: user } = await Api.get<IUser>('users/getinfo')
    return user
  }

  static login(): Promise<void> {
    return AuthService.KeycloakInstance.login()
  }

  static getToken() {
    return AuthService.KeycloakInstance.token
  }

  static updateToken(successCallback: Function) {
    return AuthService.KeycloakInstance.updateToken(300).then(successCallback()).catch(AuthService.login)
  }

  static logout(): Promise<void> {
    return AuthService.KeycloakInstance.logout()
  }
}

export default AuthService
