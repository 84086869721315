import React from 'react'

import { IVoteSummary } from 'models/reports'

import RatingStatus from 'components/RatingStatus'
import User from '../User'

import { Box, Flex, FlexFill, Link, Tooltip } from '@ocs.lab/ui'
import { HOVER } from '@ocs.lab/ui/lib/theme/helpers'
import rocket_chat from '@ocs.lab/ui/lib/assets/icons/rocket_chat.svg'

import { styled } from '@material-ui/core'

type Props = {
  voteSummary: IVoteSummary
}

export const IconWrapper = styled(Box)(({ theme: { palette } }) => ({
  padding: 8,
  [HOVER]: {
    borderRadius: 24,
    backgroundColor: palette.background.default,
  },
}))

const UserItem: React.FC<Props> = ({ voteSummary }) => {
  return (
    <FlexFill mt={16} alignItems="center">
      <User user={voteSummary.client.person} />
      <Flex justifyContent="space-between" alignItems="center" minWidth={70}>
        <RatingStatus voteSummary={voteSummary} placement="bottom" />
        <Tooltip title="Написать в Rocket.chat" placement="left" arrow={true}>
          <IconWrapper height={24}>
            <Link href={voteSummary.client.rocketLink} target="_blank">
              <img src={rocket_chat} alt="rocket_chat" />
            </Link>
          </IconWrapper>
        </Tooltip>
      </Flex>
    </FlexFill>
  )
}

export default UserItem
