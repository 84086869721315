import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Box, Flex, FlexColumn, Paper, Text, Link } from '@ocs.lab/ui'

import header_404 from '@ocs.lab/ui/lib/assets/icons/header_404.svg'
import check_url_404 from '@ocs.lab/ui/lib/assets/icons/check_url_404.svg'
import flower_404 from '@ocs.lab/ui/lib/assets/icons/flower_404.svg'
import cross_404 from '@ocs.lab/ui/lib/assets/icons/cross_404.svg'

type CauseProps = {
  imgSrc: string
  text: string | JSX.Element
  alignItems?: string
}

const Cause: React.FC<CauseProps> = ({ imgSrc, text, alignItems = 'center' }) => (
  <Flex maxWidth={360} alignItems={alignItems} mt={28}>
    <img src={imgSrc} alt="404" />
    <Box ml={8}>
      <Text>{text}</Text>
    </Box>
  </Flex>
)

const PageNotFound = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <Flex height="100vh" width="100vw" alignItems="center" justifyContent="center">
      <Box>
        <Paper elevation={matches ? 0 : 5}>
          <FlexColumn py={48} px={32} alignItems="center">
            <img src={header_404} alt="404" />
            <Box mt={23}>
              <Text variant="h5">Что–то пошло не так ...</Text>
            </Box>
            <FlexColumn>
              <Cause imgSrc={check_url_404} text="Пожалуйста, проверьте правильность ссылки" />
              <Cause imgSrc={flower_404} text="Возможно, ссылка могла устареть" />
              <Cause
                imgSrc={cross_404}
                text={
                  <Text component="span">
                    Возможно, сервер временно недоступен, попробуйте через 5–10 минут. Если вы по–прежнему видите эту
                    страницу — пишите на{' '}
                    <Link
                      color="textPrimary"
                      underline="always"
                      href={process.env.REACT_APP_ROCKET_SUPPORT_MAIL}
                      target="_blank"
                    >
                      почту команды поддержки OCS Feedback
                    </Link>
                  </Text>
                }
                alignItems="flex-start"
              />
            </FlexColumn>
          </FlexColumn>
        </Paper>
      </Box>
    </Flex>
  )
}

export default PageNotFound
