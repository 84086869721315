import React from 'react'

import { Box, Text, Button, FlexColumn, Flex } from '@ocs.lab/ui'
import { LAST_CHILD } from '@ocs.lab/ui/lib/theme/helpers'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
    borderRadius: 16,
    paddingTop: 14,
    justifyContent: 'space-between',
    marginRight: 20,
    [LAST_CHILD]: {
      marginRight: 0,
    },
  },
  price_text: {
    fontWeight: 600,
  },
}))

type Props = {
  title: string
  price: number
  photo: any
  href: string
}

const Product: React.FC<Props> = ({ title, price, photo, href }) => {
  const { root, price_text } = useStyles()
  return (
    <FlexColumn className={root}>
      <img width={242} src={photo} alt="no_photo" />
      <Box py={24} px={16}>
        <Flex justifyContent="space-between" pb={16}>
          <Text>{title}</Text>
          <Text className={price_text}>{price} лав</Text>
        </Flex>
        <Button fullWidth size="small" variant="contained" color="primary" component="a" href={href} target="_blank">
          <Text color="inherit">Купить</Text>
        </Button>
      </Box>
    </FlexColumn>
  )
}

export default Product
