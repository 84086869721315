import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { omit } from 'lodash'
import { AppState } from 'store'

import { Box, ScrollToTop } from '@ocs.lab/ui'

import { IServiceReportFilter, IServiceReport, IServiceReportItem } from 'models/reports'
import { IEmployeeActivityItem } from 'models/employee/activities'
import { IHideColumns } from 'models'
import ServiceSummaryActions from 'store/reports/serviceSummary/actions'

import ServiceSummary from './components/ServiceSummary'
import ReportLineItem from 'screens/Reports/components/ReportLineItem'
import TableStatus from '../../components/TableComponents/TableStatus'
import Table, { ITableCaption } from 'components/TableComponents/Table'
import FiltersPanel from 'components/TableComponents/FiltersPanel'
import { reports } from 'store/reports'
import EmployeeInfoActions from 'store/reports/employeeInfo/actions'
import EmployeeInfo from './components/EmployeeInfo'

const TABLE_CAPTIONS: ITableCaption[] = [
  { key: 'serviceTypeTitle', title: 'Активность' },
  { key: 'service', title: 'Название' },
  { key: 'department', title: 'Подразделение' },
  { key: 'provisionDate', title: 'Дата', sortKey: 'Date' },
  { key: 'executors', title: 'Исполнитель' },
  { key: 'clients', title: 'Заказчик' },
  { key: 'serviceComments', title: 'Заметки' },
]

const captionsToFiltersRatio: { [key in IHideColumns]: string } = {
  serviceTypeTitle: 'serviceTypes',
  department: 'departments',
  serviceTypes: 'serviceTypes',
}

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = {} & PropsFromRedux

type State = {
  isDrawerOpen: boolean
  isEmployeeInfoOpen: boolean
}

//TODO: повторение логики и UI reports надо сделать унивесрслаьные компоненты
class Reports extends React.PureComponent<Props, State> {
  state = {
    isDrawerOpen: false,
    isEmployeeInfoOpen: false,
  }

  componentDidMount() {
    this.props.createReport({})
  }

  handleDrawerServiceSummaryOpen = (serviceId: number) => {
    this.setState({ isDrawerOpen: true })
    this.props.getServiceSummary(serviceId)
  }

  handleDrawerServiceSummaryClose = () => {
    this.setState({ isDrawerOpen: false })
  }

  handleDrawerEmployeeInfoOpen = (uuid: string) => {
    this.setState({ isEmployeeInfoOpen: true })
    this.props.getEmployeeInfo(uuid)
  }
  handleDrawerEmployeeInfoClose = () => {
    this.setState({ isEmployeeInfoOpen: false })
  }

  RenderLayout = () => {
    const { state, createReport, changePaging } = this.props
    const isLoading = state.get('isLoading')
    const error = state.get('error')
    const report = state.get('data')

    const filteredCaptionsAccrodingHideColumns = TABLE_CAPTIONS.filter(
      (caption) => !report?.hideColumns?.includes(caption.key as IHideColumns)
    )

    if (isLoading && !report) return <TableStatus showStatus="InitLoading" />
    if (!report || error) return <TableStatus showStatus="NoData" />
    if (!report?.data?.totalCount) return <TableStatus showStatus="NothingFound" />
    return (
      <Table<IServiceReport, IServiceReportItem>
        modelName="reports"
        state={state}
        captions={filteredCaptionsAccrodingHideColumns}
        withPageSize={true}
        getData={createReport}
        changePage={changePaging}
        renderItem={(item) => (
          <ReportLineItem
            key={item?.service?.serviceId}
            data={omit(item, report.hideColumns || []) as IServiceReportItem | IEmployeeActivityItem}
            onClick={this.handleDrawerServiceSummaryOpen!}
            onEmployeeInfoOpen={this.handleDrawerEmployeeInfoOpen!}
          />
        )}
      />
    )
  }

  render() {
    const { state, createReport, selectFastPeriod, resetFilters } = this.props
    const data = state.get('data')
    const hiddenColumns = data?.hideColumns?.map((col) => captionsToFiltersRatio[col] ?? col)
    const totalCount = data?.data?.totalCount
    const averageRating = data?.averageRating

    return (
      <Box minHeight="90vh">
        <FiltersPanel<IServiceReport, IServiceReportFilter>
          title="Активности"
          description={
            totalCount && averageRating
              ? `найдено ${data?.data?.totalCount}, средняя оценка ${averageRating}`
              : undefined
          }
          state={state}
          getData={createReport}
          selectFastPeriod={selectFastPeriod}
          resetFilters={resetFilters}
          withExcelButton={true}
          hiddenFilters={hiddenColumns}
        />
        <this.RenderLayout />
        <ServiceSummary open={this.state.isDrawerOpen} onClose={this.handleDrawerServiceSummaryClose} />
        <EmployeeInfo open={this.state.isEmployeeInfoOpen} onClose={this.handleDrawerEmployeeInfoClose} />
        <ScrollToTop />
      </Box>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  state: state.reports,
})

const mapDispatchToProps = {
  createReport: reports.actions.getData,
  changePaging: reports.actions.changePaging,
  selectFastPeriod: reports.actions.onSelectFastPeriod,
  resetFilters: reports.actions.resetFilters,
  getServiceSummary: ServiceSummaryActions.getReportServiceSummary,
  getEmployeeInfo: EmployeeInfoActions.getData,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(Reports)
