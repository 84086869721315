import daniil from 'assets/images/daniil.svg';
import tanya from 'assets/images/tanya.svg';
import andrey from 'assets/images/andrey.svg';
import aleksandr from 'assets/images/aleksandr.svg';
import evgeniy from 'assets/images/evgeniy.svg';
import andrey_P from 'assets/images/andrey_P.svg';

const TEAM_MEMBERS = [
  {
    name: 'Даниил Скрыльников',
    role: 'Product Owner, проектировщик интерфейса',
    photo: daniil,
  },
  {
    name: 'Татьяна Максимова',
    role: 'UX/UI-дизайнер',
    photo: tanya,
  },
  {
    name: 'Андрей Молотков',
    role: 'Backend-разработчик, архитектор',
    photo: andrey,
  },
  {
    name: 'Александр Караман',
    role: 'Backend-разработчик',
    photo: aleksandr,
  },
  {
    name: 'Евгений Гузман',
    role: 'Frontend-разработчик',
    photo: evgeniy,
  },
  {
    name: 'Андрей Платунов',
    role: 'QA-инженер',
    photo: andrey_P,
  },
]

export default TEAM_MEMBERS