import { IServiceDescription, /*IReportDepartmentStatistic,*/ IVoteSummary, IReportService, IServicesStatisticRecord } from './reports'

export type IRoles = 'None' | 'Client' | 'Manager' | 'BusinessAnalyst' | 'Admin'

export type Status = 200 | 204

export enum Statuses {
  NoContent = 204,
  Success = 200
}


export type IPerson = {
  uid: string
  firstName: string
  middleName: string
  lastName: string
  avatarUrl: string
}

//TODO: думаю стоит выделить в отдельный файл модели для юзера
export type IUser = {
  person: IPerson
  role: IRoles
  lvs: 0
  lvsStatus: 'Temporary unavailable'
}

export type IPageSizes = {
  reports?: number
  activities?: number
  champions?: number
}

export type IUserSettings = {
  pageSizes: IPageSizes
}

export type Statistics = {
  totalVotedVotesCount: number
  totalPublicVotedVotesCount: number
  totalVotedClientsCount: number
  // departmentsStatistic: IReportDepartmentStatistic
  servicesStatistic: IServicesStatisticRecord[]
}

//TODO: думаю стоит выделить в отдельный файл модели для Vote
export type IVote = {
  id: number
  bonusLvsAmount: number
  lvsToBeRewarded: number
  bonusLvsEligibleDueDate: Date
  commentIsRequiredRatings: number[]
  maxRating: number
  minRating: number
  serviceTypeTitle: string
  managers: IPerson[]
  noExecutorsInManagers: boolean
  pollCommentMode: 'Disabled' | 'Optional' | 'Required'
  serviceDescriptionObj?: string
  serviceTitle: string
  rating: number
  serviceProvisionDate: Date
  votedDate?: string | Date
  votedByUid?: string
  descriptions: IServiceDescription[]
  ratingComment?: string
}

export type IVoted = {
  vote: IVote
  notVotedServicesCount: number
}

export type IReward = {
  id: number
  client: IPerson
  manager: IPerson
  rewardedDate: string | null
  lvsToBeRewarded: number
}

export type IFastPeriods = 'Месяц' | 'Квартал' | 'Год'

export type SortOrders = 'Asc' | 'Desc'

export type ServiceReportSortableColumns = 'Id' | 'Title' | 'Department' | 'Date' | 'Executor' | 'Client' | 'Rating'

export type IHideColumns = 'department' | 'serviceTypeTitle' | 'serviceTypes'

export type IHideFilters = 'clients' | 'serviceTypes'

export type IListFilter<T = string> = {
  items: IListFilterItem<T>[]
}

export type IListFilterItem<T = string> = {
  key: string
  value: T
  enabled: boolean
  selected: boolean
}

export type IDatePeriodFilterIDatePeriodFilter = {
  from: Date | string
  to: Date | string
}

//TODO: возможно стоит создать отедльный файл для паджинированного списка
export type IPagingSettings = {
  pageIndex: number
  pageSize: number
  fetchTotalCount?: boolean
}

export type ServiceReportSortableColumnsISortingSettings = {
  fieldName: ServiceReportSortableColumns
  sortOrder: SortOrders
}

// New types for paginated list

export interface IBaseReport<FILTER = IBaseFilters, DATA = IBaseData<any>> {
  filter: FILTER,
  data: DATA,
}

export interface IReportWithHideColumns<FILTER = IBaseFilters, DATA = IBaseData<any>> extends IBaseReport<FILTER, DATA> {
  hideColumns: IHideColumns[]
}

export interface IReportWithHideFilters<FILTER = IBaseFilters, DATA = IBaseData<any>> extends IBaseReport<FILTER, DATA> {
  hideFilters: IHideFilters[]
}

export type IRequiredFilters = {
  paging: IPagingSettings,
}

export type IBaseFilters = IRequiredFilters & {
  search?: string | null
  serviceTypes?: IListFilter
  clients?: IListFilter
  period: IDatePeriodFilterIDatePeriodFilter
  // paging: IPagingSettings // needed in required filters
  sorting?: ServiceReportSortableColumnsISortingSettings
  withCommentsOnly: boolean | null //doesn't exists in activities filters
  [key: string]: any
}

export interface IBaseData<T> {
  data: T[]
  totalCount: number
}

export type IBaseSummary = {
  serviceId: number
  serviceTypeId: number
  provisionDate: string
  title: IServiceDescription[] | null
  headerDescriptions: IServiceDescription[] | null
  footerDescriptions?: IServiceDescription[] | null
  clients?: IVoteSummary[]
}

export type IEmployeeItem = {
  serviceTypeTitle: string
  service: IReportService
  provisionDate: string
}