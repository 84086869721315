import { call, put, takeLatest, select, ForkEffect } from 'redux-saga/effects'

import { AppState } from 'store'
import UserSettings from 'services/UserSettings'

import { IBaseFilters, IPagingSettings, IRequiredFilters, Status } from 'models'
import { BasePaginatedActionNames, FilteredPaginatedActionNames, FilteredPaginatedWithCommentsActionNames } from 'store/helpers/paginatedListFactory/actionNames'
import { BasePaginatedActions, FilteredPaginatedActions, FilteredPaginatedWithCommentsActions } from './actions'

import { INITIAL_FILTER } from 'store/helpers/filters'
import { IBasePaginateState } from './reducer'

import { Record } from 'immutable'
import { ModelName } from '.'
import { AxiosPromise } from 'axios'
import { IServiceCommentModel } from 'models/reports'


/**
 * Описание API-методов бэка, используемых в сагах
 */
export interface SagasApiActions<DATA, FILTER extends IRequiredFilters> {
  getData(filter: FILTER): AxiosPromise<DATA>
  createComment(serviceId: number, content: string): AxiosPromise<IServiceCommentModel>
  removeComment(serviceId: number): AxiosPromise<IServiceCommentModel>
}

/**
* Получение данных - списка
*/

function getData<DATA, FILTER extends IRequiredFilters>(modelName: ModelName = 'reports', actions: BasePaginatedActions<DATA, FILTER>, getData: SagasApiActions<DATA, FILTER>['getData']) {

  return function* getDataFlow(action: ReturnType<typeof actions.getData>) {
    try {
      const currentFilter: FILTER = yield select((state: AppState) => {
        const data: Record<IBasePaginateState<any, any>> = state[modelName]
        return data.get('currentFilter').toJS()
      })

      currentFilter.paging.pageIndex = 1
      currentFilter.paging.pageSize = UserSettings.pageSize[modelName] || 10

      const nextFilter: FILTER = { ...currentFilter, ...action.payload }

      const { data, status }: { data: DATA, status: Status } = yield call(
        getData,
        nextFilter,
      )
      yield put(actions.getDataSuccess(data, nextFilter, status))
    } catch (err) {
      console.error(err.response)
      yield put(actions.getDataFail(err))
    }
  }

}

/**
* Получение данных - списка (с новыми параметрами пагинации)
*/
function changePaging<DATA, FILTER extends IRequiredFilters>(modelName: ModelName = 'reports', actions: BasePaginatedActions<DATA, FILTER>, getData: SagasApiActions<DATA, FILTER>['getData']) {
  return function* changePagingFlow(action: ReturnType<typeof actions.changePaging>) {
    try {

      const currentFilter: FILTER = yield select((state: AppState) => {
        const data: Record<IBasePaginateState<any, any>> = state[modelName]
        return data.get('currentFilter').toJS()
      })

      const { pageIndex = 1, pageSize = currentFilter.paging.pageSize, fetchTotalCount = currentFilter.paging.fetchTotalCount } = action.payload

      const paging: IPagingSettings = { pageIndex, pageSize, fetchTotalCount }

      const nextFilter: FILTER = { ...currentFilter, paging }

      const { data } = yield call(getData, nextFilter)
      yield put(actions.getDataSuccess(data, nextFilter))
    } catch (err) {
      console.error(err.response)
      yield put(actions.getDataFail(err))
    }
  }
}

/**
* Сброс текущих фильтров и получение данных
*/
function resetFilters<DATA, FILTER extends IBaseFilters>(modelName: ModelName = 'reports', actions: FilteredPaginatedActions<DATA, FILTER>, getData: SagasApiActions<DATA, FILTER>['getData']) {
  return function* resetFiltersFlow(action: ReturnType<typeof actions.resetFilters>) {
    try {

      const currentFilter: FILTER = yield select((state: AppState) => {
        const data: Record<IBasePaginateState<any, any>> = state[modelName]
        return data.get('currentFilter').toJS()
      })

      const withResetOnlyFilters: FILTER = {
        ...(INITIAL_FILTER as FILTER),
        search: currentFilter.search,
        paging: { pageIndex: 1, pageSize: currentFilter.paging.pageSize },
        period: currentFilter.period,
      }

      const { data }: { data: DATA } = yield call(
        getData,
        withResetOnlyFilters,
      )
      yield put(actions.getDataSuccess(data, withResetOnlyFilters))
    } catch (err) {
      console.error(err.response)
      yield put(actions.getDataFail(err))
    }
  }
}

/**
* Обновление данных с текущими фильтрами
*/
function refreshWithCurrentFilters<DATA, FILTER extends IBaseFilters>(modelName: ModelName = 'reports', actions: FilteredPaginatedActions<DATA, FILTER>, getData: SagasApiActions<DATA, FILTER>['getData']) {
  return function* refreshWithCurrentFiltersFlow() {
    try {
      const currentFilter: FILTER = yield select((state: AppState) => {
        const data: Record<IBasePaginateState<any, any>> = state[modelName]
        return data.get('currentFilter').toJS()
      })
      const { data, status }: { data: DATA, status: Status } = yield call(
        getData,
        currentFilter,
      )
      yield put(actions.getDataSuccess(data, currentFilter, status))
    } catch (err) {
      console.error(err.response)
      yield put(actions.getDataFail(err))
    }
  }

}

/**
* Создание комментария
*/
function createManagerComment<DATA, FILTER extends IBaseFilters>(
  _: ModelName = 'reports',
  actions: FilteredPaginatedWithCommentsActions<DATA, FILTER>,
  getData: SagasApiActions<DATA, FILTER>['createComment']
) {
  return function* createManagerCommentFlow(action: ReturnType<typeof actions.createManagerComment>) {
    try {
      const { serviceId, commentContent } = action.payload

      const { data } = yield call(getData, serviceId, commentContent)
      yield put(actions.createManagerCommentSuccess(serviceId, data))
    } catch (err) {
      console.error(err.response)
      yield put(actions.getDataFail(err))
    }
  }

}

/**
* Удаление комментария
*/
function removeManagerComment<DATA, FILTER extends IBaseFilters>(
  _: ModelName = 'reports',
  actions: FilteredPaginatedWithCommentsActions<DATA, FILTER>,
  getData: SagasApiActions<DATA, FILTER>['removeComment']
) {
  return function* removeManagerCommentFlow(action: ReturnType<typeof actions.removeManagerComment>) {
    try {
      const { data } = yield call(getData, action.payload)
      yield put(actions.removeManagerCommentSuccess(action.payload, data))
    } catch (err) {
      console.error(err.response)
      yield put(actions.getDataFail(err))
    }
  }

}

export function* addSagas(sagas: ForkEffect[] = []) {
  for (const saga of sagas) {
    yield saga
  }
}

export const createPaginatedListSagas = <DATA, FILTER extends IRequiredFilters>(
  modelName: ModelName,
  { GET_DATA, CHANGE_PAGING }: BasePaginatedActionNames,
  actions: BasePaginatedActions<DATA, FILTER>,
  getItems: SagasApiActions<DATA, FILTER>['getData'],
  extraSagas: ForkEffect[] = []
) => {
  return function* watch() {
    yield addSagas([
      takeLatest(GET_DATA, getData(modelName, actions, getItems)),
      takeLatest(CHANGE_PAGING, changePaging(modelName, actions, getItems)),
      ...extraSagas
    ])
  }
}

export const createFilteredPaginatedListSagas = <DATA, FILTER extends IBaseFilters>(
  modelName: ModelName, { REFRESH_WITH_CURRENT_FILTERS, RESET_FILTERS, ...rest }: FilteredPaginatedActionNames,
  actions: FilteredPaginatedActions<DATA, FILTER>,
  getItems: SagasApiActions<DATA, FILTER>['getData'],
  extraSagas: ForkEffect[] = []
) => {
  return function* watch() {
    yield createPaginatedListSagas<DATA, FILTER>(modelName, rest, actions, getItems, [
      takeLatest(REFRESH_WITH_CURRENT_FILTERS, refreshWithCurrentFilters(modelName, actions, getItems)),
      takeLatest(RESET_FILTERS, resetFilters(modelName, actions, getItems)),
      ...extraSagas
    ])()
  }
}

export const createFilteredPaginatedListWithCommentsSagas = <DATA, FILTER extends IBaseFilters>(
  modelName: ModelName,
  { CREATE_MANAGER_COMMENT, REMOVE_MANAGER_COMMENT, ...rest }: FilteredPaginatedWithCommentsActionNames,
  actions: FilteredPaginatedWithCommentsActions<DATA, FILTER>,
  api: SagasApiActions<DATA, FILTER>,
  extraSagas: ForkEffect[] = []
) => {
  return function* watch() {
    yield createFilteredPaginatedListSagas<DATA, FILTER>(modelName, rest, actions, api.getData, [
      takeLatest(CREATE_MANAGER_COMMENT, createManagerComment(modelName, actions, api.createComment)),
      takeLatest(REMOVE_MANAGER_COMMENT, removeManagerComment(modelName, actions, api.removeComment)),
      ...extraSagas
    ])()
  }
}