import React from 'react'

import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import { ServiceReportSortableColumnsISortingSettings, ServiceReportSortableColumns } from 'models'
import { ITableCaption } from '../Table'

import { SortType } from '@ocs.lab/ui/lib/components/icons'
import { useTheme } from '@material-ui/core/styles'

type Props = {
  captions: ITableCaption[]
  currentSortType?: ServiceReportSortableColumnsISortingSettings
  onChangeSort: (sorting: ServiceReportSortableColumnsISortingSettings) => void
}

const HeadTable: React.FC<Props> = ({ captions, currentSortType, onChangeSort }) => {
  const { palette } = useTheme()
  return (
    <TableHead>
      <TableRow>
        {captions.map((caption, i) => {
          const handleChangeSortFieldName = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
            if (caption.sortKey) {
              const sortOrder =
                currentSortType?.fieldName === caption.sortKey
                  ? currentSortType?.sortOrder === 'Asc'
                    ? 'Desc'
                    : 'Asc'
                  : 'Asc'
              onChangeSort({ fieldName: caption.sortKey as ServiceReportSortableColumns, sortOrder })
            }
          }
          const IconComponent = () =>
            currentSortType ? (
              <SortType
                style={{ marginRight: 3, marginLeft: 3 }}
                type={currentSortType.sortOrder}
                isVisible={currentSortType?.fieldName === caption.sortKey}
              />
            ) : null
          return (
            <TableCell key={caption.key} style={{ fontSize: 12, color: palette.text.secondary }}>
              <TableSortLabel
                style={{ cursor: caption.sortKey ? 'pointer' : 'text' }}
                active={currentSortType?.fieldName === caption.sortKey}
                direction={currentSortType?.sortOrder.toLocaleLowerCase() as 'asc' | 'desc'}
                onClick={handleChangeSortFieldName}
                IconComponent={IconComponent}
              >
                {caption.title.toUpperCase()}
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export default HeadTable
