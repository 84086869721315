export type BaseFetchActionNames = {
  GET_DATA: string,
  GET_DATA_SUCCESS: string,
  GET_DATA_FAIL: string
}

export const createBaseFetchActionNames = (modelName: string): BaseFetchActionNames => {
  return {
    GET_DATA: `GET_${modelName}`,
    GET_DATA_SUCCESS: `GET_${modelName}_SUCCESS`,
    GET_DATA_FAIL: `GET_${modelName}_FAIL`,
  }
}