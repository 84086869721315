import React from 'react'

import { Link, FlexColumn, FlexFill, Flex, Text } from '@ocs.lab/ui'
import email from '@ocs.lab/ui/lib/assets/icons/email.svg'

import { TITLE_VARIANTS } from '../../options'

import get from 'assets/images/get.svg'

const AlreadyVotedForm: React.FC = () => {

  const title = TITLE_VARIANTS['voted']

  const Image = () => {
    return <img src={get} alt="" />
  }

  return (
    <FlexFill bgcolor="#fff" justifyContent="center" alignItems="center" height="100vh">
      <FlexColumn>
        <FlexColumn alignItems="center" padding={24}>
          <Text variant="h3" align="center">{title}</Text>
          <Image />
          <FlexColumn mt={16}>
            <Flex alignItems="center">
              <Text color="textSecondary" align="center">
                Дать обратную связь можно также в&nbsp;
                <Link color="primary" underline="none" href="/feedback" target="_blank">
                  личном кабинете
                </Link>
              </Text>
            </Flex>
          </FlexColumn>
          <hr />
          <Flex alignItems="center">
            <Text color="textSecondary" align="center">
              Вопросы, предложения?&nbsp;
            </Text>
            <Link
              color="textSecondary"
              underline="always"
              href={process.env.REACT_APP_ROCKET_SUPPORT_MAIL}
              target="_blank"
            >
              Пиши нам на почту
            </Link>
            <img style={{ marginLeft: 5 }} src={email} alt="" />
          </Flex>
        </FlexColumn>
      </FlexColumn>
    </FlexFill>
  )
}

export default AlreadyVotedForm
