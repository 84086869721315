import principle_clearly from 'assets/images/1_principle_clearly.svg'
import principle_constructivity from 'assets/images/2_principle_constructivity.svg'
import principle_in_time from 'assets/images/3_principle_in_time.svg'
import principle_friendly from 'assets/images/4_principle_friendly.svg'

const PRINCIPLES = [
  {
    title: 'Ясность',
    body: 'Излагайте ваши мысли подробно и точно. Чем полнее будет комментарий, тем проще руководителю разобраться в ситуации и принять меры.',
    photo: principle_clearly,
  },
  {
    title: 'Конструктивность',
    body: 'Описывайте факты, избегайте оценок и обобщений. Старайтесь описывать события, на которые ваш коллега мог непосредственно повлиять.',
    photo: principle_constructivity,
  },
  {
    title: 'Своевременность',
    body: 'Постарайтесь дать обратную связь в течение суток. При большем сроке уже труднее вспомнить все нюансы по задаче и качество фидбека снижается.',
    photo: principle_in_time,
  },
  {
    title: 'Дружелюбие',
    body: 'В комментариях старайтесь использовать позитивный тон письма. Даже если что-то пошло не так, всегда можно подобрать вежливые и аккуратные формулировки.',
    photo: principle_friendly,
  },
]

export default PRINCIPLES