import { fromJS, Record as ImmutableRecord } from 'immutable'

import { IVote } from 'models'
import {
  VoteTypes,
  GET_VOTE,
  GET_VOTE_SUCCESS,
  GET_VOTE_FAIL,
  SET_VOTE,
  SET_VOTE_SUCCESS,
  SET_VOTE_FAIL,
  RESET,
  RESET_VOTED,
} from './actions'

type IState = {
  isLoading: boolean
  voteData?: IVote
  error?: string | boolean
  voted: boolean
}

const initialState: ImmutableRecord<IState> = fromJS({
  isLoading: false,
  voteData: undefined,
  error: false,
  voted: false
})

type VotesReducerState = {
  votesData: Record<string, ImmutableRecord<IState>>
  voted: boolean
}

const votesInitialState: VotesReducerState = {
  votesData: {},
  voted: false
}

export const reducer = (state: VotesReducerState = votesInitialState, action: VoteTypes) => {
  
  let voteId: string
  let voteState: ImmutableRecord<IState>

  switch (action.type) {
    case GET_VOTE:
    case SET_VOTE:
      if (!action.payload.voteId) {
        return state
      }
      voteId = action.payload.voteId.toString()
      voteState = state.votesData[voteId] ?? initialState
      return {
        votesData: {
          ...state.votesData,
          [voteId]: voteState.set('isLoading', true)
        },
        voted: state.voted
      }
    case GET_VOTE_SUCCESS:
      voteId = action.payload.id.toString()
      voteState = state.votesData[voteId] ?? initialState
      return {
        votesData: {
          ...state.votesData,
          [voteId]: voteState.set('isLoading', false).set('voteData', action.payload).set('voted', false)
        },
        voted: false
      }
    case SET_VOTE_SUCCESS:
      voteId = action.payload.id.toString()
      voteState = state.votesData[voteId] ?? initialState
      return {
        votesData: {
          ...state.votesData,
          [voteId]: voteState.set('isLoading', false).set('voted', true)
        },
        voted: true
      }
    case GET_VOTE_FAIL:
    case SET_VOTE_FAIL:
      if (!action.payload.voteId) {
        return state
      }
      voteId = action.payload.voteId
      voteState = state.votesData[voteId] ?? initialState
      return {
        votesData: {
          ...state.votesData,
          [voteId]: voteState.set('isLoading', false).set('error', action.payload.error)
        },
        voted: state.voted
      }
    case RESET_VOTED:
      return {
        ...state,
        voted: false
      }
    case RESET:
      return votesInitialState
    default:
      return state
  }
}



export default reducer
