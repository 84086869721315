import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { FlexColumn, Box } from '@ocs.lab/ui'

import LikeImage from 'assets/images/feedback-like.png'
import { HOVER } from '@ocs.lab/ui/lib/theme/helpers'

type LikeButtonProps = {
  count: number
  isActive?: boolean
  onClick?: React.MouseEventHandler<HTMLElement>
}

const useStyles = makeStyles(({ palette }) => ({
  root: ({ isActive }: { isActive: boolean }) => ({
    position: 'absolute',
    top: -15,
    width: 50,
    height: 50,
    background: `url("${LikeImage}") no-repeat`,
    backgroundSize: 'auto 50px',
    backgroundPosition: isActive ? '-1450px 0' : '0 0',
    cursor: 'pointer',
    transition: 'background-position 1s steps(28)',
    transitionDuration: isActive ? '1s' : '0s',
    [HOVER]: {
      backgroundPosition: isActive ? '-1450px 0' : '-50px 0',
    },
  }),
  wrapper: ({ isActive }: { isActive: boolean }) => ({
    position: 'relative',
    alignItems: 'center',
    paddingTop: 16,
    color: isActive ? palette.error.main : palette.text.secondary,
  }),
}))

const LikeButton: React.VFC<LikeButtonProps> = ({ count, isActive, onClick }) => {
  const [active, setActive] = useState(isActive ?? false)
  const [disabled, setDisabled] = useState(false)
  const classes = useStyles({ isActive: active ?? false })
  useEffect(() => {
    setDisabled(false)
    if (isActive !== active) {
      setActive(isActive!)
    }
  }, [isActive]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <FlexColumn className={`${classes.wrapper} like-btn ${active ? 'active' : ''}`}>
      <Box
        className={classes.root}
        onClick={(e) => {
          if (!disabled) {
            setDisabled(true)
            setActive(!active)
            onClick && onClick(e)
          }
        }}
      ></Box>
      {count > 0 ? (count >= 1000 ? count?.toString()[0] + 'k' : count) : null}
    </FlexColumn>
  )
}

export default LikeButton
