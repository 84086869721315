import React from 'react'

import { Box, FlexColumn, Text } from '@ocs.lab/ui'

import FAQAccrodion from './components/FAQAccrodion'
import FAQS from './faqs'

const FAQ = () => {
  return (
    <FlexColumn id="faq" bgcolor="#fff" py={120} alignItems="center" overflow="hidden">
      <Text variant="h1">Вопросы и ответы</Text>
      <Box width={766} mt={84}>
        {FAQS.map((faq) => (
          <FAQAccrodion key={faq.title} {...faq} />
        ))}
      </Box>
    </FlexColumn>
  )
}

export default FAQ
