import * as yup from 'yup'

/**
 * func for format errors object to key:error
 */
export function formatErrors<T extends object>(err: yup.ValidationError): T {
  if (!err.inner) return Object()
  //FIXME:
  const errors: any = Object()
  err.inner.forEach((error: yup.ValidationError) => {
    errors[error.path!] = error.errors[0]
  })
  return errors
}


export const MAX_COMMENT_LENGTH = 500
export const MAX_ACTIVITY_LENGTH = 70
export const MAX_EXECUTORS = 10
export const MIN_EXECUTORS = 1

export const thanskFormSchema = yup.object().shape({
  executors: yup.array().min(MIN_EXECUTORS).max(MAX_EXECUTORS),
  activity: yup.string().max(MAX_ACTIVITY_LENGTH).required(),
  comment: yup.string().max(MAX_COMMENT_LENGTH).required(),
})

// export default null