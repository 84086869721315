import { fromJS, Record } from 'immutable'

import { Statistics } from 'models'
import {
  StatisticsTypes,
  GET_STATISTICS,
  GET_STATISTICS_SUCCESS,
  GET_STATISTICS_FAIL
} from './actions'

type IState = {
  isLoading: boolean
  data?: Statistics
  error?: string | boolean
}

const initialState: Record<IState> = fromJS({
  isLoading: true,
  data: undefined,
  error: false,
})

export const reducer = (state = initialState, action: StatisticsTypes) => {
  switch (action.type) {
    case GET_STATISTICS:
      return state.set('isLoading', true)
    case GET_STATISTICS_SUCCESS:
      return state.set('isLoading', false).set('data', action.payload)
    case GET_STATISTICS_FAIL:
      return state.set('isLoading', false).set('error', action.payload)
    default:
      return state
  }
}

export default reducer
