import { createAction, ActionsUnion } from '../helpers/actions'
import { Statistics } from 'models'

export const GET_STATISTICS = 'GET_STATISTICS'
export const GET_STATISTICS_SUCCESS = 'GET_STATISTICS_SUCCESS'
export const GET_STATISTICS_FAIL = 'GET_STATISTICS_FAIL'

const StatisticsActions = {
  getStatistics: () => createAction(GET_STATISTICS),
  getStatisticsSuccess: (data: Statistics) => createAction(GET_STATISTICS_SUCCESS, data),
  getStatisticsFail: (error: string | boolean) => createAction(GET_STATISTICS_FAIL, error),
}

export type StatisticsTypes = ActionsUnion<typeof StatisticsActions>

export default StatisticsActions
