import { IUserSettings, IPageSizes } from 'models'

class UserSettings {
  private static _settings: IUserSettings = { pageSizes: { reports: 10, activities: 10, champions: 10 } }

  static keyName: string = 'user_settings'

  constructor(settings: IUserSettings) {
    UserSettings._settings = settings
  }

  static set pageSize(pageSize: IPageSizes) {
    UserSettings._settings.pageSizes = { ...UserSettings.pageSize, ...pageSize }
  }

  static get pageSize() {
    return UserSettings._settings.pageSizes
  }

  static get settings(): IUserSettings {
    return UserSettings._settings
  }
}

export default UserSettings