import React from 'react'
import { useSelector, useDispatch } from 'store'

import { Accordion, Box, Button, Dialog, FlexColumn, Typography } from '@ocs.lab/ui'
import { HOVER, LAST_CHILD, FIRST_CHILD } from '@ocs.lab/ui/lib/theme/helpers'
import { ClearIcon } from '@ocs.lab/ui/lib/components/icons'

import { makeStyles, useTheme } from '@material-ui/core/styles'

import { reports } from 'store/reports'
import StatisticsActions from 'store/statistics/actions'
import { IReportDepartmentsFilterItem } from 'models/reports'

const useStyles = makeStyles(({ palette }) => ({
  rootButton: {
    width: 200,
    height: 40,
    position: 'relative',
    borderRadius: 12,
    backgroundColor: '#fff',
    transition: 'none',
    justifyContent: 'left',
    border: '2px solid #fff',
    overflow: 'hidden',
    [HOVER]: {
      borderColor: palette.action.focus,
      backgroundColor: '#fff',
      '& .MuiButton-endIcon': {
        display: 'inherit',
      },
    },
  },
  labelButton: {
    justifyContent: 'space-between',
  },
  endIconButton: {
    display: 'none',
    [HOVER]: {
      backgroundColor: '#fff',
    },
  },
  buttonText: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'initial',
  },
  listItemGutters: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  subButton: {
    minWidth: 58,
    width: 58,
    height: 38,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 12,
    border: '2px solid',
    borderColor: palette.background.default,
    backgroundColor: '#fff',
  },
  menuItem: {
    width: 200,
    height: 48,
    display: 'flex',
    justifyContent: 'flex-end',
    overflow: 'visible',
    [FIRST_CHILD]: {
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    },
    [LAST_CHILD]: {
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
    },
  },
  subMenuItem: {
    width: 120,
    display: 'flex',
    paddingLeft: 25,
    [FIRST_CHILD]: {
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    },
    [LAST_CHILD]: {
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
    },
  },
  button: {
    padding: '6px 20px',
  },
}))

interface Props {
  data: IReportDepartmentsFilterItem[]
}

const AccordionFilter: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(StatisticsActions.getStatistics())
  }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps
  // const departmentsStatistic = useSelector((state) => state.statistics.get('data')?.departmentsStatistic)

  const { palette } = useTheme()
  const styles = useStyles()
  const currentFilter = useSelector(({ reports }) => reports.get('currentFilter'))
  const currentDepartment: IReportDepartmentsFilterItem | undefined = currentFilter.getIn(['departments', 'items', 0])

  const title = currentDepartment?.value ?? 'Подразделение'
  const [isOpen, setIsOpen] = React.useState(false)

  const [activePath, setActivePath] = React.useState<string[]>()

  const handleButton = () => {
    if (data.length > 0) {
      setIsOpen(!isOpen)
    }
  }

  const handleChange = (item: IReportDepartmentsFilterItem, path?: string[]) => {
    setIsOpen(false)
    setActivePath(path)
    dispatch(
      reports.actions.getData({
        departments: {
          items: [{ ...item }],
        },
      })
    )
  }

  const onClear = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation()
    setActivePath([])
    dispatch(reports.actions.getData({ departments: undefined }))
  }

  const EndHeaderComponent = ({ item, openPath }: any) => {
    return (
      <Button
        color="primary"
        style={{ padding: '8px 20px' }}
        onClick={(e: any) => {
          e.stopPropagation()
          handleChange(item, openPath)
        }}
      >
        Выбрать
      </Button>
    )
  }

  return (
    <>
      <Box position="relative">
        <Button
          variant="outlined"
          style={{
            borderColor: isOpen ? palette.action.focus : undefined,
          }}
          className={styles.rootButton}
          classes={{ label: styles.labelButton, endIcon: styles.endIconButton }}
          endIcon={currentDepartment?.value ? <ClearIcon onClick={onClear} /> : null}
          onClick={handleButton}
        >
          <Typography
            className={styles.buttonText}
            style={{
              color: currentDepartment?.value ? undefined : palette.text.secondary,
            }}
          >
            {title}
          </Typography>
        </Button>
      </Box>

      <Dialog
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(!isOpen)
        }}
        onOverlayClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <FlexColumn
          gridGap={8}
          maxWidth="1028px"
          minWidth="900px"
          minHeight="400px"
          bgcolor="#fff"
          margin="auto"
          padding={8}
          borderRadius={16}
        >
          <Accordion
            items={data}
            openPathQueue={activePath}
            activePathQueue={activePath?.map((id, index) => (activePath.length - 1 > index ? '' : id))}
            EndHeaderComponent={EndHeaderComponent}
            getId={(item) => item.key}
            getTitle={(item) => item.value}
            getChildren={(item) => item.childs}
          />
        </FlexColumn>
      </Dialog>
    </>
  )
}

export default AccordionFilter
