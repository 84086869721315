import React from 'react'

import { FlexColumn, FlexFill, Text } from '@ocs.lab/ui'

import Way from './components/Way'
import WAYS from './ways'

const Ways = () => {
  return (
    <FlexColumn bgcolor="#fff" py={120} maxWidth={1600} margin="auto">
      <Text variant="h1" align="center">
        Три способа оставить фидбек
      </Text>
      <FlexFill justifyContent="space-evenly" pt={80}>
        {WAYS.map((way) => (
          <Way key={way.title} {...way} />
        ))}
      </FlexFill>
    </FlexColumn>
  )
}

export default Ways
