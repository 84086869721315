import React from 'react'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import { Text } from '@ocs.lab/ui'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import { FlexColumn } from '@ocs.lab/ui'
import { LAST_CHILD, FIRST_CHILD, HOVER } from '@ocs.lab/ui/lib/theme/helpers'

import { IEmployee, ITopExecutorReportItem } from 'models/champions'

import User from '@ocs.lab/ui/lib/components/elements/User'
import LikesPreview from '../Likes/LikesPreview'
import { champions } from 'store/champions'
import { useDispatch } from 'store'

const useStyles = makeStyles((theme) => ({
  container: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    [LAST_CHILD]: {
      borderBottom: 'none',
    },
    [FIRST_CHILD]: {
      borderTop: 'none',
    },
    [HOVER]: {
      backgroundColor: `${theme.palette.text.hint} !important`,
    },
  },
  clientCell: {
    width: '20%',
  },
  serviceTypeTitleCell: {
    maxWidth: 350,
    overflowX: 'scroll',
  },
  executorsCell: {
    width: '20%',
  },
  publicCommentCell: {
    maxWidth: 350,
    overflowX: 'scroll',
  },
  likesCell: {
    minWidth: 50,
    boxSizing: 'border-box',
  },
}))

type Props = {
  data: ITopExecutorReportItem
  onEmployeeInfoOpen?: (userId: string) => void
  onLikesModalOpen?: (data: IEmployee[]) => void
}

const TopExecutorReportItem: React.FC<Props> = ({ data, onEmployeeInfoOpen, onLikesModalOpen }) => {
  const { container, clientCell, serviceTypeTitleCell, executorsCell, publicCommentCell, likesCell } = useStyles()

  const dispatch = useDispatch()

  const handleEmployeeInfoOpen = (uid: string) => onEmployeeInfoOpen && onEmployeeInfoOpen(uid)

  return (
    <TableRow className={container} hover>
      <TableCell className={clientCell}>
        <User
          key={data.client.person.uid}
          name={data.client.person.displayName ?? 'Сотрудник больше не работает в компании'}
          description={data.client.position}
          avatarUrl={data.client.person.avatarUrl ?? ''}
          onClick={() => {
            if (data.client.person.displayName) handleEmployeeInfoOpen(data.client.person.uid)
          }}
        />
      </TableCell>
      <TableCell className={serviceTypeTitleCell}>
        <Text style={{ whiteSpace: 'pre-line' }}>{data.serviceTypeTitle}</Text>
      </TableCell>
      <TableCell className={executorsCell}>
        <FlexColumn>
          {data.executors?.map((executor) => (
            <User
              key={executor.person.uid}
              name={executor.person.displayName ?? 'Сотрудник больше не работает в компании'}
              description={executor.position}
              avatarUrl={executor.person.avatarUrl ?? ''}
              onClick={() => {
                if (executor.person.displayName) handleEmployeeInfoOpen(executor.person.uid)
              }}
            />
          ))}
        </FlexColumn>
      </TableCell>
      <TableCell className={publicCommentCell}>
        <Text style={{ whiteSpace: 'pre-line' }}>{data.publicComment}</Text>
      </TableCell>
      <TableCell>
        <Text color="textSecondary">{moment(data.feedbackDate).format('DD.MM.YYYY')}</Text>
      </TableCell>
      <TableCell className={likesCell}>
        <LikesPreview
          isActive={data.isLikedByMe}
          data={data.likedBy}
          onClick={onLikesModalOpen}
          onToggleLike={(value: boolean) => dispatch(champions.actions.togglePublicCommentLike(value, data.voteId))}
        />
      </TableCell>
    </TableRow>
  )
}

export default React.memo(TopExecutorReportItem)
