import { fromJS, Record } from 'immutable'
import { IEmployeeInfo } from 'models/employee/activities'

import {
  EmployeeInfoTypes,
  GET_REPORT_EMPLOYEE_INFO,
  GET_EMPLOYEE_INFO_SUCCESS,
  GET_EMPLOYEE_INFO_FAIL,
  RESET_EMPLOYEE_INFO,
} from './actions'

type IState = {
  isLoading: boolean
  data?: IEmployeeInfo
  error?: string | boolean
}

const initialState: Record<IState> = fromJS({
  isLoading: true,
  data: undefined,
  error: false,
})

export const reducer = (state = initialState, action: EmployeeInfoTypes) => {
  switch (action.type) {
    case GET_REPORT_EMPLOYEE_INFO:
      return state.set('isLoading', true)
    case GET_EMPLOYEE_INFO_SUCCESS:
      return state.set('isLoading', false).set('data', action.payload).set('error', false)
    case GET_EMPLOYEE_INFO_FAIL:
      return state.set('isLoading', false).set('error', action.payload)
    case RESET_EMPLOYEE_INFO:
      return state.set('data', undefined).set('error', false)
    default:
      return state
  }
}

export default reducer