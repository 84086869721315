import step_by_step_1 from 'assets/images/step-by-step_1.svg'
import step_by_step_2 from 'assets/images/step-by-step_2.svg'
import step_by_step_3 from 'assets/images/step-by-step_3.svg'
import step_by_step_4 from 'assets/images/step-by-step_4.svg'
import step_by_step_5 from 'assets/images/step-by-step_5.svg'

const STEPS = [
  {
    body: 'Сотрудник создает заявку на юридическую помощь на портале ServiceDesk',
    photo: step_by_step_1,
  },
  {
    body: 'Коллега из юридического департамента выполняет задачу и закрывает заявку',
    photo: step_by_step_2,
  },
  {
    body: 'Feedback OCS отправляет заказчику письмо с просьбой дать обратную связь по выполненной задаче',
    photo: step_by_step_3,
  },
  {
    body: 'Сотрудник дает обратную связь удобным ему способом: через письмо или в',
    link: 'личном кабинете',
    photo: step_by_step_4,
  },
  {
    body:
      'Руководитель получает обратную связь. Если что–то пошло не так, то подумает как исправить. Если все отлично — поблагодарит сотрудника за хорошую работу, а заказчика — за полезный фидбек.',
    photo: step_by_step_5,
  },
]

export default STEPS
