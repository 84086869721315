import { createAction, ActionsUnion } from '../helpers/actions'
import { IVote } from 'models'

export const GET_VOTE = 'GET_VOTE'
export const GET_VOTE_SUCCESS = 'GET_VOTE_SUCCESS'
export const GET_VOTE_FAIL = 'GET_VOTE_FAIL'

export const SET_VOTE = 'SET_VOTE'
export const SET_VOTE_SUCCESS = 'SET_VOTE_SUCCESS'
export const SET_VOTE_FAIL = 'SET_VOTE_FAIL'

export const RESET_VOTED = 'RESET_VOTED'
export const RESET = 'RESET'

const VoteActions = {
  getVote: (voteId: number | null, secret: string | null) => createAction(GET_VOTE, { voteId, secret }),
  getVoteSuccess: (data: IVote) => createAction(GET_VOTE_SUCCESS, data),
  getVoteFail: (error: string | boolean, voteId?: string | null) => createAction(GET_VOTE_FAIL, { error, voteId }),

  setVote: (voteId: number, secret: string, { rating, comment, isPublicComment }: { rating: number; comment: string, isPublicComment: boolean }) =>
    createAction(SET_VOTE, { voteId, secret, rating, comment, isPublicComment }),
  setVoteSuccess: (data: IVote) => createAction(SET_VOTE_SUCCESS, data),
  setVoteFail: (error: string | boolean, voteId?: string | null) => createAction(SET_VOTE_FAIL, { error, voteId }),

  resetVoted: () => createAction(RESET_VOTED),
  reset: () => createAction(RESET)
}

export type VoteTypes = ActionsUnion<typeof VoteActions>

export default VoteActions
