import { IRequiredFilters, Status } from "models"
import { createAction, IActionWithPayload } from "../actions"
import { BaseFetchActionNames } from "./actionNames"

// it's now only for paginated list with filters
// need to customize for simple fetch requests

export type getDataAction<FILTER> = IActionWithPayload<string, FILTER>
export type getDataSuccessAction<DATA, FILTER extends IRequiredFilters> = IActionWithPayload<string, { data: DATA, filter: FILTER, status?: Status }>
export type getDataFailAction = IActionWithPayload<string, (string | boolean)>

export type BaseFetchActions<DATA, FILTER extends IRequiredFilters> = {
  getData: (filter: Partial<FILTER>) => getDataAction<Partial<FILTER>>,
  getDataSuccess: (data: DATA, filter: FILTER, status?: Status) => getDataSuccessAction<DATA, FILTER>,
  getDataFail: (error: string | boolean) => getDataFailAction,
}

export const createBaseFetchActions = <DATA, FILTER extends IRequiredFilters>({ GET_DATA, GET_DATA_SUCCESS, GET_DATA_FAIL }: BaseFetchActionNames): BaseFetchActions<DATA, FILTER> => ({
  getData: (filter: Partial<FILTER>) => createAction(GET_DATA, filter),
  getDataSuccess: (data: DATA, filter: FILTER, status?: Status) => createAction(GET_DATA_SUCCESS, { data, filter, status }),
  getDataFail: (error: string | boolean) => createAction(GET_DATA_FAIL, error),
})