import React from 'react'

import { Box, Flex, FlexColumn, Paper, Spinner, Typography } from '@ocs.lab/ui'

import nodata from 'assets/images/nodata.svg'
import _204 from 'assets/images/204.svg'
import nothing_found from 'assets/images/nothing_found.svg'
import no_activities from 'assets/images/no_activities.svg'
import no_champions from 'assets/images/no_champions.svg'
import no_feedbacks from 'assets/images/no_feedbacks.svg'

const EmptyDataContainer: React.FC = ({ children }) => (
  <Box px={24} pb={24}>
    <Paper elevation={1}>
      <Flex
        justifyContent="center"
        pt={30}
        bgcolor="#fff"
        height="60vh"
        minHeight={600}
        boxShadow={0}
        borderRadius={16}
      >
        {children}
      </Flex>
    </Paper>
  </Box>
)

type Props = {
  showStatus: 'NothingFound' | 'NoData' | 'NoContent' | 'InitLoading' | 'NoActivities' | 'NoChampions' | 'NoFeedbacks'
}

const TableStatus: React.FC<Props> = ({ showStatus }) => {
  const GetImageAccordingStatus = React.useCallback(() => {
    switch (showStatus) {
      case 'NoData':
        return <img src={nodata} alt="nodata" />
      case 'NoContent':
        return <img height={220} src={_204} alt="NoContent" />
      case 'NothingFound':
        return <img src={nothing_found} alt="nothing_found" />
      case 'NoActivities':
        return <img height={220} width={916} src={no_activities} alt="no_activities" />
      case 'NoChampions':
        return (
          <Flex height={220} alignItems="center">
            <img height={220} src={no_champions} alt="no_champions" />
            <FlexColumn gridGap={8}>
              <Typography variant="h3">Лав-лента временно недоступна</Typography>
              <Typography>Мы знаем о проблеме и уже работаем над ее устранением</Typography>
            </FlexColumn>
          </Flex>
        )
      case 'NoFeedbacks':
        return <img height={220} src={no_feedbacks} alt="no_feedbacks" />
      default:
        return <Spinner />
    }
  }, [showStatus])

  return (
    <EmptyDataContainer>
      <GetImageAccordingStatus />
    </EmptyDataContainer>
  )
}

export default TableStatus
