import { fromJS, Record } from 'immutable'

import { IServiceReportSummary } from 'models/reports'
import { IEmployeeActivitySummary } from 'models/employee/activities'
import {
  ServiceSummaryTypes,
  GET_REPORT_SERVICE_SUMMARY,
  GET_EMPLOYEE_ACTIVITY_SERVICE_SUMMARY,
  GET_SERVICE_SUMMARY_SUCCESS,
  GET_SERVICE_SUMMARY_FAIL,
  RESET_SERVICE_SUMMARY,
} from './actions'

type IState = {
  isLoading: boolean
  data?: IServiceReportSummary | IEmployeeActivitySummary
  error?: string | boolean
}

const initialState: Record<IState> = fromJS({
  isLoading: true,
  data: undefined,
  error: false,
})

export const reducer = (state = initialState, action: ServiceSummaryTypes) => {
  switch (action.type) {
    case GET_REPORT_SERVICE_SUMMARY:
    case GET_EMPLOYEE_ACTIVITY_SERVICE_SUMMARY:
      return state.set('isLoading', true)
    case GET_SERVICE_SUMMARY_SUCCESS:
      return state.set('isLoading', false).set('data', action.payload).set('error', false)
    case GET_SERVICE_SUMMARY_FAIL:
      return state.set('isLoading', false).set('error', action.payload)
    case RESET_SERVICE_SUMMARY:
      return state.set('data', undefined).set('error', false)
    default:
      return state
  }
}

export default reducer