import React from 'react'
import { useSelector } from 'store'

import { makeStyles } from '@material-ui/core/styles'
import { Link, FlexColumn, Flex, Text } from '@ocs.lab/ui'
import bubble_arrow from '@ocs.lab/ui/lib/assets/icons/bubble-arrow.svg'

import { declensions } from 'utils'

const useStyles = makeStyles((theme) => ({
  statistics_container: {
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 48px',
    marginTop: 60,
    border: '6px solid',
    borderColor: theme.palette.action.active,
    borderRadius: 16,
  },
  count_text: {
    fontSize: 40,
    fontWeight: 900,
  },
}))

const Statistics = () => {
  const { statistics_container, count_text } = useStyles()
  const statistics = useSelector(({ statistics }) => statistics.get('data'))
  return (
    <FlexColumn bgcolor="#fff" py={120} alignItems="center">
      <Flex mt={20}>
        <Text variant="h3" align="center">
          С помощью Feedback OCS легко делиться обратной связью
          <br/>
          по конкретной задаче, которую выполнил коллега.  А еще в любой
          <br/>
          момент можно поблагодарить за помощь и поддержку в{' '}
          <Link variant="h3" color="primary" underline="none" href="/lav-list">лав-ленте</Link>
          .
        </Text>
      </Flex>
      <Flex className={statistics_container}>
        {statistics?.totalVotedClientsCount && (
          <Flex alignItems="baseline">
            <Text className={count_text} noWrap>
              {statistics.totalVotedClientsCount}
            </Text>
            &nbsp;
            <Text variant="h3" noWrap>
              {declensions(statistics.totalVotedClientsCount, [
                'коллега оставил',
                'коллеги оставили',
                'коллег оставили',
              ])}{' '}
            </Text>
            &nbsp;&nbsp;
          </Flex>
        )}
        {statistics?.totalVotedVotesCount && (
          <Flex alignItems="baseline">
            <Text className={count_text} noWrap>
              {statistics.totalVotedVotesCount}
            </Text>
            &nbsp;
            <Text variant="h3" noWrap>
              {declensions(statistics.totalVotedVotesCount, ['отзыв, из них', 'отзыва, из них', 'отзывов, из них'])}{' '}
            </Text>
            &nbsp;&nbsp;
          </Flex>
        )}
        {statistics?.totalPublicVotedVotesCount && (
          <Flex alignItems="baseline">
            <Link style={{ display: 'contents' }} color="primary" underline="none" href="/lav-list" noWrap>
              <Text className={count_text} color="primary" noWrap>
                {statistics.totalPublicVotedVotesCount}
              </Text>
              &nbsp;
              <Text variant="h3" color="primary" noWrap>
                в лав-ленте
              </Text>
              &nbsp;&nbsp;
            </Link>
          </Flex>
        )}
        <Flex position="absolute" bottom={-30} right={53}>
          <img src={bubble_arrow} alt="" />
        </Flex>
      </Flex>
    </FlexColumn>
  )
}

export default Statistics
