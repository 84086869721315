import { Box, FlexCenter, Paper, Typography } from '@ocs.lab/ui'
import React from 'react'
import heart from 'assets/icons/heart_accent.svg'

const Thanks = () => {
  return (
    <Box mx={16}>
      <Paper>
        <FlexCenter flexDirection="column" gridGap={16} height={375}>
          <Typography align='center'>Ты помогаешь коллегам <br/>сделать работу лучше и отметить их успехи!</Typography>
          <Typography variant='h4'>Спасибо</Typography>
          <img src={heart} alt="Heart" />
        </FlexCenter>
      </Paper>  
    </Box>
    
  )
}

export default Thanks