import Api from '.'
import { IPagingSettings } from 'models'
import { IEmployee } from 'models/champions'
import { IEmployeeFeedbackReport } from 'models/employee/feedback'
import { IEmployeeActivityReport, IEmployeeActivityReportFilter, IEmployeeActivitySummary, IEmployeeInfo } from 'models/employee/activities'

class Employee {

  static getEmployee(uid: string) {
    return Api.get<IEmployeeInfo>(`employee/getemployee?employeeUid=${uid}`)
  }

  static getEmployeeFeedback(provisionDateSortOrder: 'Asc' | "Desc" = "Desc", serviceTypeId: string = '') {
    return Api.get<IEmployeeFeedbackReport>(`employee/getemployeefeedbackreport?provisionDateSortOrder=${provisionDateSortOrder}&serviceTypeId=${serviceTypeId}`)
  }

  static getEmployeeActivities(filter: IEmployeeActivityReportFilter) {
    return Api.post<IEmployeeActivityReport>('employee/getemployeeactivities', filter)
  }

  /**
   * Returns service summary by service id
   */
  static getEmployeeActivityServiceSummary(serviceId: number) {
    return Api.get<IEmployeeActivitySummary>(`/employee/getemployeeactivityservicesummary?serviceId=${serviceId}`)
  }

  static getFeedbackCount() {
    return Api.get<number>('/employee/getservicesemployeefeedbackrequiredcount')
  }

  /**
   * Get all employee by search template, exclude current employee
   */

  static findEmployee(searchPattern: string, paging?: IPagingSettings) {
    return Api.get<{ data: IEmployee[], totalCount: number }>(`/employee/findemployee?searchPattern=${searchPattern}&pageIndex=${paging?.pageIndex}&pageSize=${paging?.pageSize}`)
  }

}

export default Employee
