import { Box, Button, Flex, FlexCenter, FlexColumn, Typography } from '@ocs.lab/ui'
import React, { useEffect, useMemo, useState } from 'react'
import rewardedDog from 'assets/images/rewarded_dog.svg'
import { Link, useLocation } from 'react-router-dom'
import alreadyRewarded from 'assets//images/already_rewarded_dog.svg'
import { styled } from '@material-ui/core'
import RewardApi from 'api/reward'
import { IReward } from 'models'
import { declensions } from 'utils'
import PageNotFound from 'screens/404'

const StyledImg = styled('img')({
  width: 150
})

const StyledText = styled(Typography)({
  fontSize: 16,
  textAlign: "center"
})

const AlreadyRewarded = () => {
  return (
    <FlexColumn gridGap={16} alignItems="center">
      <StyledText>
        Сотрудник уже получил от тебя благодарность за полезную обратную связь
      </StyledText>
      <StyledImg src={alreadyRewarded} alt="Already rewarded" />
    </FlexColumn>
  )
}

const Reward = () => {

  const location = useLocation()

  const { rewardId, secret } = useMemo(() => {
    const url = new URLSearchParams(location.search)
    return {
      rewardId: Number(url.get('rewardId')),
      secret: String(url.get('secret'))
    }
  }, [location])

  const [data, setData] = useState<IReward>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const { lvsToBeRewarded = 0 } = data || {}
  const { firstName, lastName } = data?.client || {}
  const alreadyRewarded = !!data?.rewardedDate

  useEffect(() => {
    setLoading(true)
    RewardApi.getRewardInfo(rewardId, secret).then((res) => {
      setLoading(false)
      setData(res.data)
    }).catch((err) => {
      setLoading(false)
      setError(err)
    })
  }, [rewardId, secret]) 

  const content = useMemo(() => {

    if (alreadyRewarded) {
      return <AlreadyRewarded />
    }

    const lavsDeclension = declensions(lvsToBeRewarded, [
      'лав',
      'лава',
      'лавов',
    ])
    const text = `${firstName} ${lastName} получил ${lvsToBeRewarded} ${lavsDeclension} за полезную обратную связь`

    return(
      <FlexColumn gridGap={32} alignItems="center">
        <FlexColumn gridGap={16} alignItems="center" maxWidth={320} px={10}>
          <StyledImg src={rewardedDog} alt="Rewarded dog" />
          <StyledText variant="body2">{text}</StyledText>  
        </FlexColumn>
        <Flex flexWrap="wrap" justifyContent="center" gridGap={16}>
          <Button variant="text" color="primary" component={Link} to="/lav-list">
            Перейти в лав-ленту
          </Button>
          <Button variant="contained" color="primary" component={Link} to="/reports">
            Посмотреть отчеты
          </Button>
        </Flex>
      </FlexColumn>
    )
  }, [alreadyRewarded, firstName, lastName, lvsToBeRewarded])

  if (loading) {
    return null
  }

  if (!rewardId || !secret || error || !firstName || !lastName) {
    return <PageNotFound />
  }

  return (
    <FlexCenter width="100vw" height="100vh" bgcolor="#FFF">
      <Box maxWidth={383}>
        {content}
      </Box>
    </FlexCenter>
  )
}

export default Reward