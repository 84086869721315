import { createBasePaginatedActions, createFilteredPaginatedActions, createFilteredPaginatedWithCommentsActions } from "./actions"
import { createBasePaginatedActionNames, createFilteredPaginatedActionNames, createFilteredPaginatedWithCommentsActionNames } from "store/helpers/paginatedListFactory/actionNames"
import { createFilteredPaginatedListReducer, createFilteredPaginatedListWithCommentsReducer, createPaginatedListReducer } from "./reducer"
import { SagasApiActions, createFilteredPaginatedListWithCommentsSagas, createFilteredPaginatedListSagas, createPaginatedListSagas } from "./sagas"

import { IBaseFilters, IBaseReport, IRequiredFilters } from "models"

export type ModelName = 'reports' | 'activities' | 'champions' // | 'champions' | 'feedback'?

export const createPaginatedList = <DATA extends IBaseReport<IRequiredFilters>, FILTER extends IRequiredFilters>(modelName: ModelName, getData: SagasApiActions<DATA, FILTER>['getData']) => {
  const actionNames = createBasePaginatedActionNames(modelName.toUpperCase())
  const actions = createBasePaginatedActions<DATA, FILTER>(actionNames)
  const reducer = createPaginatedListReducer<DATA, FILTER>(actionNames)
  const sagas = createPaginatedListSagas<DATA, FILTER>(modelName, actionNames, actions, getData)
  return {
    actionNames,
    actions,
    reducer,
    sagas
  }
}

export const createFilteredPaginatedList = <DATA extends IBaseReport, FILTER extends IBaseFilters>(modelName: ModelName, getData: SagasApiActions<DATA, FILTER>['getData']) => {
  const actionNames = createFilteredPaginatedActionNames(modelName.toUpperCase())
  const actions = createFilteredPaginatedActions<DATA, FILTER>(actionNames)
  const reducer = createFilteredPaginatedListReducer<DATA, FILTER>(actionNames)
  const sagas = createFilteredPaginatedListSagas<DATA, FILTER>(modelName, actionNames, actions, getData)
  return {
    actionNames,
    actions,
    reducer,
    sagas
  }
}

export const createFilteredPaginatedListWithComments = <DATA extends IBaseReport, FILTER extends IBaseFilters>(
  modelName: ModelName,
  api: SagasApiActions<DATA, FILTER>
) => {
  const actionNames = createFilteredPaginatedWithCommentsActionNames(modelName.toUpperCase())
  const actions = createFilteredPaginatedWithCommentsActions<DATA, FILTER>(actionNames)
  const reducer = createFilteredPaginatedListWithCommentsReducer<DATA, FILTER>(actionNames)
  const sagas = createFilteredPaginatedListWithCommentsSagas<DATA, FILTER>(modelName, actionNames, actions, api)
  return {
    actionNames,
    actions,
    reducer,
    sagas
  }
}