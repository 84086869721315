import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'store'

import { Box, Dropdown, Link, NavLink, FlexFill, Text, User } from '@ocs.lab/ui'

import logo_shot_icon from '@ocs.lab/ui/lib/assets/icons/logo-shot.svg'
import { IRoles } from 'models'

type SubRoute = {
  title: string | JSX.Element
  href?: string
  disabled: boolean
  requiredRoles?: IRoles[]
}

type Route = SubRoute & {
  active: boolean
  routes?: SubRoute[]
}

//TODO: i thnike this compoennt need a little refactor
const HeaderMenu: React.FC = () => {
  const location = useLocation()
  const user = useSelector(({ user }) => user.get('user'))
  const feedbackCount = useSelector(({ feedback }) => feedback.get('feedbackCount'))

  //TODO: delete isDisable keys
  //TODO: создать константы для имен роутов
  const ROUTES = React.useCallback<() => Route[]>(
    () => [
      {
        title: 'О сервисе',
        disabled: false,
        active: location.pathname === '/',
        href: '/',
      },
      {
        title: 'Лав–лента',
        disabled: false,
        active: location.pathname.includes('lav-list'),
        href: 'lav-list',
      },
      {
        title: 'Мои активности',
        disabled: false,
        active: location.pathname.includes('activities'),
        href: 'activities',
      },
      {
        title: (
          <Text style={{ display: 'flex' }}>
            Обратная связь{' '}
            <Text component="span" style={{ marginLeft: 5 }} color="primary">
              {feedbackCount || ''}
            </Text>
          </Text>
        ),
        disabled: false,
        active: location.pathname.includes('feedback'),
        href: 'feedback',
      },
      {
        title: 'Справочник',
        disabled: true,
        active: false,
        requiredRoles: ['BusinessAnalyst'],
        routes: [
          {
            title: 'Подразделения',
            href: '',
            disabled: true,
          },
          {
            title: 'Цифровые сервисы',
            href: '',
            disabled: true,
          },
          { title: 'Архив', href: '', disabled: true },
        ],
      },
      {
        title: 'Отчет по активностям',
        disabled: false,
        active: location.pathname.includes('reports'),
        requiredRoles: ['BusinessAnalyst', 'Manager'],
        href: 'reports',
      },
    ],
    [feedbackCount, location.pathname]
  )

  //TODO: FIXED types
  const filterByUserRoles = (route: SubRoute) =>
    route.requiredRoles ? route.requiredRoles?.includes(user?.role ?? 'Client') : true

  return (
    <header>
      <FlexFill margin="auto" maxWidth={1600}>
        <FlexFill alignItems="center" mx={24}>
          <Link href="/">
            <img src={logo_shot_icon} alt="" />
          </Link>
          <FlexFill flex={4} pl={24} alignItems="center">
            <FlexFill flex={1} maxWidth="60%" ml={40}>
              {ROUTES()
                .filter(filterByUserRoles)
                .map((route) => {
                  return (
                    <Box key={route.title.toString()} height={64} mr={32}>
                      <Dropdown
                        title={route.title}
                        href={route.href}
                        isUnderline={route.active}
                        disabled={route.disabled}
                        menuItems={route.routes?.filter(filterByUserRoles).map(({ title, href, disabled }) => (
                          <NavLink key={title.toString()} disabled={disabled} to={href ?? '/'}>
                            {title}
                          </NavLink>
                        ))}
                      />
                    </Box>
                  )
                })}
            </FlexFill>
          </FlexFill>
          <FlexFill flex={1} justifyContent="flex-end" alignItems="center">
            {user && (
              <User
                key={user.person.uid}
                name={user.person.firstName ?? 'Сотрудник больше не работает в компании'}
                description={`${user.lvs} лав`}
                avatarUrl={user.person.avatarUrl ?? ''}
              />
            )}
          </FlexFill>
        </FlexFill>
      </FlexFill>
    </header>
  )
}

export default HeaderMenu
