import { createAction, IActionWithPayload } from '../actions'

import { IRequiredFilters } from '../../../models'

import { BasePaginatedActions, createBasePaginatedActions } from '../paginatedListFactory/actions'
import { ITopExecutorReportItem } from 'models/champions'
import { PaginatedWithLikesActionNames } from './actionNames'

export type togglePublicCommentLikeAction = IActionWithPayload<string, {
  value: boolean
  voteId: number
}>
export type togglePublicCommentLikeSuccessAction = IActionWithPayload<string, {
  voteId: number;
  data: ITopExecutorReportItem;
}>
export type togglePublicCommentLikeFailAction = IActionWithPayload<string, string | boolean>

export type PaginatedWithLikesActions<DATA, FILTER extends IRequiredFilters> = BasePaginatedActions<DATA, FILTER> & {
  togglePublicCommentLike: (value: boolean, voteId: number) => togglePublicCommentLikeAction,
  togglePublicCommentLikeSuccess: (voteId: number, data: ITopExecutorReportItem) => togglePublicCommentLikeSuccessAction
  togglePublicCommentLikeFail: (error: string | boolean) => togglePublicCommentLikeFailAction
}

export const createPaginatedWithLikesActions = <DATA, FILTER extends IRequiredFilters>({ TOGGLE_PUBLIC_COMMENT_LIKE, TOGGLE_PUBLIC_COMMENT_LIKE_SUCCESS, TOGGLE_PUBLIC_COMMENT_LIKE_FAIL, ...rest }: PaginatedWithLikesActionNames): PaginatedWithLikesActions<DATA, FILTER> => ({
  ...createBasePaginatedActions(rest),
  togglePublicCommentLike: (value: boolean, voteId: number) =>
    createAction(TOGGLE_PUBLIC_COMMENT_LIKE, { value, voteId }),
  togglePublicCommentLikeSuccess: (voteId: number, data: ITopExecutorReportItem) => createAction(TOGGLE_PUBLIC_COMMENT_LIKE_SUCCESS, { voteId, data }),
  togglePublicCommentLikeFail: (error: string | boolean) => createAction(TOGGLE_PUBLIC_COMMENT_LIKE_FAIL, error),
})