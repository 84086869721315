import { takeLatest } from 'redux-saga/effects'

import ReportActions, { GET_REPORT_SERVICE_SUMMARY, GET_EMPLOYEE_ACTIVITY_SERVICE_SUMMARY } from './actions'
import ReportsApi from 'api/reports'
import EmployeeApi from 'api/employee'
import { fetchData } from 'store/helpers/fetch/sagas'

// function* getReportServiceSummaryFlow(action: ReturnType<typeof ReportActions.getReportServiceSummary>) {
//   try {
//     const { data } = yield call(ReportsApi.getServiceSummary, action.payload)
//     yield put(ReportActions.getServiceSummarySuccess(data))
//   } catch (err) {
//     console.error(err.response)
//     yield put(ReportActions.getServiceSummaryFail(err))
//   }
// }

// function* getEmployeeActivityServiceSummaryFlow(action: ReturnType<typeof ReportActions.getEmployeeActivityServiceSummary>) {
//   try {
//     const { data } = yield call(EmployeeApi.getEmployeeActivityServiceSummary, action.payload)
//     yield put(ReportActions.getServiceSummarySuccess(data))
//   } catch (err) {
//     console.error(err.response)
//     yield put(ReportActions.getServiceSummaryFail(err))
//   }
// }

// export default function* watchReports() {
//   yield takeLatest(GET_REPORT_SERVICE_SUMMARY, getReportServiceSummaryFlow)
//   yield takeLatest(GET_EMPLOYEE_ACTIVITY_SERVICE_SUMMARY, getEmployeeActivityServiceSummaryFlow)
// }

export default function* watchReports() {
  yield takeLatest(GET_REPORT_SERVICE_SUMMARY, fetchData(
    ReportsApi.getServiceSummary,
    ReportActions.getServiceSummarySuccess,
    ReportActions.getServiceSummaryFail
  ))
  yield takeLatest(GET_EMPLOYEE_ACTIVITY_SERVICE_SUMMARY, fetchData(
    EmployeeApi.getEmployeeActivityServiceSummary,
    ReportActions.getServiceSummarySuccess,
    ReportActions.getServiceSummaryFail
  ))
}
