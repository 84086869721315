import { Flex, FlexColumn, Link, Typography } from '@ocs.lab/ui'
import React, { memo } from 'react'
import Blockqoute from '../Blockquote'
import { styled } from '@material-ui/core/styles'
import amonin from 'assets/images/0000003084.jpeg'
import demelin from 'assets/images/0000002114.jpeg'
import rdmitriev from 'assets/images/1000005370.jpeg'
import oduranina from 'assets/images/1000004119.jpeg'

const StyledH4 = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 500
}));

const StyledH5 = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 500
}));

const About = () => {
  return (
    <Flex justifyContent="center" bgcolor="#FFF">
      <FlexColumn maxWidth="640px" py={60} px={12} gridGap={60}>
        <FlexColumn gridGap={24}>
          <StyledH4>Что говорят руководители о сервисе Feedback OCS</StyledH4>
          <Blockqoute 
            imageUrl={amonin} 
            text="«Для меня Фидбэк — это инструмент и стимул для незамедлительной коррекции и совершенствования процессов. Искренность отзыва — самая главная ценность. А излишняя «вежливость» лишь обесценивает его значение»." 
            caption="Артем Монин, заместитель директора департамента Document Imaging Solutions" 
          />
          <Blockqoute 
            imageUrl={demelin} 
            text="«Я всегда внимательно читаю отзывы и разбираюсь в причинах недовольства. При этом, никого не наказываю. Очень благодарен тем, кто честно говорит о недостатках. Это помогает быстро узнать, где мое подразделение должно прибавить»." 
            caption="Дима Емелин, директор департамента корпоративных информационных систем" 
          />
          <Blockqoute 
            imageUrl={rdmitriev} 
            text="«Для меня Фидбэк — это не просто обратная связь, а источник вдохновения и мотивации для моих ребят. Человек всегда расцветает и лучше работает, когда слышит теплые слова в свой адрес. Обоснованная критика показывает, где мы можем улучшить сервис и быть более полезными»." 
            caption="Рома Дмитриев, руководитель отдела сопровождения и технической поддержки" 
          />
          <Blockqoute 
            imageUrl={oduranina} 
            text="«Обратная связь от Фидбэка — отличный мотиватор для нашей команды. Позитивные отклики повышают продуктивность, а негативные — помогают улучшить процессы, которые не всегда заметны «изнутри»." 
            caption="Ольга Дуранина, руководитель отдела международных перевозок" 
          />
        </FlexColumn>
        <FlexColumn gridGap={16}>
          <StyledH4>Популярные вопросы:</StyledH4>
          <FlexColumn gridGap={4}>
            <StyledH5>Что будет дальше с моим отзывом?</StyledH5>
            <Typography>Руководитель исполнителя увидит твой отзыв. Если что–то пошло не так, то подумает как исправить. Если все отлично — при случае поблагодарит сотрудника за хорошую работу.</Typography>
          </FlexColumn>
          <FlexColumn gridGap={4}>
            <StyledH5>Зачем мне давать обратную связь?</StyledH5>
            <Typography>Конструктивная обратная связь поможет твоим коллегам улучшить работу.  В результате ты сможешь получать более качественный сервис от коллег.</Typography>
          </FlexColumn>
          <FlexColumn gridGap={4}>
            <StyledH5>У меня много работы, я не успеваю давать обратную связь, как быть?</StyledH5>
            <Typography>Поделиться обратной связью можно в любое удобное время в <Link color="primary" underline="none" href="/feedback" target="_blank">личном кабинете Feedback</Link></Typography>
          </FlexColumn>
        </FlexColumn>
      </FlexColumn>
    </Flex>
  )
}

export default memo(About)