const FAQS = [
  {
    title: 'Кто увидит мою обратную связь',
    body: `Сообщения с фидбеком видит только руководитель исполнителя. Сам исполнитель получает замечания по работе или благодарность непосредственно от руководителя.`,
  },
  {
    title: 'На что влияет моя обратная связь',
    body: 'Конструктивный фидбек повышает качество внутренних процессов — делает их более прозрачными. В итоге, улучшается общее благополучие компании.',
  },
  {
    title: 'Что делать, если я не пользуюсь сервисами, с которыми работает Feedback',
    body: `К сожалению, через Feedback OCS можно дать обратную связь по ограниченному количеству сервисов. Список всех сервисов есть выше на странице. Если хочешь просто поблагодарить коллегу — переходи в лав-ленту.`,
  },
  {
    title: 'Что такое лав-лента',
    body: 'Это публичная лента благодарностей и поздравлений, где в любой момент можно поблагодарить коллегу. Подойдет любой повод — помощь в решении задачи или день рождения. Уведомление о благодарности приходит на почту, чтобы ничего не пропустить.',
  },
  {
    title: 'У меня и так всё хорошо работает, зачем давать фидбек',
    body: 'Если работа со смежниками проходит без замечаний — расскажи об этом! Позитивная обратная связь крайне важна. Она поддерживает мотивацию сотрудника, а руководителю помогает объективно оценить работу подразделения.',
  },

]

export default FAQS