import { createAction, IAction, IActionWithPayload } from '../actions'

import { IPagingSettings, IFastPeriods, IBaseFilters, IRequiredFilters } from '../../../models'

import { BasePaginatedActionNames, FilteredPaginatedActionNames, FilteredPaginatedWithCommentsActionNames } from './actionNames'
import { IServiceCommentModel } from 'models/reports'
import { BaseFetchActions, createBaseFetchActions } from '../fetch/actions'

export type changePagingAction = IActionWithPayload<string, Partial<IPagingSettings>>
export type onSelectFastPeriodAction = IActionWithPayload<string, IFastPeriods | undefined>
export type refreshWithCurrentFiltersAction = IAction<string>
export type resetFiltersAction = IAction<string>

export type createManagerCommentAction = IActionWithPayload<string, { serviceId: number; commentContent: string; }>
export type createManagerCommentSuccessAction = IActionWithPayload<string, {
  serviceId: number;
  data: IServiceCommentModel[];
}>
export type createManagerCommentFailAction = IActionWithPayload<string, string | boolean>
export type removeManagerCommentAction = IActionWithPayload<string, number>
export type removeManagerCommentSuccessAction = IActionWithPayload<string, {
  serviceId: number;
  data: IServiceCommentModel[];
}>
export type removeManagerCommentFailAction = IActionWithPayload<string, string | boolean>

export type BasePaginatedActions<DATA, FILTER extends IRequiredFilters> = BaseFetchActions<DATA, FILTER> & {
  changePaging: (paging: Partial<IPagingSettings>) => changePagingAction,
}

export type FilteredPaginatedActions<DATA, FILTER extends IBaseFilters> = BasePaginatedActions<DATA, FILTER> & {
  onSelectFastPeriod: (period?: IFastPeriods) => onSelectFastPeriodAction,
  refreshWithCurrentFilters: () => refreshWithCurrentFiltersAction,
  resetFilters: () => resetFiltersAction,
}

export type FilteredPaginatedWithCommentsActions<DATA, FILTER extends IBaseFilters> = FilteredPaginatedActions<DATA, FILTER> & {
  createManagerComment: (serviceId: number, commentContent: string) => createManagerCommentAction,
  createManagerCommentSuccess: (serviceId: number, data: IServiceCommentModel[]) => createManagerCommentSuccessAction
  createManagerCommentFail: (error: string | boolean) => createManagerCommentFailAction
  removeManagerComment: (serviceId: number) => removeManagerCommentAction
  removeManagerCommentSuccess: (serviceId: number, data: IServiceCommentModel[]) => removeManagerCommentSuccessAction
  removeManagerCommentFail: (error: string | boolean) => removeManagerCommentFailAction
}

export const createBasePaginatedActions = <DATA, FILTER extends IRequiredFilters>({ CHANGE_PAGING, ...rest }: BasePaginatedActionNames): BasePaginatedActions<DATA, FILTER> => ({
  ...createBaseFetchActions(rest),
  changePaging: (paging: Partial<IPagingSettings>) => createAction(CHANGE_PAGING, paging),
})

export const createFilteredPaginatedActions = <DATA, FILTER extends IBaseFilters>({ ON_SELECT_FAST_PERIOD, REFRESH_WITH_CURRENT_FILTERS, RESET_FILTERS, ...rest }: FilteredPaginatedActionNames): FilteredPaginatedActions<DATA, FILTER> => ({
  ...createBasePaginatedActions(rest),
  onSelectFastPeriod: (period?: IFastPeriods) => createAction(ON_SELECT_FAST_PERIOD, period),
  refreshWithCurrentFilters: () => createAction(REFRESH_WITH_CURRENT_FILTERS),
  resetFilters: () => createAction(RESET_FILTERS),
})

export const createFilteredPaginatedWithCommentsActions = <DATA, FILTER extends IBaseFilters>({ CREATE_MANAGER_COMMENT, CREATE_MANAGER_COMMENT_SUCCESS, CREATE_MANAGER_COMMENT_FAIL, REMOVE_MANAGER_COMMENT, REMOVE_MANAGER_COMMENT_SUCCESS, REMOVE_MANAGER_COMMENT_FAIL, ...rest }: FilteredPaginatedWithCommentsActionNames): FilteredPaginatedWithCommentsActions<DATA, FILTER> => ({
  ...createFilteredPaginatedActions(rest),
  createManagerComment: (serviceId: number, commentContent: string) =>
    createAction(CREATE_MANAGER_COMMENT, { serviceId, commentContent }),
  createManagerCommentSuccess: (serviceId: number, data: IServiceCommentModel[]) => createAction(CREATE_MANAGER_COMMENT_SUCCESS, { serviceId, data }),
  createManagerCommentFail: (error: string | boolean) => createAction(CREATE_MANAGER_COMMENT_FAIL, error),
  removeManagerComment: (serviceId: number) => createAction(REMOVE_MANAGER_COMMENT, serviceId),
  removeManagerCommentSuccess: (serviceId: number, data: IServiceCommentModel[]) => createAction(REMOVE_MANAGER_COMMENT_SUCCESS, { serviceId, data }),
  removeManagerCommentFail: (error: string | boolean) => createAction(REMOVE_MANAGER_COMMENT_FAIL, error),
})