import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'store'
import styled from '@material-ui/core/styles/styled'

import { Box, Flex, Text } from '@ocs.lab/ui'
import { HOVER } from '@ocs.lab/ui/lib/theme/helpers'
import reduce from '@ocs.lab/ui/lib/assets/icons/reduce.svg'
import expand from '@ocs.lab/ui/lib/assets/icons/expand.svg'
import ProgressRing from '@ocs.lab/ui/lib/components/elements/ProgressRing'

import { IServiceCommentModel } from 'models/reports'

import { reports } from 'store/reports'

const SECONDS_BEFOR_DELETE_COMMENT = 5

//NOTE: FOR WIDTH=250px, if will change in futer need change this const (MAX_SYMBOLS_BEFORE_NEW_LINE)
const MAX_SYMBOLS_BEFORE_NEW_LINE = 32

const ImgExpandIcon = styled('img')({
  position: 'absolute',
  right: 6,
  top: 6,
  display: 'none',
})

const Edit = styled(Text)({
  fontSize: 12,
  display: 'none',
})

const Container = styled(Box)({
  position: 'relative',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: 260,
  backgroundColor: '#fff',
  padding: '8px 16px',
  boxSizing: 'border-box',
  borderRadius: 8,
  marginLeft: -16,
  marginBottom: 8,
  '& #comment_item': {
    marginRight: 10,
  },
  [HOVER]: {
    '& img': {
      display: 'block',
    },
    '& #comment_item': {
      display: 'block',
    },
  },
})

type Props = {
  serviceid: number
  comment: IServiceCommentModel
  handleOpenCommentModal: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

//TODO: возможноследует немного порефаактоирть и разбить на более мелкие компоненты
const Comment: React.FC<Props> = ({ serviceid, comment, handleOpenCommentModal }) => {
  const dispatch = useDispatch()

  const currentUser = useSelector(({ user }) => user.get('user')?.person)

  const [isExpand, setisExpand] = useState(false)
  const [isDeleting, setisDeleting] = useState(false)
  const [timeBeforDelete, setTimeBeforDelete] = useState<number>(SECONDS_BEFOR_DELETE_COMMENT)

  const isCurrentUserComment = comment.employeeUid === currentUser?.uid

  const isNeedExpand = comment?.content?.length && comment?.content?.length > MAX_SYMBOLS_BEFORE_NEW_LINE

  useEffect(() => {
    let interval: any
    //TODO: maybe need fix this
    // NOTE: удаляем за 0.2 секунды чтобы не показывать 0
    //TODO: (вынести в константу)
    // if (timeBeforDelete <= 0.2) {
    if (timeBeforDelete === 0) {
      clearInterval(interval)
      setisDeleting(false)
      setTimeBeforDelete(SECONDS_BEFOR_DELETE_COMMENT)
      // dispatch(ReportsActions.removeManagerComment(serviceid))
      dispatch(reports.actions.removeManagerComment(serviceid))
      return
    }
    if (isDeleting) {
      interval = setInterval(() => {
        setTimeBeforDelete((seconds) => seconds - 1)
      }, 1000)
    } else if (!isDeleting && timeBeforDelete !== 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [isDeleting, timeBeforDelete, serviceid, dispatch])

  const onToggleExpand = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    if (isNeedExpand) setisExpand(!isExpand)
  }

  const onDeletingComment = () => setisDeleting(true)

  const onStopDeletingComment = () => {
    setisDeleting(false)
    setTimeBeforDelete(SECONDS_BEFOR_DELETE_COMMENT)
  }

  const DeletingLayout = () => (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="center">
        <ProgressRing
          title={timeBeforDelete.toFixed(0)}
          radius={19.5}
          stroke={4}
          progress={timeBeforDelete * (100 / SECONDS_BEFOR_DELETE_COMMENT)}
        />
        <Text style={{ fontSize: 12 }} color="textSecondary">
          Удаление заметки
        </Text>
      </Flex>
      <Text style={{ fontSize: 12 }} color="primary" onClick={onStopDeletingComment}>
        Отмена
      </Text>
    </Flex>
  )

  const CommentLayout = () => (
    <Flex flexDirection="column">
      <Flex alignItems="baseline">
        <Text style={{ fontSize: 12, marginRight: 5 }} color="textSecondary">
          {isCurrentUserComment ? 'Вы' : comment.authorDisplayName}
        </Text>
        {isCurrentUserComment ? (
          <Flex>
            <Edit id="comment_item" color="primary" onClick={handleOpenCommentModal}>
              Редактировать
            </Edit>
            <Edit id="comment_item" color="primary" onClick={onDeletingComment}>
              Удалить
            </Edit>
          </Flex>
        ) : null}
      </Flex>
      <Text style={{ wordBreak: 'break-word', whiteSpace: isExpand ? 'pre-line' : 'nowrap' }} noWrap={!isExpand}>
        {comment.content}
      </Text>
      {isNeedExpand ? <ImgExpandIcon src={isExpand ? reduce : expand} alt="expand" /> : null}
    </Flex>
  )

  return <Container onClick={onToggleExpand}>{isDeleting ? <DeletingLayout /> : <CommentLayout />}</Container>
}

export default Comment
