import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Link, Flex, FlexColumn, Text } from '@ocs.lab/ui'
import { BEFORE, AFTER, LAST_CHILD, ODD, EVEN } from '@ocs.lab/ui/lib/theme/helpers'

import step_by_step_icon from 'assets/images/arrow-step.svg'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: 'relative',
      width: 770,
      alignItems: 'center',
      marginBottom: 20,
      [ODD]: {
        [AFTER]: {
          content: "''",
          background: `url(${step_by_step_icon})`,
          backgroundRepeat: 'no-repeat',
          height: 148,
          width: 200,
          position: 'absolute',
          right: 0,
          top: 44,
        },
      },
      [EVEN]: {
        justifyContent: 'flex-end',
        [BEFORE]: {
          content: "''",
          background: `url(${step_by_step_icon})`,
          backgroundRepeat: 'no-repeat',
          height: 148,
          width: 200,
          position: 'absolute',
          left: 0,
          top: 44,
          transform: 'scaleX(-1)',
        },
      },
      [LAST_CHILD]: {
        [AFTER]: {
          display: 'none',
        },
      },
    },
    centerBox: {
      backgroundColor: '#fff',
      borderRadius: 16,
      alignItems: 'center',
      width: 500,
      padding: 24,
    },
  }
})

type Props = {
  body: string
  photo: any
  subBody?: string
  link?: string
}

const Step: React.FC<Props> = ({ body, photo, subBody, link }) => {
  const { root, centerBox } = useStyles()
  return (
    <Flex className={root}>
      <Flex className={centerBox}>
        <img src={photo} alt="no_photo" />
        <FlexColumn ml={24}>
          <Text style={{ lineHeight: 1.6 }} variant="h6">
            {body}&nbsp;
            {link && (
              <Link variant="h6" href="/feedback">
                {link}
              </Link>
            )}
          </Text>
          <Flex mt={subBody ? 8 : 0}>
            <Text component="span" color="textSecondary">
              {subBody}
            </Text>
          </Flex>
        </FlexColumn>
      </Flex>
    </Flex>
  )
}

export default Step
