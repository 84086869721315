import React from 'react'

import Tooltip, { TooltipProps } from '@ocs.lab/ui/lib/components/elements/Tooltip'
import { FlexCenter } from '@ocs.lab/ui'
import { SmallClock } from '@ocs.lab/ui/lib/components/icons'

import { useTheme } from '@material-ui/core'

const MIN_DAYS_FOR_SET_RED_COLOR = 3

type Props = {
  daysDelayed: number
  daysDelayedWithHours: string
  placement?: TooltipProps['placement']
}

const RatingStatusDelayed: React.FC<Props> = ({ daysDelayed, daysDelayedWithHours, placement }) => {
  const { palette } = useTheme()
  return (
    <Tooltip title={daysDelayedWithHours} arrow placement={placement}>
      <FlexCenter>
        <SmallClock color={daysDelayed >= MIN_DAYS_FOR_SET_RED_COLOR ? palette.error.main : undefined} />
      </FlexCenter>
    </Tooltip>
  )
}

export default RatingStatusDelayed
