import React from 'react'

import { styled, useTheme } from '@material-ui/core/styles'

import { Box, Button, Typography } from '@ocs.lab/ui'
import { HOVER } from '@ocs.lab/ui/lib/theme/helpers'

import { IButtonRating } from '../../options'

const RoundChip = styled(Typography)(({theme})=>({
  width: 24,
  height: 24,
  lineHeight: '24px',
  textAlign: 'center',
  color: theme.palette.common.black,
  backgroundColor: "transparent",
  borderRadius: '50%',
}))

type Props = {
  buttonRating: IButtonRating
  isActive: boolean
  setRating: (value: IButtonRating) => void
}

const RateButton: React.FC<Props> = ({ buttonRating, isActive, setRating }) => {
  const { palette } = useTheme()
  const { title, rating } = buttonRating
  const onSetCurrentRate = () => setRating(buttonRating)

  const StyledButton = styled(Button)({
    backgroundColor: isActive ? palette.primary.main : palette.primary.light,
    '& .MuiTypography-root': {
      color: isActive ? palette.common.white : palette.text.primary,
    },
    [HOVER]: {
      backgroundColor: isActive ? palette.primary.main : palette.action.active
    }
  })

  return (
    <Box mr={6} mt={5}>
      <StyledButton size="small" variant="contained" color="primary" onClick={onSetCurrentRate}>
        <Typography>{title}</Typography>
        <RoundChip className="chip">{rating}</RoundChip>
      </StyledButton>
    </Box> 
  )
}

export default RateButton
