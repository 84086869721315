import React from 'react'

import { styled, useTheme } from '@material-ui/core/styles'
import { Box, FlexColumn, Text } from '@ocs.lab/ui'

import TeamMember, { WIDTH_MEMBER_CARD, HEIGHT_MEMBER_CARD } from './components/TeamMember'

import TEAM_MEMBERS from './members'

const Grid = styled(Box)({
  display: 'grid',
  gridTemplateRows: `repeat(2, ${HEIGHT_MEMBER_CARD}px)`,
  gridTemplateColumns: `repeat(3 ,${WIDTH_MEMBER_CARD}px)`,
  justifyContent: 'center',
  gridGap: 20,
  paddingTop: 60,
})

const TeamSection = () => {
  const { palette } = useTheme()
  return (
    <FlexColumn id="team" bgcolor={palette.action.hover} pt={120} pb={160} alignItems="center">
      <Text variant="h1">Команда сервиса Feedback OCS</Text>
      <Grid>
        {TEAM_MEMBERS.map((member) => (
          <TeamMember key={member.name} {...member} />
        ))}
      </Grid>
    </FlexColumn>
  )
}

export default TeamSection
