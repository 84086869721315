import Api from '.'
import {
  IServiceReport,
  IServiceReportFilter,
  IServiceReportSummary,
  IServiceCommentModel,
  IReportDepartmentStatistic,
  IServicesStatisticRecord
} from 'models/reports'

class Reports {
  /**
   * Builds services report according to passed filter
   */
  static createReport(filter: IServiceReportFilter) {
    return Api.post<IServiceReport>('reports/createservicesreport/', filter)
  }

  /**
   * Returns service report summary by service id
   */
  static getServiceSummary(serviceId: number) {
    return Api.get<IServiceReportSummary>(`reports/getservicesummary?serviceId=${serviceId}`)
  }

  /**
   * Builds services report as xlsx according to passed filter
   * @param filter {IServiceReportFilter}
   */
  static createServicesReportAsExcel(filter: IServiceReportFilter) {
    return Api.post('/reports/createservicesreportasexcel', filter, { responseType: 'blob' })
  }

  /**
   * Create or update service manager comment
   * @param filter {IServiceReportFilter}
   */
  static createManagerComment(serviceId: number, content: string) {
    return Api.post<IServiceCommentModel>('/servicecomments/addorupdate', { serviceId, content })
  }

  /**
   * Remove service manager's comment
   * @param serviceId number
   */
  static removeComment(serviceId: number) {
    return Api.post<IServiceCommentModel>('/servicecomments/remove', { serviceId })
  }

  /**
   * Returns tree of departments with their activities and totalVotes for each activity and department
   */
  static getVotesInfoByDepartments() {
    return Api.get<IReportDepartmentStatistic>("/votes/getvotesinfobydepartments")
  }

  static getVotesInfoByServiceTypes() {
    return Api.get<IServicesStatisticRecord[]>("/servicetypes/getservicetypesgroups")
  }
}

export default Reports
