import { createAction, ActionsUnion } from '../helpers/actions'

import { IUser } from 'models'

export const SET_USER = 'SET_USER'

export const ON_READY = 'ON_READY'

export const ON_AUTH_SUCCESS = 'ON_AUTH_SUCCESS'

export const SET_ERROR = 'SET_ERROR'

const UserActions = {
  setUser: (user: IUser) => createAction(SET_USER, user),

  onReady: (authenticated?: boolean) => createAction(ON_READY, authenticated),

  onAuthSuccess: () => createAction(ON_AUTH_SUCCESS),

  setError: (err: string | boolean | number) => createAction(SET_ERROR, err)
}

export type UserTypes = ActionsUnion<typeof UserActions>

export default UserActions
