import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from 'store'

import { Box, Flex, FlexCenter, FlexColumn, Paper, Typography } from '@ocs.lab/ui'

import PageNotFound from 'screens/404'
import VoteForm, { MIN_MOBILE_WIDTH, VoteFormProps } from './components/VoteForm'
import VotesList from './components/VotesList'
import { useHistory, useLocation } from 'react-router-dom'
import Vote from 'api/vote'
import { IEmployeeFeedbackItem } from 'models/employee/feedback'

import dog from 'assets/images/dog.png'
import dogGif from 'assets/images/dog.gif'
import About from './components/About'
import Thanks from './components/Thanks'
import { makeStyles, styled } from '@material-ui/core/styles'
import AlreadyVotedForm from './components/AlreadyVotedForm'
import VoteActions from 'store/vote/actions'

import Arrow from "@ocs.lab/ui/lib/assets/icons/arrow_2.svg";

type Placement = 'left' | 'center' | 'right'

const getPlacement = (index: number, currentIndex: number, lastIndex: number, isSmallScreen: boolean): Placement | null  => {
  if (index === currentIndex) {
    return 'center'
  }
  if (currentIndex === 0 && index === lastIndex) {
    return 'left'
  }
  if (currentIndex === lastIndex && index === 0) {
    return 'right'
  }
  if (isSmallScreen) {
    if (index - currentIndex === -1) {
      return 'left'
    }
    if (index - currentIndex === 1) {
      return 'right'
    }
    return null
  }
  if (index < currentIndex) {
    return 'left'
  }
  if (index > currentIndex) {
    return 'right'
  }
  return null
}

const useStyles = makeStyles(() => ({
  extraPaper: {
    width: '100%',
    top: 12, 
    left: 0,
    zIndex: -1, 
  }
}))

const StyledPaper = styled(Paper)(() => ({
  padding: 16,
  boxSizing: 'border-box',
  position: 'absolute',
}))

const StyledButton = styled(FlexCenter)(({theme}) => ({
  cursor: "pointer",
  padding: "8px 16px",
  marginLeft: 12,
  marginRight: 12,
  borderRadius: 12,
  backgroundColor: theme.palette.common.white,
}))

type WrapperProps = VoteFormProps & {
  index: number
  currentIndex: number
  lastIndex: number
  onTouchStart?: React.TouchEventHandler<HTMLDivElement>
  onTouchMove?: React.TouchEventHandler<HTMLDivElement>
}

const Wrapper = memo(({ index, currentIndex, lastIndex, onTouchStart, onTouchMove, ...restProps }: WrapperProps) => {

  const isSmallScreen = window.innerWidth < MIN_MOBILE_WIDTH

  const styles = useStyles()

  const placement = getPlacement(index, currentIndex, lastIndex, isSmallScreen)

  if (placement === null) {
    return null
  }

  if (placement === 'left' || placement === 'right') {
    return <StyledPaper
      className={styles.extraPaper} 
      style={{
        height: isSmallScreen ? 500 : undefined,
        bottom: isSmallScreen ? undefined : 0,
        transform: `translateX(${placement === 'left' ? (isSmallScreen ? '-25px' : '-50px') : (isSmallScreen ? '25px' : '50px')}) scale(0.9)`,
        transition: isSmallScreen ? undefined : "all .4s ease",
      }}
      elevation={0}
    />
  }

  return <StyledPaper
    style={{
      width: isSmallScreen ? '100%' : undefined,
      transition: isSmallScreen ? undefined : "all .4s ease",
    }}
    elevation={1}
    onTouchStart={onTouchStart}
    onTouchMove={onTouchMove}
  >
    <VoteForm {...restProps} />
  </StyledPaper>
})


type PropsFromRedux = ConnectedProps<typeof connector>

type Props = {} & PropsFromRedux

const ServiceRate = ({ votesData, getVote }: Props) => {

  const isSmallScreen = window.innerWidth < MIN_MOBILE_WIDTH

  const history = useHistory()
  const location = useLocation()
  
  const [data, setData] = useState<IEmployeeFeedbackItem[]>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [votingFinished, setVotingFinished] = useState(false)
  const [touchPosition, setTouchPosition] = useState<number | null>(null)

  const { voteId, secret } = useMemo(() => {
    const url = new URLSearchParams(location.search)
    return {
      voteId: String(url.get('voteId')),
      secret: String(url.get('secret'))
    }
  }, [location])
  
  const fetchingError = votesData[voteId]?.get('error')

  const voted = useMemo(() => {
    return votesData[voteId]?.get('voted')
  }, [votesData, voteId])

  const getVotes = useCallback(() => {
    return Vote.getVotes(voteId, secret).then((res) => {
      setLoading(false)
      setData(res.data)
    }).catch((err) => {
      setError(err)
    })
  }, [secret, voteId])

  useEffect(() => {
    getVote(parseInt(voteId), secret)
  }, [voteId, secret, getVote])

  useEffect(() => {
    getVotes()
  }, [])

  const currentIndex = useMemo(() => {
    return data?.findIndex((vote) => {
      return vote.voteInfo.voteId === parseInt(voteId)
    })
  }, [data, voteId])

  const goNext = useCallback(() => {
    if (!data || currentIndex === undefined) {
      return
    }
    const nextRecord = currentIndex === 0 ? data[1] : data[0]
    if (nextRecord && nextRecord !== data[currentIndex]) {
      history.push(`/vote?voteId=${nextRecord.voteInfo.voteId}&secret=${nextRecord.voteInfo.voteSecret}`)
      setData(data.filter((_, index) => index !== currentIndex))
      return
    }
    setVotingFinished(true)
  }, [data, history, currentIndex])

  const handleCallback = useCallback(() => {
    goNext()
  }, [goNext])

  const next = useCallback(() => {
    if (currentIndex === undefined || !data) {
      return
    }
    const vote = currentIndex < data.length - 1 ? data[currentIndex + 1] : data[0]
    if (vote) {
      history.push(`/vote?voteId=${vote.voteInfo.voteId}&secret=${vote.voteInfo.voteSecret}`)
    }
  }, [currentIndex, data, history])

  const prev = useCallback(() => {
    if (currentIndex === undefined || !data) {
      return
    }
    const vote = currentIndex > 0 ? data[currentIndex - 1] : data[data?.length - 1]
    if (vote) {
      history.push(`/vote?voteId=${vote.voteInfo.voteId}&secret=${vote.voteInfo.voteSecret}`)
    }
  }, [currentIndex, data, history])

  const handleTouchStart: React.TouchEventHandler<HTMLDivElement> = useCallback((e) => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }, [])

  const handleTouchMove: React.TouchEventHandler<HTMLDivElement> = useCallback((e) => {
    const touchDown = touchPosition

    if(touchDown === null) {
      return
    }

    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch

    if (diff > 10) {
      next()
    }

    if (diff < -10) {
      prev()
    }

    setTouchPosition(null)
  }, [next, prev, touchPosition])

  const contentEl = useMemo(() => {
    if (currentIndex === undefined) {
      return null
    }

    return (
      <Box position="relative" width={isSmallScreen ? 'calc(100vw - 24px)' : 742} height={isSmallScreen ? 700 : 375} mx={isSmallScreen ? 12 : undefined}>
        {
          votingFinished 
            ? <Thanks />
            : data?.map((vote, index) => {
              return <Wrapper 
                key={vote.voteInfo.voteId}
                index={index} 
                currentIndex={currentIndex} 
                lastIndex={data.length - 1}  
                voteId={vote.voteInfo.voteId} 
                secret={vote.voteInfo.voteSecret}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                callback={handleCallback}
              />
            })
        }
      </Box>
    )
  }, [currentIndex, isSmallScreen, votingFinished, data, handleTouchStart, handleTouchMove, handleCallback])

  const navigationEl = useMemo(() => {
    if (votingFinished || !data || data.length < 2 || currentIndex === undefined) {
      return null
    }
    if (isSmallScreen) {
      return (
        <Flex justifyContent="space-between">
          <StyledButton
            onClick={prev}
          >
            <img src={Arrow} alt="arrow_2" />
            <Typography>Предыдущий</Typography>
          </StyledButton>
          <StyledButton
            onClick={next}
          >
            <Typography>Следующий</Typography>
            <img
              style={{ transform: "rotate(180deg)" }}
              src={Arrow}
              alt="arrow_2"
            />
          </StyledButton>
        </Flex>
      )
    }
    return (
      <VotesList 
        voteId={parseInt(voteId)} 
        secret={secret} 
        data={data} 
        loading={loading} 
        error={error} 
      />
    )
  }, [currentIndex, data, error, isSmallScreen, loading, next, prev, secret, voteId, votingFinished])

  if ((!voteId || !secret || fetchingError || error) && !votesData[voteId]?.get('voteData')) {
    return <PageNotFound />
  }

  if (!votingFinished && typeof votesData[voteId]?.get('voteData')?.votedDate === 'string' && !voted) {
    return <AlreadyVotedForm />
  }

  if (currentIndex === undefined) {
    return null
  }

  if (currentIndex === -1 && !votingFinished) {
    return <PageNotFound />
  }

  return (
    <>
      <FlexColumn 
        boxSizing="border-box"
        pt={isSmallScreen ? 20 : 0}
        pb={isSmallScreen ? 20 : 160}
      >
        {(!isSmallScreen || votingFinished) && <FlexColumn alignItems="center" my={12}>
          <img src={voted ? dogGif : dog} alt='Dog' />
          <Typography variant='h3'>Обратная связь</Typography>
        </FlexColumn>}
        <Flex
          alignItems={isSmallScreen ? 'stretch' : 'center'}
          justifyContent="center"
          flexDirection={isSmallScreen ? 'column-reverse' : 'row'}
          gridGap="12px 0"
        >
          {contentEl}
          {navigationEl}  
        </Flex>
      </FlexColumn>
      
      <About />
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  votesData: state.vote.votesData
})

const mapDispatchToProps = {
  getVote: VoteActions.getVote,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(ServiceRate)
