import React from 'react'
import { Box, Flex, Typography } from '@ocs.lab/ui'

import { IReportActivityStatistic } from 'models/reports'

import { makeStyles } from '@material-ui/core/styles'
import { declensions } from 'utils'

const useStyles = makeStyles(({ palette }) => ({
  activity: {
    justifyContent: 'space-between',
    paddingTop: 12,
    paddingBottom: 12,
    borderBottom: `1px solid ${palette.background.default}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  totalVotesContainer: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}))

const Activity = ({ item: { title, totalVotes } }: { item: Omit<IReportActivityStatistic, 'uid'> }) => {
  const { activity, totalVotesContainer } = useStyles()
  return (
    <Flex className={activity}>
      <Box>
        <Typography>{title}</Typography>
      </Box>
      <Box className={totalVotesContainer}>
        <Typography color="textSecondary">
          {totalVotes} {declensions(totalVotes || 0, ['отзыв', 'отзыва', 'отзывов'])}
        </Typography>
      </Box>
    </Flex>
  )
}

export default Activity
