import React from 'react'

import { Box, FlexColumn, Text } from '@ocs.lab/ui'

import { makeStyles } from '@material-ui/core/styles'

export const WIDTH_MEMBER_CARD = 373
export const HEIGHT_MEMBER_CARD = 280

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
    borderRadius: 16,
    justifyContent: 'center',
    padding: 24,
  },
}))

type Props = {
  title: string
  body: string
  photo: any
}

const Principle: React.FC<Props> = ({ title, body, photo }) => {
  const { root } = useStyles()
  return (
    <FlexColumn className={root}>
      <img src={photo} alt="no_photo" />
      <Box pt={35} pb={16}>
        <b style={{ fontSize: 20 }}>{title}</b>
      </Box>
      <Text style={{ lineHeight: 1.8 }}>{body}</Text>
    </FlexColumn>
  )
}

export default Principle
