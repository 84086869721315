import { Record } from 'immutable'
import { IBaseFilters, ServiceReportSortableColumnsISortingSettings } from 'models'
import moment from 'moment'

// import AllFilters from 'models/base/filters'

const PERIOD_START = moment().subtract(1, 'month').startOf('day').toDate()
const PERIOD_END = moment().endOf('day').toDate()

const PAGING = { pageIndex: 1, pageSize: 10, fetchTotalCount: true }

const SORTING: ServiceReportSortableColumnsISortingSettings = { fieldName: 'Date', sortOrder: 'Desc' }


// тут необходимо перечислить все возможные варианты фильтров - иначе Immutable Record не будет их видеть
export const INITIAL_FILTER: IBaseFilters = {
  period: { from: PERIOD_START, to: PERIOD_END },
  withCommentsOnly: false,
  paging: PAGING,
  sorting: SORTING,
  clients: undefined,
  search: undefined,
  serviceTypes: undefined,
  rateStatus: undefined,
  serviceTypeModes: undefined,
  departments: undefined,
  executors: undefined,
  ratingLimits: undefined,
  employees: undefined
}

/**
 * Универсальный класс для хранения фильтров
 */
class Filters<T> extends Record(INITIAL_FILTER) {

  private _filters: T

  constructor(params: T) {
    super(params)
    this._filters = params;
  }

  public get filters(): T {
    return this._filters
  }

  public get isWithCommentsOnly(): boolean | null {
    return this.withCommentsOnly
  }

  public set isWithCommentsOnly(value: boolean | null) {
    this.set('withCommentsOnly', value)
  }

}

export default Filters