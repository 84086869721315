import { Record } from 'immutable'
import { IBaseReport, IRequiredFilters, Status } from "models";
import { IActionWithPayload } from '../actions';
import Filters from "../filters";
import { IBasePaginateState } from "../paginatedListFactory/reducer";
import { BaseFetchActionNames } from "./actionNames";
import { getDataFailAction, getDataSuccessAction } from "./actions";

// it's now only for paginated list with filters
// need to customize for simple fetch requests

/**
 * Базовая фабрика для reducer'а простого получения данных по сети.
 *
 * @param {string} GET_DATA - старт загрузки списка.
 * @param {string} GET_DATA_SUCCESS - успешная загрузка списка.
 * @param {string} GET_DATA_FAIL - ошибка загрузки списка.
 * @return {Object} reducer списка.
 */

export function reducerFetchFactory<DATA extends IBaseReport<IRequiredFilters>, FILTER extends IRequiredFilters>({ GET_DATA, GET_DATA_SUCCESS, GET_DATA_FAIL }: BaseFetchActionNames) {
  return (state: Record<IBasePaginateState<DATA, FILTER>>, action: IActionWithPayload<string, any>) => {
    switch (action.type) {
      case GET_DATA:
        return state.set('isLoading', true)
      case GET_DATA_SUCCESS:
        return getDataSuccess(state, action.payload)
      case GET_DATA_FAIL:
        return getDataFail(state, action.payload)
      default:
        return state;
    }
  };
}

function getDataSuccess<DATA extends IBaseReport<IRequiredFilters>, FILTER extends IRequiredFilters>(state: Record<IBasePaginateState<DATA, FILTER>>, payload: getDataSuccessAction<DATA, FILTER>['payload']) {
  return state
    .set('isLoading', false)
    .set('error', false)
    .set('data', payload.data)
    .set('status', payload.status as Status)
    .set('currentFilter', new Filters<FILTER>(payload.filter))
}
function getDataFail<DATA extends IBaseReport<IRequiredFilters>, FILTER extends IRequiredFilters>(state: Record<IBasePaginateState<DATA, FILTER>>, payload: getDataFailAction['payload']) {
  return state.set('isLoading', false).set('error', payload)
}