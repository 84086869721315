import React from 'react'
import moment from 'moment'
import { useSelector } from 'store'

import { Box, FlexFill, FlexColumn, Text, Link, Tooltip, Sidebar } from '@ocs.lab/ui'
import rocket_chat from '@ocs.lab/ui/lib/assets/icons/rocket_chat.svg'
import ArrowRight from '@ocs.lab/ui/lib/assets/icons/arrow_right.svg'
import { InfoBlock } from '../ServiceSummary'

import { styled } from '@material-ui/core'
import { IconWrapper } from '../ServiceSummary/components/ClientItem'

const WIDTH_DRAWER = 510
const DRAWER_PADDING = 48

type Props = {
  open?: boolean
  onClose?: () => void
}

const LargeAvatar = styled(Box)({
  width: '100%',
  height: 512,
  borderRadius: 16,
  marginBottom: 24,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
})

const EmployeeInfo: React.FC<Props> = ({ open, onClose }) => {
  const isLoading = useSelector(({ employeeInfo }) => employeeInfo.get('isLoading'))
  const employeeInfo = useSelector(({ employeeInfo }) => employeeInfo.get('data'))
  const isError = !!useSelector(({ employeeInfo }) => employeeInfo.get('error'))

  return (
    <Sidebar
      open={open}
      onClose={onClose}
      isLoading={isLoading}
      isError={isError}
      width={WIDTH_DRAWER}
      padding={DRAWER_PADDING}
    >
      {employeeInfo && (
        <FlexFill flexDirection="column">
          <LargeAvatar
            style={{
              backgroundImage: `url('${employeeInfo.avatarUrl}')`,
            }}
          />

          <FlexFill alignItems="center" justifyContent="space-between">
            <Text variant="h2">{`${employeeInfo?.lastName} ${employeeInfo?.firstName} ${employeeInfo?.middleName}`}</Text>
            <Tooltip title="Написать в Rocket.chat" placement="left" arrow={true}>
              <IconWrapper height={32}>
                <Link href={employeeInfo?.rocketLink} target="_blank">
                  <img src={rocket_chat} alt="rocket_chat" height="100%" />
                </Link>
              </IconWrapper>
            </Tooltip>
          </FlexFill>

          {employeeInfo?.positions.map((position) => (
            <Box key={position.position.managerUid} marginTop="12px">
              <Text>{position.position.title}</Text>
              <Text component="span" variant="subtitle2" color="textSecondary" style={{ lineHeight: '16px' }}>
                {position.hierarchyDepartments.map((item, index) => (
                  <React.Fragment key={index}>
                    {' ' + item + ' '}
                    {position.hierarchyDepartments.length - 1 > index && <img src={ArrowRight} alt="arrow" />}
                  </React.Fragment>
                ))}
              </Text>
            </Box>
          ))}

          <FlexColumn mt={12}>
            <InfoBlock
              className="topline"
              title="Дата рождения"
              value={employeeInfo.birthDate ? moment(employeeInfo.birthDate).format('D MMMM') : null}
            />
            <InfoBlock
              title="Электронная почта"
              value={
                employeeInfo.email ? <Link href={`mailto:${employeeInfo.email}`}>{employeeInfo.email}</Link> : null
              }
            />
            <InfoBlock title="Мобильный телефон" value={employeeInfo.mobilePhone} />
          </FlexColumn>
        </FlexFill>
      )}
    </Sidebar>
  )
}

export default EmployeeInfo
