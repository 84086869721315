import way_1 from 'assets/images/way_1.svg'
import way_2 from 'assets/images/way_2.svg'
import way_4 from 'assets/images/way_4.svg'

const VALUES = [
  {
    title: 'Через письмо на почте',
    body: 'Как только твой коллега\nзакроет заявку',
    photo: way_1,
  },
  {
    title: 'В личном кабинете',
    body: 'В любое время в разделе \n',
    highlightBody: 'обратная связь',
    href: '/feedback',
    photo: way_2,
  },
  {
    title: 'В лав-ленте',
    body: 'В любой момент можно выразить благодарность в ',
    highlightBody: 'лав-ленте',
    href: '/lav-list',
    photo: way_4,
  },
]

export default VALUES