import { BaseFetchActionNames, createBaseFetchActionNames } from "../fetch/actionNames"

export type BasePaginatedActionNames = BaseFetchActionNames & {
  CHANGE_PAGING: string
}
export type FilteredPaginatedActionNames = BasePaginatedActionNames & {
  ON_SELECT_FAST_PERIOD: string,
  REFRESH_WITH_CURRENT_FILTERS: string,
  RESET_FILTERS: string
}

export type FilteredPaginatedWithCommentsActionNames = FilteredPaginatedActionNames & {
  CREATE_MANAGER_COMMENT: string,
  CREATE_MANAGER_COMMENT_SUCCESS: string,
  CREATE_MANAGER_COMMENT_FAIL: string,
  REMOVE_MANAGER_COMMENT: string
  REMOVE_MANAGER_COMMENT_SUCCESS: string
  REMOVE_MANAGER_COMMENT_FAIL: string
}

// TODO change actionName to prefix

export const createBasePaginatedActionNames = (modelName: string): BasePaginatedActionNames => {
  return {
    ...createBaseFetchActionNames(modelName),
    CHANGE_PAGING: `CHANGE_PAGING_${modelName}`
  }
}

export const createFilteredPaginatedActionNames = (modelName: string): FilteredPaginatedActionNames => {
  return {
    ...createBasePaginatedActionNames(modelName),
    ON_SELECT_FAST_PERIOD: `ON_SELECT_FAST_PERIOD_${modelName}`,
    REFRESH_WITH_CURRENT_FILTERS: `REFRESH_WITH_CURRENT_FILTERS_${modelName}`,
    RESET_FILTERS: `RESET_${modelName}_FILTERS`
  }
}

export const createFilteredPaginatedWithCommentsActionNames = (modelName: string): FilteredPaginatedWithCommentsActionNames => {
  return {
    ...createFilteredPaginatedActionNames(modelName),
    CREATE_MANAGER_COMMENT: `CREATE_COMMENT_${modelName}`,
    CREATE_MANAGER_COMMENT_SUCCESS: `CREATE_COMMENT_${modelName}_SUCCESS`,
    CREATE_MANAGER_COMMENT_FAIL: `CREATE_COMMENT_${modelName}_FAIL`,
    REMOVE_MANAGER_COMMENT: `REMOVE_COMMENT_${modelName}`,
    REMOVE_MANAGER_COMMENT_SUCCESS: `REMOVE_COMMENT_${modelName}_SUCCESS`,
    REMOVE_MANAGER_COMMENT_FAIL: `REMOVE_COMMENT_${modelName}_FAIL`
  }
}

