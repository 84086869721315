import { call, put, takeLatest } from 'redux-saga/effects'

import ReportActions, { GET_REPORT_EMPLOYEE_INFO } from './actions'
import EmployeeApi from 'api/employee'

function* getEmployeeInfoFlow(action: ReturnType<typeof ReportActions.getData>) {
  try {
    const { data } = yield call(EmployeeApi.getEmployee, action.payload,)
    yield put(ReportActions.getDataSuccess(data))
  } catch (err) {
    console.error(err.response)
    yield put(ReportActions.getDataFail(err))
  }
}

export default function* watchEmployeeInfo() {
  yield takeLatest(GET_REPORT_EMPLOYEE_INFO, getEmployeeInfoFlow)
}
