import { Flex, Typography } from '@ocs.lab/ui'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette }) => ({
  box: {
    alignItems: 'center',
    gap: 4,
    padding: '8px 24px',
    cursor: 'pointer',
    borderRadius: 12,
    backgroundColor: ({ active }: any) => (active ? palette.primary.main : palette.common.white),
  },
  text: {
    maxWidth: 690,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: ({ active }: any) => (active ? palette.common.white : palette.text.primary),
  },
  textMedium: {
    fontWeight: 500,
  },
}))

type Props = {
  title: string
  subtitle: string
  active: boolean
  onClick: () => void
}

const Tab = ({ title, subtitle, active, onClick }: Props) => {
  const { box, text, textMedium } = useStyles({ active })
  return (
    <Flex className={box} onClick={onClick}>
      <Typography className={text}>{title}</Typography>
      <Typography className={text + ' ' + textMedium}>{subtitle}</Typography>
    </Flex>
  )
}

export default Tab
