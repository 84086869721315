import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from 'store'

import { Box, ScrollToTop } from '@ocs.lab/ui'

import {
  IEmployeeActivityReportFilter,
  IEmployeeActivityReport,
  IEmployeeActivityItem,
} from 'models/employee/activities'
import { Statuses } from 'models'
import ServiceSummaryActions from 'store/reports/serviceSummary/actions'

import ServiceSummary from 'screens/Reports/components/ServiceSummary'
import ReportLineItem from 'screens/Reports/components/ReportLineItem'
import TableStatus from '../../components/TableComponents/TableStatus'
import Table, { ITableCaption } from 'components/TableComponents/Table'
import FiltersPanel from 'components/TableComponents/FiltersPanel'
import { activities } from 'store/employee/activities'
import EmployeeInfo from 'screens/Reports/components/EmployeeInfo'
import EmployeeInfoActions from 'store/reports/employeeInfo/actions'

const TABLE_CAPTIONS: ITableCaption[] = [
  { key: 'serviceTypeTitle', title: 'Активность' },
  { key: 'service', title: 'Название' },
  { key: 'provisionDate', title: 'Дата', sortKey: 'Date' },
  { key: 'clients', title: 'Заказчик' },
]

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = {} & PropsFromRedux

type State = {
  isDrawerOpen: boolean
  isEmployeeInfoOpen: boolean
}

//TODO: стоит сделать один большой универсальный класс для reports, activities, champions and etc. for рендеринг таблиц
//TODO: повторение логики и UI reports надо сделать унивесрслаьные компоненты
class MyActivities extends React.PureComponent<Props, State> {
  state = {
    isDrawerOpen: false,
    isEmployeeInfoOpen: false,
  }

  componentDidMount() {
    this.props.getEmployeeActivities({})
  }

  handleDrawerServiceSummaryOpen = (serviceId: number) => {
    this.setState({ isDrawerOpen: true })
    this.props.getEmployeeActivityServiceSummary(serviceId)
  }
  handleDrawerServiceSummaryClose = () => {
    this.setState({ isDrawerOpen: false })
  }

  handleDrawerEmployeeInfoOpen = (uuid: string) => {
    this.setState({ isEmployeeInfoOpen: true })
    this.props.getEmployeeInfo(uuid)
  }
  handleDrawerEmployeeInfoClose = () => {
    this.setState({ isEmployeeInfoOpen: false })
  }

  RenderLayout = () => {
    const { state, getEmployeeActivities, changePaging } = this.props
    const isLoading = state.get('isLoading')
    const activities = state.get('data')
    const status = state.get('status')

    if (isLoading && !activities) return <TableStatus showStatus="InitLoading" />
    if (status === Statuses.NoContent) return <TableStatus showStatus="NoActivities" />
    if (status === Statuses.Success && !activities) return <TableStatus showStatus="NoData" />
    if (!activities?.data?.totalCount) return <TableStatus showStatus="NothingFound" />
    return (
      <Table<IEmployeeActivityReport, IEmployeeActivityItem>
        modelName="activities"
        state={state}
        captions={TABLE_CAPTIONS}
        withPageSize={true}
        getData={getEmployeeActivities}
        changePage={changePaging}
        renderItem={(item) => (
          <ReportLineItem
            key={item?.service?.serviceId}
            data={item}
            onClick={this.handleDrawerServiceSummaryOpen!}
            onEmployeeInfoOpen={this.handleDrawerEmployeeInfoOpen!}
          />
        )}
      />
    )
  }

  render() {
    const { state, getEmployeeActivities, selectFastPeriod, resetFilters } = this.props
    const hiddenFilters = state.get('data')?.hideFilters
    const status = state.get('status')
    return (
      <Box minHeight="90vh">
        <FiltersPanel<IEmployeeActivityReport, IEmployeeActivityReportFilter>
          title="Мои активности"
          state={state}
          getData={getEmployeeActivities}
          selectFastPeriod={selectFastPeriod}
          resetFilters={resetFilters}
          disabled={status === Statuses.NoContent}
          hiddenFilters={hiddenFilters}
        />
        <this.RenderLayout />

        <ServiceSummary open={this.state.isDrawerOpen} onClose={this.handleDrawerServiceSummaryClose} />
        <EmployeeInfo open={this.state.isEmployeeInfoOpen} onClose={this.handleDrawerEmployeeInfoClose} />

        <ScrollToTop />
      </Box>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  state: state.activities,
})

const mapDispatchToProps = {
  getEmployeeActivities: activities.actions.getData,
  changePaging: activities.actions.changePaging,
  selectFastPeriod: activities.actions.onSelectFastPeriod,
  getEmployeeActivityServiceSummary: ServiceSummaryActions.getEmployeeActivityServiceSummary,
  resetFilters: activities.actions.resetFilters,
  getEmployeeInfo: EmployeeInfoActions.getData,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(MyActivities)
