import React, { memo } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from 'store'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  TextField,
  Text,
  Link,
  Flex,
  FlexFill,
  FlexCenter,
  FlexColumn,
  Spinner,
} from '@ocs.lab/ui'
import Modal from '@material-ui/core/Modal'

import VoteActions from 'store/vote/actions'
import { IServiceDescription } from 'models/reports'
import { IButtonRating, PLACEHOLDERS } from '../../options'
import { HOVER } from '@ocs.lab/ui/lib/theme/helpers'

import RateButtons from '../RateButtons'

import SendButton from './SendButton'


const useStyles = makeStyles(({ palette }) => ({
  root: {
    [HOVER]: {
      color: palette.text.secondary,
      '& img': {
        transitionDuration: '0.3s',
        transform: 'translateX(10px)',
      },
    },
  },
}))

const FORM_WIDTH = 694

export const MIN_MOBILE_WIDTH = 775

const Section: React.FC<Partial<IServiceDescription>> = ({ type, title, value, extraValue }) => {
  const { root } = useStyles()
  return (
    <Flex mb={16}>
      <Text>
        <Text component="span" display="inline" style={{ overflow: 'visible' }} color="textSecondary" noWrap>
          {title}:&nbsp;
        </Text>
        {type === 'LinkValue' ? (
          <Link
            className={root}
            color="textPrimary"
            underline="none"
            target="_blank"
            href={value}
            withArrow={true}
            style={{ wordBreak: 'break-word' }}
          >
            {extraValue}
          </Link>
        ) : (
          <Text component="span" display="inline" style={{ wordBreak: 'break-word' }}>
            {value}
          </Text>
        )}
      </Text>
    </Flex>
  )
}
//TODO:REFACTOR
type IERRORS_INIT_STATE = {
  noComment: boolean
  noRating: boolean
}
//TODO:REFACTOR
const ERRORS_INIT_STATE: IERRORS_INIT_STATE = {
  noComment: false,
  noRating: false,
}

type PropsFromRedux = ConnectedProps<typeof connector>

export type VoteFormProps = {
  voteId: number
  secret: string
  noElevation?: boolean
  callback?: () => any
  isLocal?: boolean
}

type Props = VoteFormProps & PropsFromRedux

type State = {
  rating?: IButtonRating
  comment: string
  isPublicComment: boolean
  errors: IERRORS_INIT_STATE
  isCommentModalOpen: boolean
  modalText: string
  isSubmitting: boolean
}

//TODO: отрефакторить
class VoteForm extends React.PureComponent<Props, State> {
  state: State = {
    rating: undefined,
    comment: '',
    isPublicComment: false,
    errors: ERRORS_INIT_STATE,
    isCommentModalOpen: false,
    modalText: '',
    isSubmitting: false,
  }

  constructor (props: Props) {
    super(props)
    this.onSend = this.onSend.bind(this)
  }

  componentDidMount() {
    if (this.props.isLocal) {
      this.props.getVote(this.props.voteId, this.props.secret)
    }
  }

  componentDidUpdate(prevProps: Props) {

    const voted = this.props.votesData[this.props.voteId]?.get('voted')
    const prevVoted = prevProps.votesData[prevProps.voteId]?.get('voted')
    if (voted && voted !== prevVoted) {
      this.setState({
        isSubmitting: false
      })
      setTimeout(() => {
        this.props.callback?.()
      }, 500)
    }

    if (!this.props.isLocal) {
      return
    }
    if (prevProps.voteId !== this.props.voteId) {
      this.props.getVote(this.props.voteId, this.props.secret)
    }
  }

  onSend = () => {
    const { votesData, setVote, voteId, secret } = this.props
    const voteData = votesData[this.props.voteId]?.get('voteData')
    const { rating, comment, isSubmitting } = this.state

    const isRequiredComment = voteData?.pollCommentMode === 'Required'
    const isOptionalComment = voteData?.pollCommentMode === 'Optional'

    try {
      const currentErrors = { ...ERRORS_INIT_STATE }
      if (isSubmitting) return
      if (!voteData) return
      if (!rating) currentErrors.noRating = true

      if (isRequiredComment && !comment.trim().length) currentErrors.noComment = true

      if (isOptionalComment)
        if (voteData.commentIsRequiredRatings.includes(Number(rating?.rating)) && !comment.trim().length)
          currentErrors.noComment = true
      if (Object.values(currentErrors).some((err) => err === true)) {
        return this.setState({ errors: currentErrors })
      }
      if (voteId && secret && rating) {
        this.setState({
          isSubmitting: true,
        })
        setVote(voteId, secret, {
          rating: rating.rating,
          comment: comment.trim(),
          isPublicComment: false,
        })
      }

    } catch (error) {
      //TODO:REFACTOR
      console.error(error)
    }
  }

  setRating = (rating: IButtonRating) => {
    const { votesData } = this.props
    const voteData = votesData[this.props.voteId]?.get('voteData')
    if (!voteData?.commentIsRequiredRatings.includes(Number(rating.rating))) {
      this.setState({ errors: ERRORS_INIT_STATE })
    }
    this.setState({ rating })
  }

  onOpenModal = (modalText: string) =>
    window.innerWidth < MIN_MOBILE_WIDTH && this.setState({ isCommentModalOpen: true, modalText })
  onCloseModal = () => this.setState({ isCommentModalOpen: false })

  render() {
    const { votesData } = this.props
    const voteData = votesData[this.props.voteId]?.get('voteData')
    const isLoading = votesData[this.props.voteId]?.get('isLoading')
    const error = votesData[this.props.voteId]?.get('error')
    const voted = this.props.votesData[this.props.voteId]?.get('voted')
    const { rating, comment, errors, isCommentModalOpen, modalText, isSubmitting } = this.state

    const isSmallScreen = window.innerWidth < MIN_MOBILE_WIDTH

    if (isLoading && !isSubmitting) {
      return (
        <FlexCenter width={isSmallScreen ? undefined : FORM_WIDTH} height={isSmallScreen ? 500 : 375} p={8}>
          <Spinner />
        </FlexCenter>
      )
    }

    const isDisabledComment = voteData?.pollCommentMode === 'Disabled'
    const isRequiredComment = voteData?.pollCommentMode === 'Required'

    // TODO можно доработать - disable button when comment is required and no comment
    const isReadyToSend = Boolean(rating)

    const names = voteData?.managers.map((p, index) => {
      return <React.Fragment key={p.uid}>
        <Text variant="subtitle2" color="primary" component="span">{p.firstName + ' ' + p.lastName}</Text>
        {index < voteData?.managers.length - 1 && ', '}
      </React.Fragment>
    }) ?? []

    const getText = () => {
      if (names.length > 0) {
        if (!voteData?.noExecutorsInManagers) {
          return <Text variant="subtitle2">Отзыв увидит только {names}.</Text>
        }
        return <Text variant="subtitle2">Отзыв увидит только {names}. Исполнитель — не увидит.</Text>
      }
      return <Text variant="subtitle2">Отзыв увидит только руководитель исполнителя. Исполнитель — не увидит.</Text>
    }

    const textEl = getText()

    return (
      <FlexColumn width={isSmallScreen ? undefined : FORM_WIDTH} p={8}>
        <Box>
          <FlexColumn>
            <Flex mb={16} justifyContent="space-between">
              <Text display="inline" style={{fontWeight: 500}}>
                {voteData?.serviceTypeTitle}
              </Text>
              <Text color="textSecondary" display="inline">
                {moment(voteData?.serviceProvisionDate).format('DD.MM.YYYY')}
              </Text>
            </Flex>
            {voteData?.descriptions.map(({ type, title, value, extraValue }) => (
              <Section key={value} type={type} title={title} value={value} extraValue={extraValue} />
            ))}
          </FlexColumn>
          <RateButtons currentRating={rating} setRating={this.setRating} />
          <FlexColumn mt={24}>
            {isDisabledComment || (
              <Box position="relative">
                <TextField
                  value={comment}
                  label="Комментарий"
                  variant="filled"
                  placeholder={PLACEHOLDERS[rating?.rating || 0]}
                  error={errors.noComment && !comment.trim().length}
                  onChange={(e) => this.setState({ comment: e.currentTarget.value })}
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{ shrink: true }}
                  multiline
                  rows={isSmallScreen ? 4 : 2}
                  fullWidth
                />
                <Box position="absolute" top={10} right={16}>
                  {/* TODO: FIX */}
                  {isRequiredComment ||
                  (voteData?.commentIsRequiredRatings &&
                    voteData?.commentIsRequiredRatings.includes(Number(rating?.rating))) ? (
                    <Text
                      color={errors.noComment && !comment.trim().length ? 'error' : 'textSecondary'}
                      style={{ fontSize: 12 }}
                    >
                      обязательно
                    </Text>
                  ) : null}
                </Box>
              </Box>
            )}
            {/* FIXME: fix inline styles */}
            <FlexFill 
              justifyContent="space-between" 
              flexWrap={isSmallScreen ? "wrap" : undefined}
              gridGap="0 16px"
              mt={16}
            >
              <Box>
                {textEl}
              </Box>
              <Box flexGrow={1} textAlign="right">
                <SendButton 
                  isSmallScreen={isSmallScreen}
                  isReadyToSend={isReadyToSend}
                  isLoading={isSubmitting}
                  isSuccess={voted}
                  isError={!!error}
                  onSend={this.onSend}
                />
              </Box>
            </FlexFill>
          </FlexColumn>
        </Box>
        <Modal
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          onClose={this.onCloseModal}
          open={isCommentModalOpen}
        >
          <Box width="70%" bgcolor="#fff" p={16} borderRadius={16}>
            <Text>{modalText}</Text>
          </Box>
        </Modal>
      </FlexColumn>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  votesData: state.vote.votesData
})

const mapDispatchToProps = {
  getVote: VoteActions.getVote,
  setVote: VoteActions.setVote,
  reset: VoteActions.reset,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(memo(VoteForm))
