import React from 'react'
import moment from 'moment'
import { has } from 'lodash'
import { useSelector } from 'store'

import { styled } from '@material-ui/core/styles'

import { Box, Button, Flex, FlexFill, FlexColumn, Text, Sidebar } from '@ocs.lab/ui'
import arrow_3 from '@ocs.lab/ui/lib/assets/icons/arrow_3.svg'

import { IServiceReportSummary } from 'models/reports'

import User from './components/User'
import ClientItem from './components/ClientItem'

const WIDTH_DRAWER = 510
const DRAWER_PADDING = 48

const INFO_BLOCK_MAX_WIDTH = WIDTH_DRAWER - DRAWER_PADDING * 2
const INFO_BLOCK_MARGIN = 12

const InfoBlockContainer = styled(FlexFill)(({ theme: { palette } }) => ({
  maxWidth: INFO_BLOCK_MAX_WIDTH,
  marginBottom: INFO_BLOCK_MARGIN,
  justifyContent: 'space-between',
  paddingBottom: 12,
  borderBottom: `1px solid ${palette.divider}`,
  '&:last-child': {
    borderBottom: 'none',
  },
  '&.topline': {
    paddingTop: 12,
    borderTop: `1px solid ${palette.divider}`,
  },
}))

type IInfoBlockProps = {
  title?: string
  value: string | JSX.Element | null
  className?: string
}

export const InfoBlock: React.FC<IInfoBlockProps> = ({ className, title, value }) => {
  return (
    <InfoBlockContainer className={className}>
      <Text color="textSecondary">{title}</Text>
      <Flex minWidth={30} />
      {value === null ? (
        <>&mdash;</>
      ) : typeof value === 'string' ? (
        <Flex
          style={{ justifyContent: 'flex-end', alignItems: 'center', fontSize: 14, textAlign: 'right' }}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      ) : (
        value
      )}
    </InfoBlockContainer>
  )
}

type Props = {
  open?: boolean
  onClose: () => void
}

const ServiceSummary: React.FC<Props> = ({ open, onClose }) => {
  const isLoading = useSelector(({ serviceSummary }) => serviceSummary.get('isLoading'))

  //TODO: могут приходить два типа, надо зарефакторить (а конкретнее убрать использование IServiceReportSummary, так как в будущем может появиться и третий тип)
  const serviceSummary: IServiceReportSummary = useSelector(({ serviceSummary }) => serviceSummary.get('data'))!
  const isError = !!useSelector(({ serviceSummary }) => serviceSummary.get('error'))

  return (
    <Sidebar
      open={open}
      onClose={onClose}
      isLoading={isLoading}
      isError={isError}
      width={WIDTH_DRAWER}
      padding={DRAWER_PADDING}
    >
      {serviceSummary && (
        <FlexFill flexDirection="column">
          {serviceSummary?.title?.map((title) => {
            return (
              <Box key={title.title}>
                <Box my={16}>
                  <Text variant="h3" style={{ wordBreak: 'break-word' }}>
                    {title.extraValue}
                  </Text>
                </Box>
                {title.type === 'LinkValue' && (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => window.open(title.value)}
                    endIcon={<img src={arrow_3} alt="arrow_3" />}
                  >
                    {title.title}
                  </Button>
                )}
              </Box>
            )
          })}
          <FlexColumn mt={24}>
            <InfoBlock title="Дата" value={moment(serviceSummary?.provisionDate).format('D.MM.YYYY')} />
            {serviceSummary?.headerDescriptions?.map((desc) => (
              <InfoBlock key={desc.title} title={desc.title} value={desc.value} />
            ))}
          </FlexColumn>

          {/* FIXME: не особо хорошо */}
          {has(serviceSummary, 'executors') ? (
            <FlexColumn mt={12}>
              <Text variant="h6">Исполнители</Text>
              {serviceSummary?.executors?.map(({ person }) => (
                <Flex key={person.uid} mt={16}>
                  <User key={person.uid} user={person} />
                </Flex>
              ))}
            </FlexColumn>
          ) : null}
          <FlexColumn mt={24}>
            <Text variant="h6">Заказчики</Text>
            {serviceSummary?.clients?.map((voteSummary) => (
              <ClientItem key={voteSummary.client.person.uid} voteSummary={voteSummary} />
            ))}
            <FlexColumn mt={36}>
              {serviceSummary?.footerDescriptions?.map((desc) => (
                <InfoBlock key={desc.title} title={desc.title} value={desc.value} />
              ))}
            </FlexColumn>
            {serviceSummary?.managerComment && (
              <Box mt={24} maxWidth={420}>
                <Text color="textSecondary">Комментарий руководителя</Text>
                <Text>{serviceSummary?.managerComment}</Text>
              </Box>
            )}
          </FlexColumn>
        </FlexFill>
      )}
    </Sidebar>
  )
}

export default ServiceSummary
