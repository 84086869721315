import React, { useEffect } from 'react'

import { VariableSizeList, ListChildComponentProps } from 'react-window'

const LISTBOX_PADDING = 0 // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  })
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

type ListboxProps = {
  index: number
  children?: React.ReactNode
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, ListboxProps>(function ListboxComponent(
  props: ListboxProps,
  ref
) {
  const { children, index, ...other } = props
  const itemData = React.Children.toArray(children)
  const itemCount = itemData.length
  const itemSize = 60

  const gridRef = useResetCache(itemCount)

  useEffect(() => {
    gridRef.current?.scrollToItem(index, 'smart')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getChildSize = (child: React.ReactNode) => {
    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 6) {
      return 6 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

export default ListboxComponent
