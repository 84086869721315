import React from 'react'

import Popper, { PopperProps } from '@material-ui/core/Popper/Popper'

const POPPER_WIDTH = 450 // px

const CustomPopper = function (props: PopperProps) {
  return <Popper {...props} style={{ width: POPPER_WIDTH }} placement="bottom-start" />
}

export default CustomPopper
