import { createPaginatedWithLikesActions } from "./actions"
import { createPaginatedListWithLikesSagas, SagasApiActions } from "./sagas"

import { IBaseReport, IRequiredFilters } from "models"
import { ModelName } from "../paginatedListFactory"
import { createPaginatedWithLikesActionNames } from "./actionNames"
import { createPaginatedListWithLikesReducer } from "./reducer"



export const createPaginatedListWithLikes = <DATA extends IBaseReport<IRequiredFilters>, FILTER extends IRequiredFilters>(
  modelName: ModelName,
  api: SagasApiActions<DATA, FILTER>
) => {
  const actionNames = createPaginatedWithLikesActionNames(modelName.toUpperCase())
  const actions = createPaginatedWithLikesActions<DATA, FILTER>(actionNames)
  const reducer = createPaginatedListWithLikesReducer<DATA, FILTER>(actionNames)
  const sagas = createPaginatedListWithLikesSagas<DATA, FILTER>(modelName, actionNames, actions, api)
  return {
    actionNames,
    actions,
    reducer,
    sagas
  }
}