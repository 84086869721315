import React from 'react'

import { Text } from '@ocs.lab/ui'
import { Box, Paper } from '@ocs.lab/ui'

import { FlexCenter, FlexColumn } from '@ocs.lab/ui'

import no_access from 'assets/images/no_access.svg'

const NoAccess = () => {
  return (
    <FlexCenter mt={42}>
      <Paper>
        <FlexColumn maxWidth={330} padding={32} alignItems="center">
          <img src={no_access} alt="no_access" />
          <Box mt={30} mb={16}>
            <Text variant="h3" align="center">
              Кажется, такой страницы нет
            </Text>
          </Box>
          <Text align="center">Возможно, она была перемещена, или вы просто неверно указали адрес страницы</Text>
        </FlexColumn>
      </Paper>
    </FlexCenter>
  )
}

export default NoAccess
