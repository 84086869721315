import React from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Button, Box, FlexColumn, Text } from '@ocs.lab/ui'

import landing_header_dog from 'assets/images/landing_header_dog.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    padding: '24px 28px',
    borderRadius: 16,
    alignItems: 'center',
  },
  title: {
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: 1.5,
    color: theme.palette.primary.contrastText,
    fontSize: 16,
  },
}))

const ActionFrame: React.FC = () => {
  const history = useHistory()
  const { root, title } = useStyles()

  const handleClick = () => history.push('/lav-list')

  return (
    <FlexColumn className={root}>
      <img width={150} height={150} src={landing_header_dog} alt="no_photo" />
      <Box pb={16}>
        <Text className={title}>Поблагодари коллегу за работу!</Text>
      </Box>
      <Button fullWidth variant="contained" color="primary" onClick={handleClick}>
        Перейти в лав-ленту
      </Button>
    </FlexColumn>
  )
}

export default ActionFrame
