import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Record } from 'immutable'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import Autocomplete from 'components/Autocomplete'
import { Period } from '@ocs.lab/ui/lib/components/elements/Datepicker'
import {
  // Autocomplete,
  Datepicker,
  Box,
  Button,
  IconButton,
  Tooltip,
  Tabs,
  TextField,
  Flex,
  FlexFill,
  FlexColumn,
  Checkbox,
  Text,
} from '@ocs.lab/ui'

// import { IServiceReportFilter } from 'models/reports'
import { IBaseFilters, IBaseReport, IFastPeriods, IListFilterItem } from 'models'
import { IBasePaginateState } from 'store/helpers/paginatedListFactory/reducer'
import ReportsApi from 'api/reports'

import { getPeriod, downloadFile } from 'utils'

import RateStatusButtonMenu from 'screens/Reports/components/RateStatusButtonMenu'

import { ClearIcon } from '@ocs.lab/ui/lib/components/icons'
import { HOVER } from '@ocs.lab/ui/lib/theme/helpers'

import load_excel from '@ocs.lab/ui/lib/assets/icons/load_excel.svg'
import search from '@ocs.lab/ui/lib/assets/icons/search.svg'
import { FilteredPaginatedActions } from 'store/helpers/paginatedListFactory/actions'
import Filters from 'store/helpers/filters'
import { AxiosPromise } from 'axios'
import AccordionFilter from 'screens/Reports/components/AccordionFilter'

const FILTER_NAMES: { [key: string]: string } = {
  serviceTypes: 'Активность',
  departments: 'Подразделение',
  executors: 'Исполнитель',
  clients: 'Заказчик',
}
const POSSIBLE_FILTERS = ['serviceTypes', 'departments', 'executors', 'clients']

const FastPeriods: IFastPeriods[] = ['Месяц', 'Квартал', 'Год']

const useStyles = makeStyles((theme) => ({
  loadExcelButton: {
    border: `1px solid ${theme.palette.divider}`,
    [HOVER]: {
      borderColor: theme.palette.text.secondary,
    },
  },
}))

type ExcelButtonProps<FILTER extends IBaseFilters> = {
  totalCount?: number
  currentFilter: Filters<FILTER>
  createReport(filter: FILTER): AxiosPromise<any>
}

const ExcelButton = <FILTER extends IBaseFilters>({
  totalCount,
  currentFilter,
  createReport,
}: ExcelButtonProps<FILTER>) => {
  const styles = useStyles()
  const getReportAsExcel = async () => {
    if (totalCount) {
      // TODO refactor - могут быть отчеты и по другим таблицам?
      // const response = await ReportsApi.createServicesReportAsExcel({
      //   ...(currentFilter.toJS() as IServiceReportFilter),
      //   paging: { pageIndex: 1, pageSize: totalCount },
      // })
      const response = await createReport({
        ...(currentFilter.toJS() as FILTER),
        paging: { pageIndex: 1, pageSize: totalCount },
      })
      downloadFile(response)
    }
  }
  return (
    <Tooltip title={totalCount ? 'Загрузить в Excel' : ''} arrow placement="bottom-end">
      <span>
        <IconButton disabled={!totalCount} className={styles.loadExcelButton} onClick={getReportAsExcel}>
          <img src={load_excel} alt="load_excel" />
        </IconButton>
      </span>
    </Tooltip>
  )
}

interface DataRecord<DATA extends IBaseReport, FILTER extends IBaseFilters>
  extends Record<IBasePaginateState<DATA, FILTER>> {}

type Props<DATA extends IBaseReport, FILTER extends IBaseFilters> = {
  title: string
  description?: string
  state: DataRecord<DATA, FILTER>
  getData: FilteredPaginatedActions<DATA, IBaseFilters>['getData']
  selectFastPeriod: (period?: IFastPeriods) => void
  resetFilters?: FilteredPaginatedActions<DATA, FILTER>['resetFilters']
  withExcelButton?: boolean
  hiddenFilters?: string[]
  disabled?: boolean
}

function FiltersPanel<DATA extends IBaseReport, FILTER extends IBaseFilters>({
  title,
  description,
  state,
  getData,
  selectFastPeriod,
  resetFilters,
  withExcelButton,
  hiddenFilters = [],
  disabled,
}: Props<DATA, FILTER>) {
  const report = state.get('data')
  const currentFilter = state.get('currentFilter')
  const selectedFastPeriod = state.get('selectedFastPeriod')

  const filters = state.get('data')?.filter

  const [period, setDate] = useState<Period>({
    startDate: selectedFastPeriod ? null : moment(report?.filter.period.from),
    endDate: selectedFastPeriod ? null : moment(report?.filter.period.to),
  })
  const [searchText, setSearchText] = useState(report?.filter?.search || '')
  const searchTextRef = useRef(report?.filter?.search || '')

  const isRateFilterExists = filters && filters['rateStatus'] !== undefined && filters['ratingLimits'] !== undefined
  const isWithCommentOnlyFilterExists = filters && filters.withCommentsOnly !== undefined

  const getFiltersData = useCallback((): any[] => {
    const filtersKeys = POSSIBLE_FILTERS.filter((item) => !hiddenFilters?.includes(item))
    return filters
      ? Object.keys(filters).reduce((sum: any, key: string) => {
          return filtersKeys.includes(key) ? [...sum, { id: key, title: FILTER_NAMES[key], ...filters[key] }] : sum
        }, [])
      : []
  }, [filters, hiddenFilters]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedFastPeriod) setDate({ startDate: null, endDate: null })
    if (!selectedFastPeriod && !period.startDate && !period.endDate) changeFastPeriod('Месяц')
  }, [selectedFastPeriod, period.startDate, period.endDate]) // eslint-disable-line react-hooks/exhaustive-deps

  const onChangePeriod = ({ startDate, endDate }: typeof period) => {
    setDate({ startDate, endDate })
    selectFastPeriod(undefined)
    if (startDate?.isValid() && endDate?.isValid()) {
      getData({
        period: {
          from: moment(startDate).startOf('day').toDate(),
          to: moment(endDate).endOf('day').toDate(),
        },
      })
    }
  }

  const onChangeFilter = (filterKey: string, value?: IListFilterItem | null) => {
    getData({ [filterKey]: { items: [value] } })
  }

  const onToggleWithСommentsCheckbox = () => {
    getData({ withCommentsOnly: !currentFilter.withCommentsOnly })
  }

  const onSearchByName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const tmpSearch = e.currentTarget.value
    setSearchText(tmpSearch)
    searchTextRef.current = tmpSearch
    setTimeout(() => {
      if (searchTextRef.current === tmpSearch) {
        getData({ search: tmpSearch.trim() })
      }
    }, 600)
  }

  const clearSearchTextInput = () => {
    setSearchText('')
    searchTextRef.current = ''
    getData({ search: '' })
  }

  const onResetFilters = () => resetFilters && resetFilters()

  const createReport = (period: 'month' | 'quarter' | 'year') => getData({ period: getPeriod(period) })

  const changeFastPeriod = (period: IFastPeriods) => {
    selectFastPeriod(period)
    switch (period) {
      case 'Месяц':
        return createReport('month')
      case 'Квартал':
        return createReport('quarter')
      case 'Год':
        return createReport('year')
    }
  }

  const onSelectFastPeriod = (_: React.MouseEvent<HTMLElement>, newFormat: typeof selectedFastPeriod) => {
    if (!newFormat) return
    changeFastPeriod(newFormat)
  }

  const RenderFilters = (
    <FlexFill flexWrap="wrap">
      {isRateFilterExists && (
        <Box mr={12} mt={16}>
          <RateStatusButtonMenu />
        </Box>
      )}
      {getFiltersData().map(({ id, title, items }: { id: string; title: string; items: any }) => {
        const value: IListFilterItem = currentFilter.get(id)?.items[0]

        if (id === 'departments') {
          return (
            <Box key={id} mr={12} mt={16}>
              <AccordionFilter data={items} />
            </Box>
          )
        }

        return (
          <Box key={id} mr={12} mt={16}>
            <Autocomplete
              title={title}
              value={value}
              data={items}
              onChangeValue={(value) => onChangeFilter(id, value)}
              getOptionSelected={(option, value) => option.key === value.key}
              // renderInput={() => null}
              // options={[]}
              disabled={disabled}
            />
          </Box>
        )
      })}
      {isWithCommentOnlyFilterExists && (
        <Box mr={12} mt={16}>
          <FormControlLabel
            checked={report?.filter?.withCommentsOnly === true}
            control={
              <Checkbox
                onChange={onToggleWithСommentsCheckbox}
                disableRipple
                disableTouchRipple
                disableFocusRipple
                color="primary"
              />
            }
            label="С комментариями"
            labelPlacement="end"
            disabled={disabled || report?.filter?.withCommentsOnly === null}
          />
        </Box>
      )}
    </FlexFill>
  )

  const isFiltersValuesExists = (filters: string[]) =>
    filters.some((filter) => !!currentFilter.getIn([filter, 'items', '0']))
  const isSetSomeFilters =
    isFiltersValuesExists(['rateStatus', 'serviceTypes', 'departments', 'executors', 'clients']) ||
    report?.filter?.withCommentsOnly

  return (
    <FlexColumn flex={1} padding={24}>
      <FlexFill justifyContent="space-between">
        <Flex alignItems="baseline">
          <Text variant="h3">{title}</Text>
          <Box ml={5}>
            <Text color="textSecondary">
              {description ?? (report?.data?.totalCount ? 'найдено ' + report?.data?.totalCount : '')}
            </Text>
          </Box>
        </Flex>
        {withExcelButton && (
          <ExcelButton
            totalCount={report?.data?.totalCount}
            currentFilter={currentFilter}
            createReport={ReportsApi.createServicesReportAsExcel}
          />
        )}
      </FlexFill>
      <FlexFill justifyContent="space-between" mt={24}>
        <FlexFill flex={1}>
          <TextField
            placeholder="Поиск"
            value={searchText}
            onChange={onSearchByName}
            size="small"
            variant="outlined"
            style={{ flex: 1, display: 'flex' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchTextRef.current && (
                    <IconButton size="small" aria-label="Очистить" onClick={clearSearchTextInput}>
                      <ClearIcon />
                    </IconButton>
                  )}
                  <img src={search} alt="search" />
                </InputAdornment>
              ),
            }}
            disabled={disabled}
          />
        </FlexFill>
        <FlexFill justifyContent="flex-end" flex={1.5}>
          <Tabs disabled={disabled} tabs={FastPeriods} selected={selectedFastPeriod} onChange={onSelectFastPeriod} />
          <Box ml={12}>
            <Datepicker
              disabled={disabled}
              selectedPeriod={{ ...period, ...report?.filter?.period! }}
              onChangePeriod={onChangePeriod}
            />
          </Box>
        </FlexFill>
      </FlexFill>
      <FlexFill justifyContent="space-between">
        {/* <RenderFilters /> */}
        {RenderFilters}
        {(isRateFilterExists || getFiltersData().length > 0 || isWithCommentOnlyFilterExists) && (
          <Box mt={16}>
            <Button
              disabled={!isSetSomeFilters}
              variant="contained"
              color="primary"
              size="small"
              onClick={onResetFilters}
            >
              Сбросить фильтры
            </Button>
          </Box>
        )}
      </FlexFill>
    </FlexColumn>
  )
}

export default FiltersPanel
