import { AxiosResponse } from 'axios'
import moment from 'moment'

import { TEXT_RATING } from 'screens/Vote/options'

export function getPeriod(period: 'month' | 'quarter' | 'year') {
  const periods = {
    month: {
      from: moment().subtract(1, 'month').startOf('day').toDate(),
      to: moment().endOf('day').toDate(),
    },
    quarter: {
      from: moment().subtract(1, 'quarter').startOf('day').toDate(),
      to: moment().endOf('day').toDate(),
    },
    year: {
      from: moment().subtract(1, 'year').startOf('day').toDate(),
      to: moment().endOf('day').toDate(),
    },
  }
  return periods[period]
}

export function downloadFile(reponse: AxiosResponse) {
  try {
    const url = window.URL.createObjectURL(new Blob([reponse.data as BlobPart]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `ServiceReport_${moment().format('lll')}.xlsx`)
    document.body.appendChild(link)
    link.click()
  } catch (error) {
    console.error(error)
  }
}

export function getTextRatingAccrodingNumberRating(rating: keyof typeof TEXT_RATING) {
  return TEXT_RATING[rating]
}

export function declensions(number: number, words: string[]) {
  return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? number % 10 : 5]];
}
