import createPalette, { PaletteOptions } from '@material-ui/core/styles/createPalette'

declare module '@material-ui/core/styles/createPalette' {
  export interface TypeBackground {
    default: string;
    paper: string;
    headerLanding: string;
  }
}

const pallete: PaletteOptions = {
  primary: {
    main: '#7953E4',
    light: '#F2EEFC',
    dark: '#6440CA',
    contrastText: '#532FB8',
  },
  secondary: {
    main: '#fff',
    light: '#F2EEFC',
    dark: '#6440CA',
    contrastText: '#532FB8',
  },
  success: {
    main: '#57CF1F',
  },
  info: {
    main: '#FFC875',
  },
  error: {
    main: '#F06E52',
    light: '#FFF0F0',
    contrastText: '#F06E52',
  },
  divider: '#D3D3D4',
  background: {
    default: '#E9E9E9',
    headerLanding: '#725BAB'
  },
  text: {
    primary: '#222527',
    secondary: '#646668',
    hint: '#F4F4F4',
  },
  action: {
    active: '#E4DDFA',
    hover: '#F2EEFC',
    focus: '#A187EC',
    selected: '#F2EEFC',
  },
  grey: {
    50: '#9C9EA0'
  }
}

export default createPalette(pallete)
