import Api from '.'
import { ITopExecutorReport, ITopExecutorFilter, ITopExecutorReportItem, IEmployee } from 'models/champions'

const filterLikes = (item: ITopExecutorReportItem) => ({
  ...item,
  likedBy: item.likedBy.reduce((result, item) => {
    return result.map((item) => item.person.uid).includes(item.person.uid) ? result : [...result, item]
  }, [] as IEmployee[])
})

class Champions {

  static getTopFeedbacksEmployees(filter: ITopExecutorFilter) {
    return Api.post<ITopExecutorReport>('employee/gettopfeedbacksemployees', filter)
      .then((res) => {
        res.data.data.data = res.data.data.data.map(filterLikes)
        return res
      })
  }

  static togglePublicCommentLike(value: boolean, voteId: number) {
    let promise = null
    if (value) {
      promise = Champions.likePublicComment(voteId)
    } else {
      promise = Champions.unlikePublicComment(voteId)
    }
    return promise.then((res) => {
      res.data = filterLikes(res.data)
      return res
    })
  }

  static likePublicComment(voteId: number) {
    return Api.post<ITopExecutorReportItem>(`employee/publiccommentlike?voteId=${voteId}`)
  }

  static unlikePublicComment(voteId: number) {
    return Api.post<ITopExecutorReportItem>(`employee/publiccommentunlike?voteId=${voteId}`)
  }

}

export default Champions
