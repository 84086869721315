import { call, delay, put, select, takeLatest } from 'redux-saga/effects'

import VoteActions, { GET_VOTE, SET_VOTE } from './actions'
import { IVoted } from 'models'
import FeedbackActions from 'store/employee/feedback/actions'
import VoteApi from 'api/vote'
import { AppState } from 'store'

function* getVoteFlow(action: ReturnType<typeof VoteActions.getVote>) {
  const state: AppState = yield select();
  const { voteId, secret } = action.payload
  const voteData = !!voteId && state.vote.votesData[voteId]?.get('voteData')
  if (voteData) {
    yield put(VoteActions.getVoteSuccess(voteData))
  }
  try {
    if (!voteId || !secret) return yield put(VoteActions.getVoteFail(true))
    const { data } = yield call(VoteApi.getVote, voteId, secret)
    yield put(VoteActions.getVoteSuccess(data))
  } catch (err) {
    console.error(err.response)
    yield put(VoteActions.getVoteFail(err, voteId?.toString()))
  }
}

function* setVoteFlow(action: ReturnType<typeof VoteActions.setVote>) {
  const { voteId, secret, rating, comment, isPublicComment } = action.payload
  try {
    const { data }: { data: IVoted } = yield call(VoteApi.setVote, voteId, secret, { rating, comment, isPublicComment })
    yield put(FeedbackActions.getFeedbackCountSuccess(data.notVotedServicesCount))
    yield delay(300)
    yield put(VoteActions.setVoteSuccess(data.vote))
  } catch (err) {
    console.error(err.response)
    yield delay(300)
    yield put(VoteActions.setVoteFail(err, voteId.toString()))
  }
}

export default function* watchVotes() {
  yield takeLatest(GET_VOTE, getVoteFlow)
  yield takeLatest(SET_VOTE, setVoteFlow)
}
