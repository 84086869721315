import React from 'react'

import { Text, FlexCenter, FlexColumn, Paper } from '@ocs.lab/ui'

type Props = {
  isShow: boolean
}

const UseGoogleChromeNotification: React.FC<Props> = ({ isShow }) => {
  const isVoteRoute = () => window.location.pathname.includes('vote')
  if (isShow)
    return (
      <Paper square elevation={1}>
        <FlexCenter width="100vw" height={100}>
          <FlexColumn alignItems="center">
            <Text style={{ fontWeight: 900 }}>Используй браузер Google Chrome для корректной работы OCS Feedback</Text>
            {isVoteRoute() ? (
              <Text color="textSecondary" style={{ fontSize: 14 }}>
                Скопируй ссылку в окне браузера и открой опрос в Google Chrome
              </Text>
            ) : null}
          </FlexColumn>
        </FlexCenter>
      </Paper>
    )
  return null
}

export default UseGoogleChromeNotification
