import { fromJS, Record } from 'immutable'

import { IFastPeriods, Status, IBaseReport, IRequiredFilters } from 'models'
import { BasePaginatedActionNames, FilteredPaginatedActionNames, FilteredPaginatedWithCommentsActionNames } from 'store/helpers/paginatedListFactory/actionNames'
import { IActionWithPayload } from '../actions'
import { reducerFetchFactory } from '../fetch/reducer'
import Filters, { INITIAL_FILTER } from '../filters'
import { createManagerCommentSuccessAction, onSelectFastPeriodAction } from './actions'

export type IBasePaginateState<DATA extends IBaseReport<IRequiredFilters>, FILTER extends IRequiredFilters> = {
  isLoading: boolean
  status?: Status
  data?: DATA
  currentFilter: Filters<FILTER>
  selectedFastPeriod?: IFastPeriods
  error: string | boolean
}

export const initialState = fromJS({
  isLoading: true,
  status: undefined,
  data: undefined,
  currentFilter: INITIAL_FILTER,
  selectedFastPeriod: 'Месяц',
  error: false,
})

/**
 * Базовая фабрика для reducer'а простого списка.
 *
 * @param {string} CHANGE_PAGING - смена номера страницы.
 * @return {Object} reducer списка.
 */

export function createPaginatedListReducer<DATA extends IBaseReport<IRequiredFilters>, FILTER extends IRequiredFilters>({ CHANGE_PAGING, ...rest }: BasePaginatedActionNames) {
  return (state: Record<IBasePaginateState<DATA, FILTER>> = initialState, action: IActionWithPayload<string, any>) => {
    switch (action.type) {
      case CHANGE_PAGING:
        return state.set('isLoading', true)
      default:
        return reducerFetchFactory<DATA, FILTER>(rest)(state, action);
    }
  };
}

/**
 * Фабрика для reducer'а списка с фильтрацией.
 *
 * @param {string} RESET_FILTERS - сброс фильтров.
 * @param {string} ON_SELECT_FAST_PERIOD - изменение быстрого периода (месяц, квартал, год).
 * @return {Object} reducer списка.
 */
export function createFilteredPaginatedListReducer<DATA extends IBaseReport, FILTER extends IRequiredFilters>({ ON_SELECT_FAST_PERIOD, REFRESH_WITH_CURRENT_FILTERS, RESET_FILTERS, ...rest }: FilteredPaginatedActionNames) {
  return (state: Record<IBasePaginateState<DATA, FILTER>> = initialState, action: IActionWithPayload<string, any>) => {
    // console.log(action.type)
    switch (action.type) {
      case RESET_FILTERS:
        return state.set('isLoading', true)
      case ON_SELECT_FAST_PERIOD:
        return onSelectFastPeriod(state, action.payload)
      default:
        return createPaginatedListReducer<DATA, FILTER>(rest)(state, action)
    }
  };
}

function onSelectFastPeriod<DATA extends IBaseReport<IRequiredFilters>, FILTER extends IRequiredFilters>(state: Record<IBasePaginateState<DATA, FILTER>>, payload: onSelectFastPeriodAction['payload']) {
  return state.set('selectedFastPeriod', payload)
}

/**
 * Фабрика для reducer'а списка с фильтрацией с добавлением комментария.
 *
 * @param {string} CREATE_MANAGER_COMMENT_SUCCESS - добавление комментария.
 * @param {string} REMOVE_MANAGER_COMMENT_SUCCESS - удаление комментария.
 * @return {Object} reducer списка.
 */
export function createFilteredPaginatedListWithCommentsReducer<DATA extends IBaseReport, FILTER extends IRequiredFilters>({ CREATE_MANAGER_COMMENT, CREATE_MANAGER_COMMENT_SUCCESS, CREATE_MANAGER_COMMENT_FAIL, REMOVE_MANAGER_COMMENT, REMOVE_MANAGER_COMMENT_SUCCESS, REMOVE_MANAGER_COMMENT_FAIL, ...rest }: FilteredPaginatedWithCommentsActionNames) {
  return (state: Record<IBasePaginateState<DATA, FILTER>> = initialState, action: IActionWithPayload<string, any>) => {
    switch (action.type) {
      case CREATE_MANAGER_COMMENT_SUCCESS:
      case REMOVE_MANAGER_COMMENT_SUCCESS:
        return updateManagerComment(state, action.payload)
      default:
        return createFilteredPaginatedListReducer<DATA, FILTER>(rest)(state, action)
    }
  };
}

function updateManagerComment<DATA extends IBaseReport<IRequiredFilters>, FILTER extends IRequiredFilters>(state: Record<IBasePaginateState<DATA, FILTER>>, payload: createManagerCommentSuccessAction['payload']) {
  const data = state.get('data')
  return state.setIn(['data', 'data', 'data'], data?.data?.data?.map((service) => service.service.serviceId === payload.serviceId ? { ...service, serviceComments: payload.data } : service))
}
