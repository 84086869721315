import { styled } from '@material-ui/core/styles'
import { FlexColumn, Typography } from '@ocs.lab/ui'
import { IEmployeeFeedbackItem } from 'models/employee/feedback'
import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { scroller, Element } from 'react-scroll'

const scrollerParams = {
  offset: -88,
  duration: 800,
  delay: 0,
  smooth: 'easeInOutQuart',
  containerId: 'votes-list'
}

const StyledContainer = styled(FlexColumn)(({
  padding: "16px 48px",
  gap: 16,
  height: 375, 
  overflow: "hidden auto",
  maxWidth: 400, 
  boxSizing: "border-box",
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    backgroundColor: '#babac0'
  }
}));


const StyledLink = styled(NavLink)(({
  theme
}) => ({
  color: theme.palette.text.primary,
}));

const StyledText = styled(Typography)(({
  theme
}) => ({
  '&.link-active': {
    color: theme.palette.primary.main,
  },
  '&:hover': {
    color: theme.palette.primary.dark,
  },
}))

type Props = {
  voteId: number
  secret: string
  data?: IEmployeeFeedbackItem[]
  loading: boolean
  error: any
}

const VotesList = ({ voteId, secret, data, loading, error }: Props) => {

  useEffect(() => {
    scroller.scrollTo(voteId.toString(), scrollerParams)
  }, [voteId])

  if (loading) {
    return null
  }

  if (error) {
    return null
  }

  return (
    <StyledContainer id="votes-list">
      {
        data?.map((vote) => {
          const isActive = vote.voteInfo.voteId === voteId && vote.voteInfo.voteSecret === secret
          return(
            <Element key={vote.voteInfo.voteId} name={vote.voteInfo.voteId.toString()}>
              <StyledLink className={isActive ? 'link-active' : ''} to={`/vote?voteId=${vote.voteInfo.voteId}&secret=${vote.voteInfo.voteSecret}`}>
                <StyledText className={isActive ? 'link-active' : ''}>{vote.serviceTypeTitle}</StyledText>
                {vote.serviceTypeTitle !== vote.service.title &&<Typography variant="subtitle2" noWrap>{vote.service.title}</Typography>}
              </StyledLink>
            </Element>
          )
        })
      }
    </StyledContainer>
  )
}

export default VotesList