import React, { useState } from 'react'
import { Box, Button } from '@ocs.lab/ui'

import { IBaseStatisticRecord } from 'models/reports'

import { makeStyles } from '@material-ui/core/styles'
import Activity from './Activity'

const useStyles = makeStyles(({ palette }) => ({
  container: {
    paddingBottom: 8,
  },
}))

const Departments: React.VFC<{ items?: Omit<IBaseStatisticRecord, 'uid'>[]; hidden?: boolean }> = ({
  items,
  hidden,
}) => {
  const { container } = useStyles()

  const [open, setOpen] = useState(false)

  const finalItems = open ? items : items?.slice(0, 9)

  const showMoreButton = !!items && items.length > 10 && !open

  if (finalItems?.length === 0) return null

  return (
    <Box className={container}>
      {!hidden && (
        <>
          <Box>
            {finalItems?.map((item) => (
              <Activity key={item.title} item={item} />
            ))}
          </Box>
          {showMoreButton && (
            <Box mt={10}>
              <Button variant="text" color="primary" fullWidth onClick={() => setOpen(true)}>
                Показать еще
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default Departments
