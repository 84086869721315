import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { Box, FlexColumn, ScrollToTop, Text } from '@ocs.lab/ui'

import { AppState } from 'store'
import FeedbackActions from 'store/employee/feedback/actions'
import TableStatus from '../../components/TableComponents/TableStatus'
import TableFeedbackLayout from './components/TableBody'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = {} & PropsFromRedux

type State = {
  voteMessage?: string
}

//TODO: повторение логики и UI reports надо сделать унивесрслаьные компоненты
class Feedback extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.getEmployeeFeedback()
  }

  RenderLayout = () => {
    const { state } = this.props
    const isLoading = state.get('isLoading')
    const feedback = state.get('data')

    if (isLoading && !feedback) return <TableStatus showStatus="InitLoading" />
    if (!feedback?.length) return <TableStatus showStatus="NoFeedbacks" />
    return (
      <TableFeedbackLayout
        onVoteSuccess={(message) => {
          this.setState({ voteMessage: message })
        }}
      />
    )
  }

  render() {
    return (
      <Box minHeight="90vh">
        <FlexColumn p={24} gridGap={24}>
          <Text variant="h3">Обратная связь</Text>
          <Box maxWidth={850}>
            <Text>
              Обратную связь можно дать двумя способами: через письмо на почте и здесь — в личном кабинете. Твой отзыв
              увидит только руководитель сотрудника, которому ты даёшь фидбек. Для руководителя важна оперативная
              обратная связь, поэтому в личном кабинете мы показываем активности коллег только за 3 последних месяца.
            </Text>
          </Box>
        </FlexColumn>
        <this.RenderLayout />
        <Snackbar
          open={!!this.state.voteMessage}
          onClose={() => this.setState({ voteMessage: undefined })}
          autoHideDuration={5000}
          TransitionComponent={Slide}
          message={this.state.voteMessage}
          ContentProps={{
            style: { justifyContent: 'center' },
          }}
        />
        <ScrollToTop />
      </Box>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  state: state.feedback,
})

const mapDispatchToProps = {
  getEmployeeFeedback: FeedbackActions.getEmployeeFeedback,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(Feedback)
