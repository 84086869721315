import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Autocomplete, TextField } from '@ocs.lab/ui'
import { RenderUserOption } from '@ocs.lab/ui/lib/components/elements/Autocomplete/options/RenderUserOption'

import { IListFilterItem } from 'models'

import { IEmployee } from 'models/champions'

import CustomPopper from './Popper'
import ListboxComponent from './ListboxComponent'
import { filterUserOptions } from '@ocs.lab/ui/lib/components/elements/Autocomplete/helpers'
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete'

const useStyles = makeStyles(({ palette }) => ({
  option: {
    minHeight: 48,
    '&[data-focus="true"]': {
      backgroundColor: palette.primary.light,
    },
  },
  inputBase: {
    width: 235,
  },
  listbox: {
    maxHeight: '70%',
  },
}))

type Props = {
  title: string
  value?: IListFilterItem<IEmployee>
  data?: IListFilterItem<IEmployee>[]
  onChangeValue: (value?: IListFilterItem<IEmployee>) => any
  getOptionSelected?: (option: IListFilterItem<IEmployee>, value: IListFilterItem<IEmployee>) => boolean
  renderOption?: (
    option: IListFilterItem<IEmployee>,
    state: {
      selected: boolean
      inputValue: string
    }
  ) => JSX.Element
  disabled?: boolean
}

const VirtualizedEmployeeAutocomplete: React.FC<Props> = ({
  title,
  data = [],
  value,
  onChangeValue,
  getOptionSelected,
  disabled = false,
}) => {
  const { option, inputBase, listbox } = useStyles()

  const RenderInput = (params: AutocompleteRenderInputParams) => (
    <TextField {...params} classes={{ root: inputBase }} label={title} variant="outlined" />
  )

  return (
    <Autocomplete
      size="small"
      title={title}
      value={value || null}
      onChange={(e, newValue: any) => onChangeValue(newValue)}
      filterOptions={filterUserOptions}
      options={data.filter((v) => v.value)}
      renderOption={RenderUserOption}
      classes={{ option }}
      getOptionLabel={(option) => option.value.person.displayName || option.key}
      getOptionSelected={getOptionSelected}
      disabled={disabled}
      PopperComponent={CustomPopper}
      ListboxComponent={(ListboxComponent as unknown) as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      ListboxProps={{
        index: data.map((i) => i.key).indexOf(value?.key || ''),
        className: listbox,
      }}
      blurOnSelect={true}
      renderInput={RenderInput}
      debug={true}
    />
  )
}

export default VirtualizedEmployeeAutocomplete
