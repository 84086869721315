import React from 'react'

import { Text, FlexColumn, Flex } from '@ocs.lab/ui'

import Step from './components/Step'

import STEPS from './steps'
import { useTheme } from '@material-ui/core'

const Steps = () => {
  const { palette } = useTheme()
  return (
    <FlexColumn id="how-it-works" bgcolor={palette.primary.light} py={120} alignItems="center">
      <Flex>
        <Text variant="h1">Как именно это работает</Text>&nbsp;
        <Text variant="h1" color="primary">
          шаг за шагом
        </Text>
      </Flex>
      <Flex pb={60} pt={8}>
        <Text variant="h6">На примере активности «заявка на юридическую помощь»</Text>
      </Flex>
      <FlexColumn>
        {STEPS.map((step, index) => (
          <Step key={index} {...step} />
        ))}
      </FlexColumn>
    </FlexColumn>
  )
}

export default Steps
