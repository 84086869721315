import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { Link, Flex, FlexColumn, Text } from '@ocs.lab/ui'
import { LAST_CHILD, HOVER } from '@ocs.lab/ui/lib/theme/helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    bottom: 64,
    right: 0,
    WebkitBackdropFilter: 'blur(10px)',
    padding: 16,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    zIndex: 9999,
    [HOVER]: {
      backgroundColor: 'rgba(255, 255, 255, 1)',
    },
    transition: '0.5s',
  },
  MenuItem_container: {
    marginBottom: 24,
    alignItems: 'flex-start',
    display: 'flex',
    '& p': {
      color: ({ isActive }: any) => (isActive ? theme.palette.primary.contrastText : theme.palette.text.primary),
    },
    [LAST_CHILD]: {
      marginBottom: 0,
    },
    [HOVER]: {
      '& > p': {
        color: theme.palette.primary.contrastText,
      },
      '& > div': {
        backgroundColor: theme.palette.primary.main,
      },
      cursor: 'pointer',
    },
  },
  MenuItem_indicator: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    marginTop: 6,
    marginRight: 9,
    backgroundColor: ({ isActive }: any) => (isActive ? theme.palette.primary.main : theme.palette.background.default),
    transition: '0.5s',
  },
}))

//NOTE: maybe need some ref-ing
const Sections = [
  { title: 'О сервисе', href: '#about', isActiveMin: 0, isActiveMax: 2000 },
  { title: 'Как это работает', href: '#how-it-works', isActiveMin: 2001, isActiveMax: 3119 },
  { title: 'Для чего это нужно', href: '#for-what', isActiveMin: 3120, isActiveMax: 3700 },
  { title: 'Принципы\nобратной связи', href: '#feedback-principles', isActiveMin: 3701, isActiveMax: 4800 },
  { title: 'Товары Feedback', href: '#goods', isActiveMin: 4801, isActiveMax: 5400 },
  { title: 'Вопросы и ответы', href: '#faq', isActiveMin: 5401, isActiveMax: 6100 },
  { title: 'Разработчики\nсервиса', href: '#team', isActiveMin: 6101, isActiveMax: 99999 },
]

type MenuItemProps = {
  title: string
  href: string
  isActive: boolean
}

const MenuItem: React.FC<MenuItemProps> = ({ title, href, isActive }) => {
  const styles = useStyles({ isActive })
  return (
    <Link className={styles.MenuItem_container} underline="none" href={href}>
      <Flex className={styles.MenuItem_indicator} />
      <Text style={{ whiteSpace: 'pre-line' }}>{title}</Text>
    </Link>
  )
}

const SideMenu = () => {
  const styles = useStyles({})
  const [scrollY, setScrollY] = useState(0)

  const handleScroll = (e: Event) => setScrollY(window.scrollY)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <FlexColumn className={styles.container}>
      {Sections.map((section) => (
        <MenuItem
          key={section.title}
          title={section.title}
          href={section.href}
          isActive={scrollY >= section.isActiveMin && scrollY <= section.isActiveMax}
        />
      ))}
    </FlexColumn>
  )
}

export default SideMenu
