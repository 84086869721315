import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import { useSelector as useReduxSelector, TypedUseSelectorHook, useDispatch as useReduxDispatch } from 'react-redux'
import { all, fork } from 'redux-saga/effects'
import createSagaMiddleware from 'redux-saga'

//TODO: i think need rename "user" to "keycloak"
import user from './keycloak'
import statistics from './statistics'
import vote from './vote'
import feedback from './employee/feedback'
import serviceSummary from './reports/serviceSummary'

import employeeInfo from './reports/employeeInfo'

import watchStatistics from './statistics/sagas'
import watchVote from './vote/sagas'
import watchFeedback from './employee/feedback/sagas'
import watchServiceSummary from './reports/serviceSummary/sagas'

import { reports } from './reports'
import { activities } from './employee/activities'
import { champions } from './champions'
import watchEmployeeInfo from './reports/employeeInfo/sagas'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const sagaMiddleware = createSagaMiddleware()

const reducers = combineReducers({
  //TODO: i think need rename "user" to "keycloak"
  user,
  statistics,
  vote,
  reports: reports.reducer,
  activities: activities.reducer,
  feedback,
  champions: champions.reducer,
  serviceSummary,
  employeeInfo
})

const sagas = function* root() {
  yield all([
    fork(watchStatistics),
    fork(watchVote),
    fork(reports.sagas),
    fork(activities.sagas),
    fork(watchFeedback),
    fork(champions.sagas),
    fork(watchServiceSummary),
    fork(watchEmployeeInfo)
  ])
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(sagaMiddleware)))

sagaMiddleware.run(sagas)

export type AppState = ReturnType<typeof reducers>

export const useSelector: TypedUseSelectorHook<AppState> = useReduxSelector

export const useDispatch = useReduxDispatch

export default store
