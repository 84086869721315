import osip_product_1 from 'assets/images/Lanjards.png'
import osip_product_4 from 'assets/images/Stickers.png'

const PRODUCTS = [
  {
    title: 'Ланьярды',
    price: 5,
    photo: osip_product_1,
    href: 'https://store.ocs.ru/catalog/?SECTION_ID=128&ELEMENT_ID=578'
  },
  {
    title: 'Стикерпак',
    price: 1,
    photo: osip_product_4,
    href: 'https://store.ocs.ru/catalog/?SECTION_ID=128&ELEMENT_ID=584'
  },
]

export default PRODUCTS