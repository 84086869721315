import { Box, Typography } from '@ocs.lab/ui'
import { IEmployee } from 'models/champions'
import React from 'react'
import { formLikesCountString } from '../LikesPreview'
import User from '@ocs.lab/ui/lib/components/elements/User'

type Props = {
  data: IEmployee[]
}

const LikesList: React.FC<Props> = ({ data = [] }) => {
  return (
    <>
      <Box width="348px" padding="8px 0 0px 8px" boxSizing="border-box">
        <Box padding="0px 0px 8px">
          <Typography variant="h6" style={{ lineHeight: '24px' }}>
            {formLikesCountString(data.length)}
          </Typography>
        </Box>
        <Box maxHeight="60vh" overflow="hidden scroll" pb={8}>
          {data.map((like) => (
            <User
              key={like.person.uid}
              name={like.person.displayName ?? 'Сотрудник больше не работает в компании'}
              description={like.position}
              avatarUrl={like.person.avatarUrl ?? ''}
            />
          ))}
        </Box>
      </Box>
    </>
  )
}

export default LikesList
