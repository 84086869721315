import { createFilteredPaginatedListWithComments } from "store/helpers/paginatedListFactory"
import ReportsApi from 'api/reports'

import { IServiceReport, IServiceReportFilter } from 'models/reports'

export const reports = createFilteredPaginatedListWithComments<IServiceReport, IServiceReportFilter>(
  'reports',
  {
    getData: ReportsApi.createReport,
    createComment: ReportsApi.createManagerComment,
    removeComment: ReportsApi.removeComment,
  }
)

