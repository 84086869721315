import { Button } from '@ocs.lab/ui'
import React, { useMemo } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import check from 'assets/icons/check.svg'
import errorIcon from 'assets/icons/close_white.svg'

const useLoaderStyles = makeStyles(({ palette }) => ({
  spinner: {
    animation: 'rotate 2s linear infinite',
    zIndex: 2,
    width: 20,
    height: 20,
  },
  path: {
    stroke: palette.common.white,
    strokeLinecap: 'round',
    animation: 'dash 1.5s ease-in-out infinite'
  }
}))

const useButtonStyles = makeStyles(({ palette }) => ({
  root: {
    width: ({isSmallScreen}: any) => isSmallScreen ? undefined : 120,
    height: 48,
    marginTop: ({isSmallScreen}: any) => isSmallScreen ? 24 : 0,
    backgroundColor: ({isError, isSuccess}: any) => isError ? palette.error.main : isSuccess ? palette.success.main : undefined,
    '&:hover': {
      backgroundColor: ({isError, isSuccess}: any) => isError ? palette.error.main : isSuccess ? palette.success.main : undefined
    }
  }
}))

const Loader = () => {
  const { spinner, path } = useLoaderStyles()

  return (
    <svg className={spinner} viewBox="0 0 50 50">
      <circle className={path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
    </svg>
  )
}

const stopPropagation: React.TouchEventHandler = (e) => {
  e.stopPropagation()
}


type Props = {
  isSmallScreen: boolean
  isReadyToSend: boolean
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  onSend: () => void
}

const SendButton = ({isSmallScreen, isReadyToSend, isLoading, isSuccess, isError, onSend}: Props) => {

  const classes = useButtonStyles({isSmallScreen, isReadyToSend, isLoading, isSuccess, isError})

  const contentEl = useMemo(() => {
    if (isError) return <img src={errorIcon} alt="Error" />
    if (isLoading) return <Loader />
    if (isSuccess) return <img src={check} alt="Done" />
    return 'Отправить'
  }, [isError, isLoading, isSuccess])

  return (
    <Button
      className={classes.root}
      fullWidth={isSmallScreen}
      onClick={onSend}
      variant="contained"
      color="primary"
      disabled={!isReadyToSend}
      onTouchStart={stopPropagation}
      onTouchMove={stopPropagation}
    >
      {contentEl}
    </Button>
  )
}

export default SendButton