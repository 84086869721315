import React from 'react'

import styled from '@material-ui/core/styles/styled'
import { makeStyles } from '@material-ui/core/styles'

import { Flex, FlexCenter, Text } from '@ocs.lab/ui'
import Tooltip, { TooltipProps } from '@ocs.lab/ui/lib/components/elements/Tooltip'
import { SmallClock } from '@ocs.lab/ui/lib/components/icons'
import mark_2 from '@ocs.lab/ui/lib/assets/icons/mark_2.svg'
import comment_fill from '@ocs.lab/ui/lib/assets/icons/comment_fill.svg'

import { IVoteSummary } from 'models/reports'

import RatingStatusDelayed from 'components/RatingStatusDelayed'

const GOT_FEEDBACK_TEXT_TOOLTIP = 'Обратная связь получена'
const NOT_YET_FEEDBACK_TOOLTIP = 'Заказчик пока не дал обратную связь'

const useStyles = makeStyles((theme) => ({
  ratingContainer: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 2,
  },
  tooltipRatingText: {
    color: (props: any) => (props.ratingComment ? '#fff' : theme.palette.text.primary),
    height: 24,
    width: 24,
    zIndex: 100,
  },
}))

//FIXME: need to perfect center, i suppose now dont
const CommentFillIcon = styled('img')({
  position: 'absolute',
  top: -2,
})

type Props = {
  voteSummary: IVoteSummary
  placement?: TooltipProps['placement']
}

const RatingsStatus: React.FC<Props> = ({ voteSummary, placement = 'top' }) => {
  const { rating, daysDelayed, daysDelayedWithHours, ratingComment, voted } = voteSummary
  const { ratingContainer, tooltipRatingText } = useStyles({ ratingComment })
  const textTooltip = () =>
    voted === undefined ? ratingComment ?? '' : voted === true ? GOT_FEEDBACK_TEXT_TOOLTIP : NOT_YET_FEEDBACK_TOOLTIP

  const GetIconIfVoted = () => (
    <Tooltip title={textTooltip()} arrow placement={placement}>
      <FlexCenter>{voted ? <img src={mark_2} alt="" /> : <SmallClock />}</FlexCenter>
    </Tooltip>
  )

  if (rating)
    return (
      <Flex className={ratingContainer}>
        <Tooltip title={textTooltip()} arrow placement={placement}>
          <Flex>
            {ratingComment ? <CommentFillIcon src={comment_fill} alt="" /> : null}
            <Text className={tooltipRatingText} align="center">
              {rating}
            </Text>
          </Flex>
        </Tooltip>
      </Flex>
    )
  return voted !== undefined ? (
    <GetIconIfVoted />
  ) : (
    <RatingStatusDelayed daysDelayed={daysDelayed} daysDelayedWithHours={daysDelayedWithHours} placement={placement} />
  )
}

export default RatingsStatus
