import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from 'store'

import { Box, ScrollToTop } from '@ocs.lab/ui'
import { Text } from '@ocs.lab/ui'
import { Button } from '@ocs.lab/ui'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'

import { Dialog, Flex, FlexFill, FlexColumn } from '@ocs.lab/ui'

import { IEmployee, ITopExecutorReport, ITopExecutorReportItem } from 'models/champions'

import TopExecutorReportItem from './components/TopExecutorReportItem'
import ThanksForm from './components/ThanksForm'
import TableStatus from '../../components/TableComponents/TableStatus'
import Table, { ITableCaption } from 'components/TableComponents/Table'
import { champions } from 'store/champions'
import EmployeeInfoActions from 'store/reports/employeeInfo/actions'
import EmployeeInfo from 'screens/Reports/components/EmployeeInfo'
import LikesList from './components/Likes/LikesList'
import VirtualizedEmployeeAutocomplete from 'components/Autocomplete/VirtualizedEmployeeAutocomplete'
import UserSettings from 'services/UserSettings'

const PAGE_SIZE = 20

const TABLE_CAPTIONS: ITableCaption[] = [
  { key: 'client', title: 'Заказчик' },
  { key: 'serviceTypeTitle', title: 'Активность' },
  { key: 'executors', title: 'Исполнитель' },
  { key: 'publicComment', title: 'Обратная связь' },
  { key: 'feedbackDate', title: 'Дата' },
  { key: 'likes', title: '' },
]

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = {} & PropsFromRedux

class Champions extends React.PureComponent<Props> {
  state = {
    isCommentModalOpen: false,
    //TODO: may need setup global snack bar with state managment
    isShowSnackbar: false,
    isEmployeeInfoOpen: false,
    isLikesModalOpen: false,
    likes: [],
  }

  componentDidMount() {
    UserSettings.pageSize = { champions: PAGE_SIZE }
    this.props.getTopEmployeeFeedback({})
  }

  onChangeFilter = (filterKey: string, value?: any) => {
    this.props.getTopEmployeeFeedback({
      [filterKey]: { items: [value] },
    })
  }

  handleCloseThanksFormModal = () => this.setState({ isCommentModalOpen: false })
  handleOpenThanksFormModal = () => this.setState({ isCommentModalOpen: true })

  handleDrawerEmployeeInfoOpen = (uuid: string) => {
    this.setState({ isEmployeeInfoOpen: true })
    this.props.getEmployeeInfo(uuid)
  }
  handleDrawerEmployeeInfoClose = () => {
    this.setState({ isEmployeeInfoOpen: false })
  }

  handleLikesModalOpen = (likes: IEmployee[]) => {
    this.setState({ likes, isLikesModalOpen: true })
  }
  handleLikesModalClose = () => {
    this.setState({ isLikesModalOpen: false })
  }

  showSnackbar = () => {
    this.props.getTopEmployeeFeedback({})
    this.setState({ isShowSnackbar: true })
  }

  closeSnackbar = () => this.setState({ isShowSnackbar: false })

  RenderLayout = () => {
    const { state, getTopEmployeeFeedback, changePaging } = this.props
    const isLoading = state.get('isLoading')
    const champions = state.get('data')

    if (isLoading && !champions) return <TableStatus showStatus="InitLoading" />
    if (!champions?.data.data.length) return <TableStatus showStatus="NoChampions" />

    //TODO: необходимо после имелементации универсального паджинированного списка отправлять тип item-а который рендерится компонентов ReportLineItem
    return (
      <Table<ITopExecutorReport, ITopExecutorReportItem>
        modelName="champions"
        state={state}
        captions={TABLE_CAPTIONS}
        getData={getTopEmployeeFeedback}
        withPageSize={false}
        changePage={changePaging}
        renderItem={(item) => (
          <TopExecutorReportItem
            key={item.voteId}
            data={item}
            onEmployeeInfoOpen={this.handleDrawerEmployeeInfoOpen!}
            onLikesModalOpen={this.handleLikesModalOpen}
          />
        )}
      />
    )
  }

  render() {
    const { state } = this.props
    const { isCommentModalOpen, isShowSnackbar, isEmployeeInfoOpen, isLikesModalOpen, likes } = this.state
    const champions = state.get('data')
    const currentFilter = state.get('currentFilter')

    return (
      <Box minHeight="90vh">
        <FlexColumn flex={1} padding={24}>
          <FlexFill justifyContent="space-between">
            <Flex alignItems="baseline">
              <Text variant="h3">Лав-лента</Text>
              {champions?.data?.totalCount ? (
                <Box ml={5}>
                  <Text color="textSecondary"> найдено {champions?.data?.totalCount}</Text>
                </Box>
              ) : null}
            </Flex>
          </FlexFill>
          <FlexFill justifyContent="space-between" mt={24}>
            <Text>
              В лав-ленте ты можешь поблагодарить коллегу за неравнодушие и поддержку или просто пожелать хорошего дня.
              <br /> Лента публичная, ее может посмотреть любой сотрудник OCS.
            </Text>
            <Button variant="contained" color="primary" size="small" onClick={this.handleOpenThanksFormModal}>
              Поблагодарить
            </Button>
          </FlexFill>
          <FlexFill flexWrap="wrap">
            <Box mr={12} mt={16}>
              <VirtualizedEmployeeAutocomplete
                title="Фильтр по сотрудникам"
                value={currentFilter.getIn(['employees', 'items', 0])}
                data={champions?.filter.employees?.items}
                onChangeValue={(value) => {
                  this.onChangeFilter('employees', value)
                }}
                getOptionSelected={(option, value) => option.key === value.key}
              />
            </Box>
          </FlexFill>
        </FlexColumn>
        <this.RenderLayout />
        <Dialog
          isOpen={isCommentModalOpen}
          onOverlayClick={this.handleCloseThanksFormModal}
          onClose={this.handleCloseThanksFormModal}
          scroll="body"
        >
          <ThanksForm showSnackbarOnComplete={this.showSnackbar} onClose={this.handleCloseThanksFormModal} />
        </Dialog>

        <Dialog
          isOpen={isLikesModalOpen}
          onOverlayClick={this.handleLikesModalClose}
          onClose={this.handleLikesModalClose}
          scroll="body"
        >
          <LikesList data={likes} />
        </Dialog>

        <EmployeeInfo open={isEmployeeInfoOpen} onClose={this.handleDrawerEmployeeInfoClose} />
        <ScrollToTop />

        {/* //TODO: may need setup global snack bar with state managment in future */}
        <Snackbar
          open={isShowSnackbar}
          onClose={this.closeSnackbar}
          autoHideDuration={5000}
          TransitionComponent={Slide}
          message="Уведомление отправлено твоему коллеге на почту."
        />
      </Box>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  state: state.champions,
})

const mapDispatchToProps = {
  getTopEmployeeFeedback: champions.actions.getData,
  changePaging: champions.actions.changePaging,
  getEmployeeInfo: EmployeeInfoActions.getData,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(Champions)
