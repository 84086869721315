import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'store'

import { Box, Button, Typography } from '@ocs.lab/ui'
import { HOVER, LAST_CHILD, FIRST_CHILD } from '@ocs.lab/ui/lib/theme/helpers'
import { ClearIcon } from '@ocs.lab/ui/lib/components/icons'
import mark from '@ocs.lab/ui/lib/assets/icons/mark.svg'
import arrow from '@ocs.lab/ui/lib/assets/icons/arrow.svg'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuItem from '@material-ui/core/MenuItem'

import { IListFilterItem } from 'models'

import { getTextRatingAccrodingNumberRating } from 'utils'

import './styles.css'
import { reports } from 'store/reports'

const useStyles = makeStyles(({ palette }) => ({
  rootButton: {
    width: 200,
    height: 40,
    borderRadius: 12,
    backgroundColor: '#fff',
    transition: 'none',
    justifyContent: 'left',
    border: '2px solid #fff',
    [HOVER]: {
      borderColor: palette.action.focus,
      backgroundColor: '#fff',
      '& .MuiButton-endIcon': {
        display: 'inherit',
      },
    },
  },
  labelButton: {
    justifyContent: 'space-between',
  },
  endIconButton: {
    display: 'none',
    [HOVER]: {
      backgroundColor: '#fff',
    },
  },
  listItemGutters: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  subButton: {
    minWidth: 58,
    width: 58,
    height: 38,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 12,
    border: '2px solid',
    borderColor: palette.background.default,
    backgroundColor: '#fff',
  },
  menuItem: {
    width: 200,
    height: 48,
    display: 'flex',
    justifyContent: 'flex-end',
    overflow: 'visible',
    [FIRST_CHILD]: {
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    },
    [LAST_CHILD]: {
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
    },
  },
  subMenuItem: {
    width: 120,
    display: 'flex',
    paddingLeft: 25,
    [FIRST_CHILD]: {
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    },
    [LAST_CHILD]: {
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
    },
  },
}))

const RATE_STATUSES = {
  AllVoted: {
    key: 'AllVoted',
    value: 'Есть',
    enabled: true,
    selected: false,
  },
  NoVotes: {
    key: 'NoVotes',
    value: 'Нет',
    enabled: true,
    selected: false,
  },
  AnyVoted: {
    key: 'AnyVoted',
    value: 'Частично',
    enabled: true,
    selected: false,
  },
}

//NOTE: тут просто жесткий костыль, лучше не вдаваться в подробности как тут все работает а просто снести это гавно и написать заново
//NOTE: заранее сори, было мало времени надо было сделать именно не по ховеру а по клику 😩
//FIXME: FIXME F*** f***** f**
//NOTE: PS: не мы такие, заказчик такой 🤗
const RateStatusButtonMenu: React.FC = () => {
  const { palette } = useTheme()
  const styles = useStyles()
  const dispatch = useDispatch()
  const currentFilter = useSelector(({ reports }) => reports.get('currentFilter'))
  const report = useSelector(({ reports }) => reports.get('data'))

  const currentRateStatusValue = currentFilter.getIn(['rateStatus', 'items', 0])?.value
  const currentRatingLimitsValue = currentFilter.getIn(['ratingLimits', 'items', 0])?.value

  let title = (
    <Typography component="span">
      <Typography component="span" color="textSecondary">
        Оценка
      </Typography>
      {!!currentRateStatusValue && <Typography component="span">: </Typography>}
      {!!currentRateStatusValue && (
        <Typography component="span">
          <Typography component="span">{currentRateStatusValue}</Typography>
          {currentRatingLimitsValue && <Typography component="span">{' ≤ ' + currentRatingLimitsValue}</Typography>}
        </Typography>
      )}
    </Typography>
  )

  const subTitle = currentRatingLimitsValue ?? 5

  const [isExpanded, setIsExpanded] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)

  let topLevelMenu = useRef<HTMLUListElement>(null)
  let secondLevelMenu = useRef<HTMLUListElement>(null)

  //FIXME: переделать
  const onMenuItemClick = (e: React.MouseEvent<any, MouseEvent>, value: IListFilterItem) => {
    e.stopPropagation()
    dispatch(reports.actions.getData({ rateStatus: { items: [value] }, ratingLimits: undefined }))
    handleButton()
  }

  //FIXME: переделать
  const onSubMenuItemClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, value: IListFilterItem) => {
    e.stopPropagation()
    dispatch(
      reports.actions.getData({ rateStatus: { items: [RATE_STATUSES.AllVoted] }, ratingLimits: { items: [value] } })
    )
  }

  //FIXME: переделать
  const handleButton = () => {
    if (topLevelMenu.current)
      isOpen ? (topLevelMenu.current.style.display = 'none') : (topLevelMenu.current.style.display = 'flex')
    setIsOpen(!isOpen)
  }

  //FIXME: переделать
  const handleRatingsButton = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation()
    if (secondLevelMenu.current)
      isExpanded ? (secondLevelMenu.current.style.display = 'none') : (secondLevelMenu.current.style.display = 'flex')
    setIsExpanded(!isExpanded)
  }

  //FIXME: переделать
  const onClickAway = () => {
    setIsExpanded(false)
    setIsOpen(false)
    if (topLevelMenu.current) topLevelMenu.current.style.display = 'none'
    if (secondLevelMenu.current) secondLevelMenu.current.style.display = 'none'
  }

  const onClear = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation()
    dispatch(reports.actions.getData({ rateStatus: undefined, ratingLimits: undefined }))
  }

  // console.log(currentRateStatusValue)
  // if (flag) return null
  // console.log(report?.filter?.rateStatus?.items)

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box position="relative">
        <Button
          variant="outlined"
          style={{
            borderColor: isOpen ? palette.action.focus : undefined,
            color: currentRateStatusValue ?? palette.text.secondary,
          }}
          className={styles.rootButton}
          classes={{ label: styles.labelButton, endIcon: styles.endIconButton }}
          endIcon={currentRateStatusValue ? <ClearIcon onClick={onClear} /> : null}
          onClick={handleButton}
        >
          {title}
        </Button>

        {/* //FIXME: переделать//FIXME: переделать//FIXME: переделать//FIXME: переделать//FIXME: переделать//FIXME: переделать//FIXME: переделать//FIXME: переделать//FIXME: переделать */}
        {/* TODO:REFACTORING! TODO:REFACTORING!TODO:REFACTORING!TODO:REFACTORING!TODO:REFACTORING!TODO:REFACTORING!TODO:REFACTORING! */}
        <ul id="top-level-menu" ref={topLevelMenu}>
          {report?.filter?.rateStatus?.items.map((item) => (
            <MenuItem
              key={item.key}
              classes={{ root: styles.menuItem, gutters: styles.listItemGutters }}
              onClick={(e) => onMenuItemClick(e, item)}
            >
              {currentRateStatusValue === item.value && (
                <img style={{ marginLeft: -21, marginRight: 5 }} src={mark} alt="" />
              )}
              <Typography
                style={{ flex: 1, color: currentRateStatusValue === item.value ? palette.primary.main : undefined }}
              >
                {item.value}
              </Typography>
              {item.key === 'AllVoted' && (
                <div style={{ position: 'relative' }}>
                  <Box display="flex" alignItems="center" onClick={handleRatingsButton}>
                    <Typography style={{ marginRight: 8 }}>≤</Typography>
                    <ul>
                      <MenuItem selected={isExpanded} className={styles.subButton}>
                        <Typography>{subTitle}</Typography>
                        <img src={arrow} alt="" />
                      </MenuItem>
                    </ul>
                  </Box>
                  <ul id="second-level-menu" ref={secondLevelMenu}>
                    {report?.filter?.ratingLimits?.items.map((subMenu) => (
                      <MenuItem
                        key={subMenu.key}
                        style={{
                          color: subMenu.enabled ? palette.text.primary : palette.background.default,
                          backgroundColor:
                            currentRatingLimitsValue === subMenu.value ? palette.primary.light : undefined,
                        }}
                        className={styles.subMenuItem}
                        onClick={(e) => onSubMenuItemClick(e, subMenu)}
                      >
                        {currentRatingLimitsValue === subMenu.value && (
                          <img style={{ marginLeft: -21, marginRight: 5 }} src={mark} alt="" />
                        )}
                        <Typography color="inherit">{subMenu.key}&nbsp;</Typography>
                        <Typography color={subMenu.enabled ? 'textSecondary' : 'inherit'}>
                          {getTextRatingAccrodingNumberRating(Number(subMenu.key) as any)}
                        </Typography>
                      </MenuItem>
                    ))}
                  </ul>
                </div>
              )}
            </MenuItem>
          ))}
        </ul>
      </Box>
    </ClickAwayListener>
  )
}

export default RateStatusButtonMenu
