import React, { CSSProperties } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { useDispatch } from 'store'

import StatisticsActions from 'store/statistics/actions'

import {
  Team,
  FAQ,
  FeedbackPrinciples,
  Values,
  Steps,
  WaysFeedback,
  DetailedStatistics,
  Statistics,
  Header,
} from './sections'
import SideMenu from './components/SideMenu'

export const rootStyles: CSSProperties = {
  // maxWidth: 'fit-content',
  maxWidth: '100vw',
}

const Landing: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch()
  // const isLoading = useSelector(({ statistics }) => statistics.get('isLoading'))

  const scrollAccordingHistoryHash = () => {
    const hash = history.location.hash.substr(1).split('&')[0] // в режиме локальной разработки к хешу прикрепляется доп. символы, начинающиеся с &, отбрасываем
    const el = document.getElementById(hash)
    if (hash && el) el.scrollIntoView({ block: 'start', inline: 'start' })
  }

  React.useEffect(() => {
    scrollAccordingHistoryHash()
    dispatch(StatisticsActions.getStatistics())
  }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  // если во время загрузки отображать loader, то useEffect отрабатывает не правильно, т.к. на странице в DOM отсутствуют нужные элементы с id
  // if (isLoading) {
  //   //TODO: need loader
  //   return <div></div>
  // }

  return (
    <section style={{ backgroundColor: '#fff' }}>
      <SideMenu />
      <Header />
      <Statistics />
      <DetailedStatistics />
      <WaysFeedback />
      <Steps />
      <Values />
      <FeedbackPrinciples />
      <FAQ />
      <Team />
    </section>
  )
}

export default withRouter(Landing)
