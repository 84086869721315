import React from 'react'
import moment from 'moment'

import { Button, Flex, Text } from '@ocs.lab/ui'
import { DialogProps } from '@ocs.lab/ui/lib/components/elements/Dialog'
import { LAST_CHILD, FIRST_CHILD, HOVER } from '@ocs.lab/ui/lib/theme/helpers'

import { makeStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import { IEmployeeFeedbackItem } from 'models/employee/feedback'
import DescriptionItem from '../../../Reports/components/ReportLineItem/components/DescriptionItem'

const useStyles = makeStyles((theme) => ({
  container: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    [LAST_CHILD]: {
      borderBottom: 'none',
    },
    [FIRST_CHILD]: {
      borderTop: 'none',
    },
    [HOVER]: {
      backgroundColor: theme.palette.text.hint,
    },
  },
  giveFeedbackButton: {
    padding: 0,
    [HOVER]: {
      backgroundColor: 'transparent',
    },
  },
}))

type Props = {
  data: IEmployeeFeedbackItem
  onOpen: (scrollType: DialogProps['scroll']) => void
}

//TODO: рассмотреть возможность слияния этого компонента в ReportLineItem, для создания одного, возможно с помощью Builder паттерна
const FeedbackItem: React.FC<Props> = ({ data, onOpen }) => {
  const { container, giveFeedbackButton } = useStyles()

  return (
    <TableRow className={container}>
      <TableCell>
        <Flex>
          <Text>{data.serviceTypeTitle}</Text>
        </Flex>
      </TableCell>
      <TableCell>
        {data.service?.descriptions?.map((description, index) => (
          <DescriptionItem key={index} index={index} description={description} />
        ))}
      </TableCell>
      <TableCell>
        <Text color="textSecondary">{moment(data.provisionDate).format('DD.MM.YYYY')}</Text>
      </TableCell>
      <TableCell style={{ width: 350 }}>
        <Flex justifyContent="flex-end">
          <Flex alignItems="center" mr={20}>
            <Button
              className={giveFeedbackButton}
              variant="text"
              color="primary"
              onClick={() => {
                onOpen('body')
              }}
            >
              Дать обратную связь
            </Button>
          </Flex>
        </Flex>
      </TableCell>
    </TableRow>
  )
}

export default FeedbackItem
