import React from 'react'

import { makeStyles, Popper, useTheme } from '@material-ui/core'
import { usePopupState, bindHover, bindPopper } from 'material-ui-popup-state/hooks'

import { Avatar, Box, Flex, Typography } from '@ocs.lab/ui'
import { convertHex } from '@ocs.lab/ui/lib/theme/utils'

import { IEmployee } from 'models/champions'
import { declensions } from 'utils'
import LikeButton from '../LikesButton'

export type Like = {
  uid: string
  name: string
  position: string
}

export const formLikesCountString = (count: number) => {
  return `Понравилось ${count} ${declensions(count, ['сотруднику', 'сотрудникам', 'сотрудникам'])}:`
}

const useStyles = makeStyles(({ palette }) => ({
  paper: {
    marginBottom: 10,
    cursor: 'pointer',
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      right: 15,
      width: 10,
      height: 10,
      borderTopRightRadius: 3,
      backgroundColor: convertHex(palette.text.primary, 0.8),
      transform: 'translate(-50%, 50%) rotate(135deg)',
      clipPath: 'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))',
    },
  },
}))

type LikesPreviewProps = {
  isActive: boolean
  likes?: IEmployee[]
  data: IEmployee[]
  onClick?: (data: any) => void
  onToggleLike?: (value: boolean) => void
}

export const LikesPreview: React.FC<LikesPreviewProps> = ({
  isActive,
  data,
  onClick = () => {},
  onToggleLike = () => {},
}) => {
  const { palette } = useTheme()
  const DropdownMenuState = usePopupState({ variant: 'popper', popupId: 'likes' })

  const classes = useStyles()

  // cause of bug in backend we receive items with same uids
  const arr = data.slice(0, 5)

  return (
    <Box {...bindHover(DropdownMenuState)}>
      <LikeButton
        count={data.length}
        isActive={isActive}
        onClick={() => {
          onToggleLike(!isActive)
        }}
      />
      {data.length > 0 && (
        <Popper {...bindPopper(DropdownMenuState)} placement="top-end" className={classes.paper}>
          <Box
            py={8}
            px={16}
            borderRadius={8}
            bgcolor={convertHex(palette.text.primary, 0.8)}
            onClick={() => onClick(data)}
          >
            <Typography variant="subtitle2" color="secondary">
              {formLikesCountString(data.length)}
            </Typography>
            <Flex gridGap={2}>
              {arr.map((like) => (
                <Avatar
                  key={like.person.uid}
                  size="small"
                  variant="rounded"
                  src={like.person.avatarUrl}
                  alt={like.person.displayName}
                />
              ))}
            </Flex>
          </Box>
        </Popper>
      )}
    </Box>
  )
}

export default LikesPreview
