import { call, put, takeLatest } from 'redux-saga/effects'

import MyActivitiesActions, { GET_EMPLOYEE_FEEDBACK, GET_FEEDBACK_COUNT } from './actions'
import MyActivitiesApi from 'api/employee'

import { IEmployeeFeedbackReport } from 'models/employee/feedback'

function* getEmployeeFeedbackFlow(action: ReturnType<typeof MyActivitiesActions.getEmployeeFeedback>) {
  try {
    const { data, status }: { data: IEmployeeFeedbackReport, status: number } = yield call(MyActivitiesApi.getEmployeeFeedback, action.payload.provisionDateSortOrder, action.payload.serviceTypeId?.toString())
    yield put(MyActivitiesActions.getEmployeeFeedbackSuccess(data, status))
  } catch (err) {
    console.error(err.response)
    yield put(MyActivitiesActions.getEmployeeFeedbackFail(err))
  }
}

function* getFeedbackCountFlow(actions: ReturnType<typeof MyActivitiesActions.getFeedbackCount>) {
  try {
    const { data }: { data: number } = yield call(MyActivitiesApi.getFeedbackCount)
    yield put(MyActivitiesActions.getFeedbackCountSuccess(data))
  } catch (err) {
    console.error(err.response)
    yield put(MyActivitiesActions.getFeedbackCountFail(err))
  }
}

export default function* watchFeedback() {
  yield takeLatest(GET_EMPLOYEE_FEEDBACK, getEmployeeFeedbackFlow)
  yield takeLatest(GET_FEEDBACK_COUNT, getFeedbackCountFlow)
}
