import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Flex, Text } from '@ocs.lab/ui'

import ActionFrame from './components/ActionFrame'

import Background from 'assets/images/fb-banner-main.svg'
import './styles.css'

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 40,
    color: '#fff',
    fontWeight: 700,
    letterSpacing: 1.2,
    lineHeight: 1.2,
    fontFamily: 'Ubuntu',
    textTransform: 'uppercase',
  },
  subTitle: {
    color: '#fff',
  },
}))

const Header = () => {
  const { title, subTitle } = useStyles()
  return (
    <Box>
      {/* //TODO: create global const for maxWidth (1600px) */}
      <Box maxWidth={1600} margin="auto" pt={16}>
        <Flex
          id="about"
          style={{ backgroundImage: `url(${Background})` }}
          mx={24}
          py={65}
          px={155}
          alignItems="center"
          justifyContent="space-between"
          borderRadius={16}
        >
          <Box color="#fff">
            <Text component="h1" className={title}>
              Feedback OCS —
            </Text>
            <Text component="h1" className={title}>
              обмен
              <Box className="slidingVertical">
                <Text component="span" className={title}>
                  конструктивной
                </Text>
                <Text component="span" className={title}>
                  ясной
                </Text>
                <Text component="span" className={title}>
                  своевременной
                </Text>
                <Text component="span" className={title}>
                  дружелюбной
                </Text>
              </Box>
            </Text>
            <Text component="h1" className={title}>
              обратной связью{' '}
            </Text>
            <Text variant="h3" className={subTitle}>
              между коллегами{' '}
            </Text>
          </Box>
          <ActionFrame />
        </Flex>
      </Box>
    </Box>
  )
}

export default Header
