import { BasePaginatedActionNames, createBasePaginatedActionNames } from "../paginatedListFactory/actionNames"

export type PaginatedWithLikesActionNames = BasePaginatedActionNames & {
  TOGGLE_PUBLIC_COMMENT_LIKE: string,
  TOGGLE_PUBLIC_COMMENT_LIKE_SUCCESS: string,
  TOGGLE_PUBLIC_COMMENT_LIKE_FAIL: string
}

export const createPaginatedWithLikesActionNames = (modelName: string): PaginatedWithLikesActionNames => {
  return {
    ...createBasePaginatedActionNames(modelName),
    TOGGLE_PUBLIC_COMMENT_LIKE: `TOGGLE_PUBLIC_COMMENT_LIKE_${modelName}`,
    TOGGLE_PUBLIC_COMMENT_LIKE_SUCCESS: `TOGGLE_PUBLIC_COMMENT_LIKE_${modelName}_SUCCESS`,
    TOGGLE_PUBLIC_COMMENT_LIKE_FAIL: `TOGGLE_PUBLIC_COMMENT_LIKE_${modelName}_FAIL`,
  }
}

