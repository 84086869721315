import { call, put, takeLatest, ForkEffect } from 'redux-saga/effects'

import { IRequiredFilters } from 'models'
import { ModelName } from '../paginatedListFactory'
import { PaginatedWithLikesActions } from './actions'
import { PaginatedWithLikesActionNames } from './actionNames'
import { createPaginatedListSagas } from '../paginatedListFactory/sagas'
import { AxiosPromise } from 'axios'
import { ITopExecutorReportItem } from 'models/champions'

/**
 * Описание API-методов бэка, используемых в сагах
 */
export interface SagasApiActions<DATA, FILTER extends IRequiredFilters> {
  getData(filter: FILTER): AxiosPromise<DATA>
  togglePublicCommentLike(value: boolean, voteId: number): AxiosPromise<ITopExecutorReportItem>
}

/**
* Like/Unlike public comment
*/
function togglePublicCommentLike<DATA, FILTER extends IRequiredFilters>(
  _: ModelName = 'reports',
  actions: PaginatedWithLikesActions<DATA, FILTER>,
  getData: SagasApiActions<DATA, FILTER>['togglePublicCommentLike']
) {
  return function* togglePublicCommentLikeFlow(action: ReturnType<typeof actions.togglePublicCommentLike>) {
    try {
      const { value, voteId } = action.payload
      const { data } = yield call(getData, value, voteId)
      yield put(actions.togglePublicCommentLikeSuccess(voteId, data))
    } catch (err) {
      console.error(err.response)
      yield put(actions.getDataFail(err))
    }
  }

}

export const createPaginatedListWithLikesSagas = <DATA, FILTER extends IRequiredFilters>(
  modelName: ModelName,
  { TOGGLE_PUBLIC_COMMENT_LIKE, ...rest }: PaginatedWithLikesActionNames,
  actions: PaginatedWithLikesActions<DATA, FILTER>,
  api: SagasApiActions<DATA, FILTER>,
  extraSagas: ForkEffect[] = []
) => {
  return function* watch() {
    yield createPaginatedListSagas<DATA, FILTER>(modelName, rest, actions, api.getData, [
      takeLatest(TOGGLE_PUBLIC_COMMENT_LIKE, togglePublicCommentLike(modelName, actions, api.togglePublicCommentLike)),
      ...extraSagas
    ])()
  }
}