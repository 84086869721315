import React, { useState } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'store'

import { makeStyles } from '@material-ui/core/styles'

import { Avatar, Box, Button, Dialog, Flex, FlexFill, FlexColumn, TextField, Text, Tooltip } from '@ocs.lab/ui'
// import Text from '@material-ui/core/Typography'
import { LAST_CHILD, FIRST_CHILD, HOVER } from '@ocs.lab/ui/lib/theme/helpers'

import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import { IServiceReportItem } from 'models/reports'
import { IEmployeeActivityItem } from 'models/employee/activities'
import RatingStatus from 'components/RatingStatus'
import Comment from './components/Comment'
import DescriptionItem from './components/DescriptionItem'
import { reports } from 'store/reports'

const EXECUTORS_TYPES = {
  Employee: 'Employee',
  Department: 'Department',
}

const useStyles = makeStyles(({ palette }) => ({
  container: {
    borderBottom: `1px solid ${palette.divider}`,
    cursor: 'pointer',
    [LAST_CHILD]: {
      borderBottom: 'none',
    },
    [FIRST_CHILD]: {
      borderTop: 'none',
    },
  },
  closeCommentModalIcon: {
    [HOVER]: {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  departmentText: {
    width: 'fit-content',
    maxWidth: 160,
    backgroundColor: palette.background.default,
    padding: '8px 10px',
    borderRadius: 8,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  serviceTypeTitleCell: {
    width: '13%',
  },
  executorsCell: {
    width: '15%',
  },
  clientsCell: {
    width: '17%',
  },
  dateCell: {
    width: '8%',
  },
  descriptionsCell: {
    width: '20%',
  },
  departmentCell: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
  },
  commentsCell: {
    width: '15%',
  },
  writeCommentContainer: {
    // FIXME: fixed width for this block to be equal with Comment component width
    width: 244,
    margin: '8px 0',
  },
  avatar: {
    [HOVER]: {
      borderColor: palette.primary.main,
    },
  },
}))

type Props = {
  data: IServiceReportItem | IEmployeeActivityItem
  onClick: (serviceId: number) => void
  onEmployeeInfoOpen?: (userId: string) => void
}

//TODO: данный компонент надо перенести в компоненты таблицы, так-как он используется и для activities тоже
const ReportLineItem: React.FC<Props> = ({ data, onClick, onEmployeeInfoOpen }) => {
  const {
    container,
    departmentText,
    serviceTypeTitleCell,
    dateCell,
    descriptionsCell,
    executorsCell,
    clientsCell,
    departmentCell,
    commentsCell,
    writeCommentContainer,
    avatar,
  } = useStyles()
  // const { serviceTypeTitleCell, descriptionsCell, departmentCell, commentsCell } = useStyles()
  const dispatch = useDispatch()

  //TODO: fix perfomance if higlight current selected Report item (now rerendering all items in table evry time while selected)
  // const selectedServiceId = useSelector(({ serviceSummary }) => serviceSummary.get('data')?.serviceId)

  const currentUser = useSelector(({ user }) => user.get('user')?.person)

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false)

  const currentUserComment = (data as IServiceReportItem).serviceComments?.find(
    (comment) => comment.employeeUid === currentUser?.uid
  )

  const [comment, setComment] = useState('')

  const {
    service,
    // department,
    departments,
    clients,
    executors,
    executorsType,
    serviceTypeTitle,
    provisionDate,
    serviceComments,
    //FIXME: разрулить проблему с типами, тут могут приходить два типа IServiceReportItem | IEmployeeActivityItem, возможно стоит рассплитить компоненты
    // либо применить паттерн Builder для конструирования нужного элемента в таблице
    // сделано на скорую руку, нужно срочно зарефакторить
  } = data as IServiceReportItem

  const isActive = service.serviceTypeStatus === 'Active' ? '#57CF1F' : '#FFC163'

  const handleClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) =>
    //NOTE: check for not open Drawer after close comment modal
    //NOTE: idk why stopPropagation() in "writeComment" func doesnt work correctly (propably cuze setModalData)
    !isCommentModalOpen && onClick(data.service.serviceId)

  const handleEmployeeInfoOpen = (uid: string) => !isCommentModalOpen && onEmployeeInfoOpen && onEmployeeInfoOpen(uid)

  const writeComment = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // dispatch(ReportActions.createManagerComment(service.serviceId, comment))
    dispatch(reports.actions.createManagerComment(service.serviceId, comment))
    setIsCommentModalOpen(false)
  }

  const onChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setComment(e.currentTarget.value)

  const handleOpenCommentModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    //NOTE: if user want to edit comment
    setComment(currentUserComment?.content || '')
    setIsCommentModalOpen(true)
  }

  const handleCloseCommentModal = () => {
    setIsCommentModalOpen(false)
    setComment(currentUserComment?.content || comment)
  }

  return (
    <TableRow className={container} hover onClick={handleClick}>
      {serviceTypeTitle && (
        <TableCell className={serviceTypeTitleCell}>
          <Flex>
            <Box height={12} width={12} minWidth={12} bgcolor={isActive} borderRadius={6} mr={10} mt={4} />
            <Text>{serviceTypeTitle}</Text>
          </Flex>
        </TableCell>
      )}
      <TableCell className={descriptionsCell}>
        {service?.descriptions?.map((description, index) => (
          <DescriptionItem key={index} index={index} description={description} />
        ))}
      </TableCell>
      {/* {department && (
        <TableCell className={departmentCell}>
          <Tooltip title={department.title} arrow placement="bottom">
            <Text component="a" href="#" className={departmentText}>
              {department.title}
            </Text>
          </Tooltip>
        </TableCell>
      )} */}
      {departments && (
        <TableCell className={departmentCell}>
          {departments
            ?.filter((d) => !!d.title)
            .map((department) => (
              <Tooltip key={department.departmentUid} title={department.title} arrow placement="bottom">
                <Text component="a" href="#" className={departmentText}>
                  {department.title}
                </Text>
              </Tooltip>
            ))}
        </TableCell>
      )}
      {provisionDate && (
        <TableCell className={dateCell}>
          <Text color="textSecondary">{moment(provisionDate).format('DD.MM.YYYY')}</Text>
        </TableCell>
      )}
      {executors && (
        <TableCell className={executorsCell}>
          <Flex flexWrap="wrap">
            {executors?.map(({ uid, displayName, avatarUrl }) => (
              /* TODO: need to create string constant */
              <Tooltip key={uid} title={displayName || 'Сотрудник больше не работает в компании'} arrow placement="top">
                <Avatar
                  size="small"
                  variant="rounded"
                  className={avatar}
                  alt={displayName}
                  src={avatarUrl}
                  onClick={(e) => {
                    e.stopPropagation()
                    if (executorsType !== EXECUTORS_TYPES.Department) {
                      handleEmployeeInfoOpen(uid)
                    }
                  }}
                />
              </Tooltip>
            ))}
          </Flex>
        </TableCell>
      )}
      {clients && (
        <TableCell className={clientsCell}>
          <Flex flexWrap="wrap" gridRowGap={3}>
            {clients?.map((voteSummary) => (
              <FlexColumn key={voteSummary.client.person.uid} bgcolor="#ffffff" mr={4} borderRadius={8}>
                {/* TODO: need to create string constant */}
                <Tooltip
                  key={voteSummary.client.person.uid}
                  title={voteSummary.client.person.displayName || 'Сотрудник больше не работает в компании'}
                  arrow
                  placement="top"
                >
                  <Avatar
                    size="small"
                    variant="rounded"
                    className={avatar}
                    alt={voteSummary.client.person.displayName}
                    src={voteSummary.client.person.avatarUrl}
                    onClick={(e) => {
                      e.stopPropagation()
                      if (voteSummary.client.person.displayName) handleEmployeeInfoOpen(voteSummary.client.person.uid)
                    }}
                  />
                </Tooltip>
                <RatingStatus voteSummary={voteSummary} placement="bottom" />
              </FlexColumn>
            ))}
          </Flex>
        </TableCell>
      )}
      {serviceComments && (
        <TableCell className={commentsCell}>
          {currentUserComment ? null : (
            <Flex className={writeCommentContainer}>
              <Button variant="text" color="primary" onClick={handleOpenCommentModal} style={{ padding: 0 }}>
                Добавить заметку
              </Button>
            </Flex>
          )}
          {serviceComments?.map((comment) => (
            <Comment
              key={comment.employeeUid}
              serviceid={service.serviceId}
              comment={comment}
              handleOpenCommentModal={handleOpenCommentModal}
            />
          ))}
        </TableCell>
      )}
      {/* TODO: propably need to isolate Dialog component  */}
      {/* FIXME: BAD PERFOMANCE because of this component */}
      <Dialog isOpen={isCommentModalOpen} scroll="body" onClose={handleCloseCommentModal}>
        <Box width={548} bgcolor="#fff" position="relative">
          <TextField
            value={comment}
            label="Заметка"
            variant="filled"
            placeholder="Введите текст"
            onChange={onChangeComment}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{ shrink: true }}
            multiline
            fullWidth
            autoFocus
          />
          <FlexFill mt={16} justifyContent="flex-end">
            <Box mr={6}>
              <Button variant="text" onClick={handleCloseCommentModal} color="primary">
                Отменить
              </Button>
            </Box>
            <Button disabled={comment.trim().length < 1} variant="contained" onClick={writeComment} color="primary">
              Сохранить
            </Button>
          </FlexFill>
        </Box>
      </Dialog>
    </TableRow>
  )
}

export default React.memo(ReportLineItem)
