export const TEXT_RATING = {
  1: 'Плохо',
  2: 'Есть проблемы',
  3: 'Нормально',
  4: 'Хорошо',
  5: 'Отлично',
}

export type IButtonRating = {
  title: string | JSX.Element
  rating: number
  rgbColorGradients: [string, string]
}

export const RATE_BUTTONS_SETUP: IButtonRating[] = [
  {
    title: TEXT_RATING[1],
    rating: 1,
    rgbColorGradients: ['255,16,31', '255,16,31'],
  },
  {
    title: TEXT_RATING[2],
    rating: 2,
    rgbColorGradients: ['255,16,31', '255,193,99'],
  },
  {
    title: TEXT_RATING[3],
    rating: 3,
    rgbColorGradients: ['255,193,99', '255,193,99'],
  },
  {
    title: TEXT_RATING[4],
    rating: 4,
    rgbColorGradients: ['255,193,99', '87,207,31'],
  },
  {
    title: TEXT_RATING[5],
    rating: 5,
    rgbColorGradients: ['87,207,31', '87,207,31'],
  },
]

export const PLACEHOLDERS: { [key: number]: string } = {
  0: '',
  1: 'Пожалуйста, опиши что именно пошло не так. Подробный комментарий поможет руководителю оперативно устранить мелкие недочеты в работе команды.',
  2: 'Пожалуйста, опиши что именно пошло не так. Подробный комментарий поможет руководителю оперативно устранить мелкие недочеты в работе команды.',
  3: 'Комментарий необязательный, но если у тебя есть идеи, предложения или замечания, пожалуйста, напиши об этом.',
  4: 'Комментарий необязательный, но если у тебя есть идеи, предложения или замечания, пожалуйста, напиши об этом.',
  5: 'Комментарий необязателен, но если есть что сказать, пожалуйста, пиши подробно. Так ты поможешь руководителю лучше понять ситуацию и при случае похвалить своего сотрудника.',
}

export const TOOLTIP_TEXT = {
  active:
    'Важно, чтобы обратная связь была оперативной. Это позволит руководителю быстро оптимизировать работу своей команды, если что-то пошло не так. Поэтому мы предлагаем дать фидбек в течение 24 часов (с момента получения письма) и получить 1 лав за оперативность. Лавы можно потратить в магазинчике Осипа.',
  // feedback_info:
  //   'За обратной связью в адрес своих сотрудников в первую очередь следит руководитель. Также фидбек увидят наши HR-специалисты и бизнес-аналитики.',
  public_feedback:
    'Публичный фидбек смогут увидеть все сотрудники компании в разделе «Наши чемпионы», а твой коллега получит 1 лав. Эту настройку можно отключить, в этом случае, фидбек увидит только руководитель, HR-специалист и бизнес-аналитик.',
}

export const TITLE_VARIANTS = {
  after_vote: 'Спасибо за фидбек!',
  voted: 'Обратная связь уже получена!',
}
export const SUBTITLE_VARIANTS = {
  with_rewarded_and_comment: 'Особенно за оперативность и комментарий',
  with_comment: 'Особенно за  комментарий',
  with_rewarded: 'Особенно за оперативность',
}
