import { call, all, put, takeLatest, SagaReturnType } from 'redux-saga/effects'

import StatisticsActions, { GET_STATISTICS } from './actions'
import StatisticsApi from 'api/statistics'
import ReportsApi from 'api/reports'

type Response = [
  SagaReturnType<typeof StatisticsApi.getTotalVotedClientsCount>,
  SagaReturnType<typeof StatisticsApi.getTotalVotedClientsCount>,
  SagaReturnType<typeof StatisticsApi.getTotalVotedClientsCount>,
  // SagaReturnType<typeof ReportsApi.getVotesInfoByDepartments>,
  SagaReturnType<typeof ReportsApi.getVotesInfoByServiceTypes>
]

function* getStatisticsFlow(action: ReturnType<typeof StatisticsActions.getStatistics>) {
  try {
    const response: Response = yield all([
      call(StatisticsApi.getTotalVotedVotesCount),
      call(StatisticsApi.getTotalVotedVotesCount, true),
      call(StatisticsApi.getTotalVotedClientsCount),
      // call(ReportsApi.getVotesInfoByDepartments),
      call(ReportsApi.getVotesInfoByServiceTypes),
    ])
    yield put(StatisticsActions.getStatisticsSuccess({
      totalVotedVotesCount: response[0].data,
      totalPublicVotedVotesCount: response[1].data,
      totalVotedClientsCount: response[2].data,
      // departmentsStatistic: response[3].data,
      servicesStatistic: response[3].data
    }))
  } catch (err) {
    console.error(err.response)
    yield put(StatisticsActions.getStatisticsFail(err))
  }
}

export default function* watchStatistics() {
  yield takeLatest(GET_STATISTICS, getStatisticsFlow)
}
