import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Autocomplete } from '@ocs.lab/ui'
import { IListFilterItem } from 'models'
import { filterBaseOptions } from '@ocs.lab/ui/lib/components/elements/Autocomplete/helpers'

const useStyles = makeStyles(({ palette }) => ({
  option: {
    minHeight: 48,
    '&[data-focus="true"]': {
      backgroundColor: palette.primary.light,
    },
  },
  inputBase: {
    width: 200,
  },
}))

type Props = {
  title: string
  value?: IListFilterItem
  data?: IListFilterItem[]
  onChangeValue: (value?: IListFilterItem) => any
  getOptionSelected?: (option: IListFilterItem, value: IListFilterItem) => boolean
  renderOption?: (
    option: IListFilterItem,
    state: {
      selected: boolean
      inputValue: string
    }
  ) => JSX.Element
  PaperComponent?: React.ComponentType<React.HTMLAttributes<HTMLElement>> | undefined
  disabled?: boolean
}

const BaseAutocomplete: React.FC<Props> = ({
  title,
  data = [],
  value,
  onChangeValue,
  getOptionSelected,
  PaperComponent,
  disabled = false,
}) => {
  const classes = useStyles()
  return (
    <Autocomplete
      size="small"
      title={title}
      value={value || null}
      onChange={(_, newValue: any) => onChangeValue(newValue)}
      filterOptions={filterBaseOptions}
      options={data.filter((v) => v.value)}
      classes={{ option: classes.option }}
      getOptionSelected={getOptionSelected}
      disabled={disabled}
      blurOnSelect={true}
      PaperComponent={PaperComponent}
    />
  )
}

export default BaseAutocomplete
