import { createAction, ActionsUnion } from '../../helpers/actions'
import { IServiceReportSummary } from 'models/reports'
import { IEmployeeActivitySummary } from 'models/employee/activities'

export const GET_REPORT_SERVICE_SUMMARY = 'GET_REPORT_SERVICE_SUMMARY'
export const GET_EMPLOYEE_ACTIVITY_SERVICE_SUMMARY = 'GET_EMPLOYEE_ACTIVITY_SERVICE_SUMMARY'
export const GET_SERVICE_SUMMARY_SUCCESS = 'GET_SERVICE_SUMMARY_SUCCESS'
export const GET_SERVICE_SUMMARY_FAIL = 'GET_SERVICE_SUMMARY_FAIL'

export const RESET_SERVICE_SUMMARY = 'RESET_SERVICE_SUMMARY'

const ServiceSummaryActions = {
  getReportServiceSummary: (id: number) => createAction(GET_REPORT_SERVICE_SUMMARY, id),
  getEmployeeActivityServiceSummary: (id: number) => createAction(GET_EMPLOYEE_ACTIVITY_SERVICE_SUMMARY, id),
  getServiceSummarySuccess: (data: IServiceReportSummary | IEmployeeActivitySummary) => createAction(GET_SERVICE_SUMMARY_SUCCESS, data),
  getServiceSummaryFail: (error: string | boolean) => createAction(GET_SERVICE_SUMMARY_FAIL, error),

  resetServiceSummary: () => createAction(RESET_SERVICE_SUMMARY),
}

export type ServiceSummaryTypes = ActionsUnion<typeof ServiceSummaryActions>

export default ServiceSummaryActions
