import { styled } from '@material-ui/core/styles';
import { Avatar, Flex, FlexColumn, Typography } from '@ocs.lab/ui'
import React from 'react'

const StyledText = styled(Typography)({
  lineHeight: '24px'
})

const StyledContainer = styled(FlexColumn)(({
  theme
}) => ({
  padding: "16px 24px",
  backgroundColor: theme.palette.primary.light,
  borderRadius: "0px 16px 16px 16px",
  gap: 8,
}))

type Props = {
  imageUrl: string
  text: string
  caption: string
}

const Blockqoute = ({imageUrl, text, caption}: Props) => {
  return (
    <Flex gridGap={8}>
      <Avatar src={imageUrl} variant="rounded" />
      <StyledContainer>
        <StyledText>{text}</StyledText>
        <Typography variant='subtitle2'>{caption}</Typography>
      </StyledContainer>
    </Flex>
  )
}

export default Blockqoute