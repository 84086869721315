import Api from '.'
import { IVote, IVoted } from 'models'
import { IEmployeeFeedbackItem } from 'models/employee/feedback';

class Vote {
  // TODO maybe need some jsdoc for this methods

  static getVote(id: number, key: string) {
    return Api.get<IVote>(`votes/${id}/${key}`)
  }

  static getVotes(id: string, key: string) {
    return Api.get<IEmployeeFeedbackItem[]>(`votes/employeefeedback/${id}/${key}`)
  }

  static setVote(id: number, key: string, { rating, comment, isPublicComment }: { rating: number; comment?: string, isPublicComment: boolean }) {
    return Api.post<IVoted>(`votes/${id}/${key}`, { rating, comment, isPublicComment })
  }
}

export default Vote
