import Api from '.'
import { IGratitude, IService } from 'models/services'

class Services {
  static addGratitude(gratitude: IGratitude) {
    return Api.post<IService>('/services/addgratitude', gratitude)
  }
}

export default Services
