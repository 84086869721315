import { createAction, ActionsUnion } from '../../helpers/actions'
import { IEmployeeFeedbackReport } from 'models/employee/feedback'

export const GET_EMPLOYEE_FEEDBACK = 'GET_EMPLOYEE_FEEDBACK'
export const GET_EMPLOYEE_FEEDBACK_SUCCESS = 'GET_EMPLOYEE_FEEDBACK_SUCCESS'
export const GET_EMPLOYEE_FEEDBACK_FAIL = 'GET_EMPLOYEE_FEEDBACK_FAIL'

export const GET_FEEDBACK_COUNT = 'GET_FEEDBACK_COUNT'
export const GET_FEEDBACK_COUNT_SUCCESS = 'GET_FEEDBACK_COUNT_SUCCESS'
export const GET_FEEDBACK_COUNT_FAIL = 'GET_FEEDBACK_COUNT_FAIL'

// export const DELETE_VOTED_ITEM = 'DELETE_VOTED_ITEM'

const FeedbackActions = {
  getEmployeeFeedback: (provisionDateSortOrder: 'Asc' | "Desc" = "Desc", serviceTypeId: number | null = null) => createAction(GET_EMPLOYEE_FEEDBACK, { provisionDateSortOrder, serviceTypeId }),
  getEmployeeFeedbackSuccess: (data: IEmployeeFeedbackReport, status?: number) => createAction(GET_EMPLOYEE_FEEDBACK_SUCCESS, { data, status }),
  getEmployeeFeedbackFail: (error: string | boolean) => createAction(GET_EMPLOYEE_FEEDBACK_FAIL, error),

  getFeedbackCount: () => createAction(GET_FEEDBACK_COUNT),
  getFeedbackCountSuccess: (count: number) => createAction(GET_FEEDBACK_COUNT_SUCCESS, count),
  getFeedbackCountFail: (error: string | boolean) => createAction(GET_FEEDBACK_COUNT_FAIL, error),

  // deleteVotedItem: ({ serviceId, serviceTypeId }: { serviceId: number, serviceTypeId: number }) => createAction(DELETE_VOTED_ITEM, { serviceId, serviceTypeId })
}

export type FeedbackTypes = ActionsUnion<typeof FeedbackActions>

export default FeedbackActions
