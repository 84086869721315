import React, { CSSProperties, useEffect } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { useSelector } from 'store'

import { Box } from '@ocs.lab/ui'
import { Text } from '@ocs.lab/ui'

import LinearProgress from '@material-ui/core/LinearProgress'

import { UserErrors } from 'store/keycloak'

import { IRoles } from 'models'
import { FlexCenter } from '@ocs.lab/ui'

import Header from 'components/Header'
import Footer from 'components/Footer'
import NoAccess from 'components/NoAccess'

import sso_closed from 'assets/images/get.svg'
import AuthService from 'services/Auth'

type Props = {
  header?: JSX.Element
  footer?: JSX.Element
  requiredRoles?: IRoles[]
  styles?: CSSProperties
}

//TODO: на скорую руку сделана переработка компоненета, надо еще подумать над ним
const ProtectedRoute: React.FC<RouteProps & Props> = ({
  component,
  children,
  header,
  footer,
  requiredRoles,
  styles,
  ...rest
}) => {
  const isFetching = useSelector(({ user }) => user.get('isFetching'))
  const isLoggedIn = useSelector(({ user }) => user.get('isLoggedIn'))
  const error = useSelector(({ user }) => user.get('error'))
  const user = useSelector(({ user }) => user.get('user'))

  const isUserHasRequiredRoles = () => (requiredRoles && user ? requiredRoles.includes(user.role) : true)

  useEffect(() => {
    if (!isLoggedIn && !error) {
      new AuthService()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (isFetching) {
    return <LinearProgress color="primary" />
  }

  if (error === UserErrors.NetworkError)
    return (
      <FlexCenter flexDirection="column" width="100wh" height="100vh" bgcolor="#fff">
        <Text variant="h3" align="center">
          Feedback OCS работает только <br /> в корпоративной сети
        </Text>
        <img src={sso_closed} alt="sso-closed" />
      </FlexCenter>
    )

  if (isLoggedIn)
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <Box>
              {header}
              <main style={styles}>{isUserHasRequiredRoles() ? children : <NoAccess />}</main>
              {footer}
            </Box>
          )
        }}
      />
    )
  //TODO: need add some logic
  return <div></div>
}

ProtectedRoute.defaultProps = {
  header: <Header />,
  footer: <Footer />,
}

export default ProtectedRoute
