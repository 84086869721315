import React from 'react'

import { Box, FlexColumn, Flex, Link, Text } from '@ocs.lab/ui'

type Props = {
  title: string
  subTitle?: string
  body: string
  highlightBody?: string
  href?: string
  photo: any
}

const Value: React.FC<Props> = ({ title, body, photo, highlightBody, href, subTitle }) => {
  return (
    <FlexColumn width={344} alignItems="center">
      <img src={photo} alt="no_photo" />
      <Box pt={15} pb={8}>
        <b style={{ fontSize: 20 }}>{title}</b>
      </Box>
      {body && (
        <Flex width={309} justifyContent="center">
          <Text align="center" style={{ whiteSpace: 'pre-line' }}>
            {/* <Text align="center"> */}
            {body}{' '}
            {highlightBody ? (
              <Link color="primary" underline="hover" href={href}>
                {highlightBody}
              </Link>
            ) : null}
            {/* </Text> */}
          </Text>
        </Flex>
      )}
      <Text align="center" color="textSecondary">
        {subTitle}
      </Text>
    </FlexColumn>
  )
}

export default Value
