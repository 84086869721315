/* for IE polyfills */
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import App from './App'
import store from 'store'

// import { MuiThemeProvider } from '@material-ui/core/styles'
// import Theme from './styles/theme'
// import './styles/global.css'
import { ThemeProvider } from '@ocs.lab/ui'
import { makeOverrides } from './styles/overrides'

import * as serviceWorker from './serviceWorker'
import palette from 'styles/palette'

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
})

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <ThemeProvider makeOverrides={makeOverrides} palette={palette}>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById('root')
)

serviceWorker.unregister()
