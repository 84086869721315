import React, { useState, useCallback, useEffect } from 'react'
import { debounce, add } from 'lodash'
import * as yup from 'yup'

import { makeStyles } from '@material-ui/core/styles'
import { Autocomplete, Box, Button, Flex, Text, TextField, FilledInput } from '@ocs.lab/ui'
import InputAdornment from '@material-ui/core/InputAdornment'

import {
  thanskFormSchema,
  formatErrors,
  MAX_ACTIVITY_LENGTH,
  MAX_COMMENT_LENGTH,
  MAX_EXECUTORS,
} from 'services/Validation'

import { IEmployee } from 'models/champions'
import EmployeeApi from 'api/employee'
import ServicesApi from 'api/services'
import { RenderUserOption } from '@ocs.lab/ui/lib/components/elements/Autocomplete/options/RenderUserOption'
import { filterUserOptions } from '@ocs.lab/ui/lib/components/elements/Autocomplete/helpers'
import { IListFilterItem } from 'models'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  activity_root: {
    backgroundColor: ({ isSelected }: any) =>
      isSelected ? theme.palette.primary.light : theme.palette.background.default,
    padding: '8px 12px',
    marginTop: 8,
    marginRight: 8,
    borderRadius: 8,
    cursor: 'pointer',
    '& > p': {
      color: ({ isSelected }: any) => (isSelected ? theme.palette.primary.contrastText : theme.palette.text.primary),
    },
  },
  comment_textField: {
    padding: '12px 16px',
  },
}))

// https://jira.inside/browse/RATE-1481
const BEST_ACTIVITY_OPTION_START_DATE = new Date(Date.UTC(2023, 1, 13, 21, 0, 0))
const BEST_ACTIVITY_OPTION_END_DATE = new Date(Date.UTC(2023, 1, 15, 7, 0, 0))

enum ACTIVITIES {
  SolvingProblem = 'Помог решить проблему',
  CargoDelivery = 'Доставил груз',
  ValuableAdvice = 'Дал ценный совет',
  EquipmentSelection = 'Подобрал оборудование',
  DocumentsPreparation = 'Подготовил документы',
  Orange = 'Поднял апельсин',
  Best = 'Лучшие!',
}
const PAGE_SIZE = 15
const MINIMUM_CHARS_FOR_SEARCH = 2

type PropsActivity = {
  title: string
  onSelectActivity: (e: string) => void
  isSelected: boolean
}

const Activity: React.FC<PropsActivity> = ({ title, onSelectActivity, isSelected }) => {
  const { activity_root } = useStyles({ isSelected })
  return (
    <Flex className={activity_root} onClick={() => onSelectActivity(title)}>
      <Text>{title}</Text>
    </Flex>
  )
}

type Props = {
  onClose: () => void
  showSnackbarOnComplete: () => void
}
//TODO: need refactoring
//FIXME: много useState, нужен useReducer
//TODO: need refactoring styles and etc...,
//FIXME: опять же сделан на скорую руку (а иногда и ногу), надо переосмыслить флоу и отрефакторить
const ThankForm: React.FC<Props> = ({ showSnackbarOnComplete, onClose }) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<IListFilterItem<IEmployee>[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState<number>()
  const [executors, setExecutors] = useState<IListFilterItem<IEmployee>[]>([])
  const [activity, setActivity] = useState('')
  // const [isSayThx, setIsSayThx] = useState(false)
  const isSayThx = false
  const [comment, setComment] = useState<string>('')
  const [isExecutorsLoading, setIsExecutorsLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [errors, setErrors] = useState({ executors: false, activity: false, comment: false })

  const [sending, setSending] = useState(false)

  const isValidComment = comment.length <= MAX_COMMENT_LENGTH
  const isValidActivity = activity.length <= MAX_ACTIVITY_LENGTH
  const isValidExecutorsCount = executors.length <= MAX_EXECUTORS

  const loading = open && inputValue.length > MINIMUM_CHARS_FOR_SEARCH && isExecutorsLoading

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedFindEmployee = useCallback(
    debounce(async (searchPattern: string) => {
      setIsExecutorsLoading(true)
      try {
        const { data } = await EmployeeApi.findEmployee(searchPattern, { pageIndex: currentPage, pageSize: PAGE_SIZE })
        if (currentPage === 1) {
          setOptions(
            data.data.map((item, index) => ({
              key: index.toString(),
              enabled: true,
              selected: false,
              value: item,
            }))
          )
        } else {
          setOptions([
            ...options,
            ...data.data.map((item, index) => ({
              key: index.toString(),
              enabled: true,
              selected: false,
              value: item,
            })),
          ])
        }
        setTotalCount(data.totalCount)
      } catch (error) {}
      setIsExecutorsLoading(false)
    }, 200),
    [currentPage]
  )

  useEffect(() => {
    setActivity('')
    setErrors({ ...errors, activity: false })
  }, [isSayThx]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setOptions([])
    setCurrentPage(1)
    setErrors({ ...errors, executors: false })
  }, [executors]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setErrors({ ...errors, executors: false })
  }, [inputValue]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setErrors({ ...errors, activity: false })
  }, [activity]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setErrors({ ...errors, comment: false })
  }, [comment]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inputValue.length >= MINIMUM_CHARS_FOR_SEARCH) {
      delayedFindEmployee(inputValue)
    } else {
      setOptions([])
    }
  }, [inputValue, delayedFindEmployee])

  const onSelectActivity = (e: string) => setActivity(e)

  const onSendFeedback = async () => {
    setSending(true)
    thanskFormSchema
      .omit(isSayThx ? ['activity'] : [])
      .validate({ activity, executors, comment }, { abortEarly: false })
      .then(async () => {
        try {
          //TODO: возможно стоит отдельный стор сделать
          await ServicesApi.addGratitude({
            activityName: activity,
            executorsUids: executors.map((executor) => executor.value.person.uid),
            serviceComment: comment,
          })
          showSnackbarOnComplete()
        } catch (error) {
          console.error(error)
        }
        setSending(false)
        onClose()
      })
      .catch((err: yup.ValidationError) => {
        setErrors(formatErrors(err))
        setSending(false)
      })
  }

  const onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.currentTarget
    if (target.scrollHeight - target.scrollTop === target.clientHeight && totalCount) {
      const maxPage = Math.ceil(totalCount / PAGE_SIZE)
      if (maxPage > currentPage) setCurrentPage(add(currentPage, 1))
    }
  }

  const RenderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      variant="filled"
      size="small"
      error={errors.executors || !isValidExecutorsCount}
      InputProps={{
        ...params.InputProps,
        endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : undefined,
      }}
    />
  )

  const NoOptionsText = useCallback(
    () =>
      inputValue.length > MINIMUM_CHARS_FOR_SEARCH && !isExecutorsLoading && !options.length ? (
        <Text>Не найден сотрудник «{inputValue}». Проверьте имя и фамилию</Text>
      ) : (
        'Введите имя или фамилию'
      ),
    [inputValue, isExecutorsLoading, options]
  )

  return (
    <Box width={700} padding={8}>
      <Flex pb={8}>
        <Text>Выбери коллегу</Text>&nbsp;
        <Text color="textSecondary">одного или нескольких</Text>
      </Flex>
      <Flex position="relative" alignItems="center">
        <Autocomplete
          multiple
          open={open && inputValue.length > MINIMUM_CHARS_FOR_SEARCH}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={(_, newValue) => setExecutors(newValue)}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => {
            //TODO: FIXME:
            setCurrentPage(1)
            setInputValue(newInputValue.trimStart())
          }}
          filterOptions={filterUserOptions}
          filterSelectedOptions
          options={options}
          renderOption={RenderUserOption}
          getOptionLabel={(option) => option.value.person.displayName!}
          getOptionSelected={(option, value) => option.value.person.uid === value.value.person.uid}
          noOptionsText={NoOptionsText()}
          fullWidth
          clearOnBlur={false}
          openOnFocus
          loading={loading}
          ListboxProps={{ onScroll }}
          closeIcon={null}
          renderInput={RenderInput}
        />
        <Flex position="absolute" right={14}>
          <Text variant="subtitle2" color={errors.executors || !isValidExecutorsCount ? 'error' : 'textSecondary'}>
            {MAX_EXECUTORS - executors.length}
          </Text>
        </Flex>
      </Flex>
      <Box pb={24}>
        <Flex pb={8} pt={24}>
          <Text>Укажи активность коллеги, </Text>&nbsp;
          <Text color="textSecondary"> за которую ты хочешь его поблагодарить</Text>
        </Flex>
        <TextField
          variant="filled"
          value={activity}
          onChange={(e) => setActivity(e.currentTarget.value.trimStart())}
          size="small"
          fullWidth
          error={!!errors.activity || !isValidActivity}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Text variant="subtitle2" color={errors.activity || !isValidActivity ? 'error' : 'textSecondary'}>
                  {MAX_ACTIVITY_LENGTH - activity.length}
                </Text>
              </InputAdornment>
            ),
          }}
        />
        <br />
        <br />
        <Text color="textSecondary">Например:</Text>
        <Flex flexWrap="wrap">
          {Object.values(ACTIVITIES).map((activityTitle) => {
            if (activityTitle === ACTIVITIES.Best) {
              if (!moment().isBetween(moment(BEST_ACTIVITY_OPTION_START_DATE), moment(BEST_ACTIVITY_OPTION_END_DATE))) {
                return null
              }
            }

            return (
              <Activity
                key={activityTitle}
                title={activityTitle}
                onSelectActivity={onSelectActivity}
                isSelected={activityTitle === activity}
              />
            )
          })}
        </Flex>
      </Box>
      {/* <Flex alignItems="center" pb={24} ml={-10}>
        <Checkbox onChange={toggleCheckboxJustSayThx} value={isSayThx} disableRipple disableTouchRipple disableFocusRipple color="primary" />
        <Text>Просто хочу сказать спасибо</Text>
      </Flex> */}
      <Text>Напиши комментарий</Text>
      <Box position="relative" pt={8}>
        <FilledInput
          value={comment}
          error={!!errors.comment || !isValidComment}
          onChange={(e) => setComment(e.currentTarget.value.trimStart())}
          rows={5}
          style={{ padding: '12px 16px' }}
          multiline
          fullWidth
        />
        <Box position="absolute" top={10} right={16}>
          <Text color={errors.comment || !isValidComment ? 'error' : 'textSecondary'} variant="subtitle2">
            {MAX_COMMENT_LENGTH - comment.length}
          </Text>
        </Box>
        <Flex pt={24} justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={onSendFeedback} disabled={sending}>
            Отправить
          </Button>
        </Flex>
      </Box>
    </Box>
  )
}

export default ThankForm
