import React from 'react'
import { Record } from 'immutable'

import { Box, Pagination, Backdrop, FlexFill, Paper } from '@ocs.lab/ui'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'

import { IBaseFilters, IBaseReport, IRequiredFilters, ServiceReportSortableColumnsISortingSettings } from 'models'
import { IBasePaginateState } from 'store/helpers/paginatedListFactory/reducer'

import SelectPageSize from '@ocs.lab/ui/lib/components/table/SelectPageSize'
import TableHead from '../TableHead'
import { ModelName } from 'store/helpers/paginatedListFactory'
import UserSettings from 'services/UserSettings'
import { BasePaginatedActions } from 'store/helpers/paginatedListFactory/actions'

export type ITableCaption = { key: string; title: string; sortKey?: string }

const PAGE_SIZES = [10, 25, 50, 100]

type Props<T extends IBaseReport<IRequiredFilters>, I> = {
  modelName: ModelName
  state: Record<IBasePaginateState<T, any>>
  captions: ITableCaption[]
  withPageSize: boolean
  //TODO: use generic
  getData: BasePaginatedActions<T, IBaseFilters>['getData']
  // getData: ({ sorting }: { sorting: ServiceReportSortableColumnsISortingSettings }) => void
  //TODO: use generic
  changePage: BasePaginatedActions<T, IBaseFilters>['changePaging']
  // changePage: ({ pageIndex }: { pageIndex: number }) => void
  renderItem: (item: I) => JSX.Element
  changePageSize?: (pageSize: number) => void
}

type State = {}

//TODO: NEED REFACTORING
class TableLayout<T extends IBaseReport<IRequiredFilters>, I> extends React.Component<Props<T, I>, State> {
  onChangePage = (page: number) => this.props.changePage?.({ pageIndex: page })

  onChangeSort = (sorting: ServiceReportSortableColumnsISortingSettings) => this.props.getData?.({ sorting })

  render() {
    const { modelName, state, captions, renderItem } = this.props
    const currentSortType = state
      .get('currentFilter')
      ?.getIn(['sorting']) as ServiceReportSortableColumnsISortingSettings
    const lastPage = state.get('data')?.data?.totalCount! / state.get('data')?.filter?.paging.pageSize!
    return (
      <Box px={24} pb={24} borderRadius={16}>
        <Backdrop isLoading={state.get('isLoading')}>
          <TableContainer component={Paper}>
            <Table style={{ minWidth: 650 }}>
              <TableHead captions={captions} currentSortType={currentSortType} onChangeSort={this.onChangeSort} />
              <TableBody>{state.get('data')?.data.data.map(renderItem)}</TableBody>
            </Table>
          </TableContainer>
        </Backdrop>
        <FlexFill mt={24} justifyContent="space-between" alignItems="center">
          <Box>
            {this.props.withPageSize && (
              <SelectPageSize
                id="select-page-size"
                paging={state.get('data')?.filter?.paging}
                pageSizes={PAGE_SIZES}
                onChangePageSize={async (pageSize) => {
                  UserSettings.pageSize = { [modelName]: pageSize }
                  this.props.changePage({ pageSize })
                  await localStorage.setItem(
                    UserSettings.keyName,
                    JSON.stringify({ pageSizes: { ...UserSettings.settings.pageSizes, [modelName]: pageSize } })
                  )
                }}
              />
            )}
          </Box>
          {lastPage > 1 && (
            <Pagination
              id="pagination"
              currentPage={state.get('data')?.filter?.paging.pageIndex!}
              lastPage={Math.ceil(lastPage)}
              onChangePage={this.onChangePage}
            />
          )}
        </FlexFill>
      </Box>
    )
  }
}

export default TableLayout
