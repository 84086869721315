import { Record } from 'immutable'

import { IBaseReport, IRequiredFilters } from 'models'
import { IActionWithPayload } from '../actions'
import { createPaginatedListReducer, IBasePaginateState, initialState } from '../paginatedListFactory/reducer'
import { PaginatedWithLikesActionNames } from './actionNames'
import { togglePublicCommentLikeSuccessAction } from './actions'

export function createPaginatedListWithLikesReducer<DATA extends IBaseReport<IRequiredFilters>, FILTER extends IRequiredFilters>({ TOGGLE_PUBLIC_COMMENT_LIKE_SUCCESS, ...rest }: PaginatedWithLikesActionNames) {
  return (state: Record<IBasePaginateState<DATA, FILTER>> = initialState, action: IActionWithPayload<string, any>) => {
    switch (action.type) {
      case TOGGLE_PUBLIC_COMMENT_LIKE_SUCCESS:
        return updateItem(state, action.payload)
      default:
        return createPaginatedListReducer<DATA, FILTER>(rest)(state, action)
    }
  };
}

function updateItem<DATA extends IBaseReport<IRequiredFilters>, FILTER extends IRequiredFilters>(state: Record<IBasePaginateState<DATA, FILTER>>, payload: togglePublicCommentLikeSuccessAction['payload']) {
  const data = state.get('data')
  return state.setIn(['data', 'data', 'data'], data?.data?.data?.map((service) => service.voteId === payload.voteId ? payload.data : service))
  // return state.get('data')?.data.data[0]
  // return state
}
