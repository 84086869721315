import React from 'react'

import { Avatar, Box, FlexFill, Text } from '@ocs.lab/ui'

import { IReportPerson } from 'models/reports'

type Props = {
  user: IReportPerson
}

const User: React.FC<Props> = ({ user }) => {
  return (
    <FlexFill alignItems="center">
      <Avatar size="small" variant="rounded" alt={user.displayName} src={user.avatarUrl} />
      <Box ml={8}>
        <Text>{user.displayName}</Text>
      </Box>
    </FlexFill>
  )
}

export default User
