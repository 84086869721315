import React from 'react'

import { Box, Text } from '@ocs.lab/ui'
import { ClearIcon } from '@ocs.lab/ui/lib/components/icons'

import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'

const Accordion = withStyles(({ palette }) => ({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {
    '& .MuiCollapse-container': {
      borderBottom: `1px solid ${palette.background.default}`,
      paddingBottom: 24,
    },
  },
}))(MuiAccordion)

const AccordionSummary = withStyles(({ palette }) => ({
  root: {
    borderBottom: `1px solid ${palette.background.default}`,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    padding: '12px 24px',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {
    borderBottom: 'none',
  },
  expandIcon: {
    transform: 'rotateZ(45deg)',
    marginRight: 6,
  },
}))(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails)

type Props = {
  title: string
  body: string
}

const FAQAccrodion: React.FC<Props> = ({ title, body }) => {
  return (
    <Accordion square>
      <AccordionSummary expandIcon={<ClearIcon />}>
        <Text style={{ fontSize: 20 }}>{title}</Text>
      </AccordionSummary>
      <AccordionDetails>
        <Box px={24}>
          <Text variant="h6" style={{ whiteSpace: 'pre-line', lineHeight: 1.4 }}>
            {body}
          </Text>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default FAQAccrodion
